import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ApiData,
  ApiMessage,
  ApiResponse,
} from '../../interfaces/api/api-response.interface';
import { User } from '../../interfaces/api/user.interface';
import { NgSelect } from '../../interfaces/ui/ui.interface';
import { ToastrNotificationService } from '../ui/toastr-notification.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public API_URL = `${environment.apiUrl}/user`;

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: ToastrNotificationService
  ) {}

  public getMeUser(): Observable<ApiResponse<User>> {
    return this._httpClient.get<ApiResponse<User>>(
      `${this.API_URL}/get-me-user`
    );
  }

  public findUsers(filter: object): Observable<ApiResponse<ApiData<User[]>>> {
    const endpoint = `${this.API_URL}/find`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<User[]>>>(endpoint, {
      params,
    });
  }

  public findUsersForSelect(
    filter: object
  ): Observable<ApiData<NgSelect<string>[]>> {
    return this.findUsers(filter).pipe(
      map((response) => ({
        totalCount: response.data.totalCount,
        result: response.data.result.map((user) => ({
          label: user.username,
          value: user._id,
          currencies: user.currencies,
          currencyCode: user.currencyId,
        })),
      }))
    );
  }

  public createUser(user: object): Observable<ApiResponse<User>> {
    const endpoint = `${this.API_URL}/register`;
    return this._httpClient
      .post<ApiResponse<User>>(endpoint, user)
      .pipe(
        tap((res) =>
          this.showNotification(res.message, 'userManagement.users.title')
        )
      );
  }

  public updateProfile(user: User): Observable<ApiResponse<User>> {
    const endpoint = `${this.API_URL}/update-profile`;
    return this._httpClient
      .patch<ApiResponse<User>>(endpoint, user)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  public setUserPassword(
    id: string,
    newPassword: string
  ): Observable<ApiResponse<User>> {
    const endpoint = `${this.API_URL}/set-user-password`;
    return this._httpClient
      .put<ApiResponse<User>>(endpoint, { id, newPassword })
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  public generateTwoFactor(id: string): Observable<ApiResponse<User>> {
    const endpoint = `${this.API_URL}/generate-two-factor`;
    return this._httpClient
      .post<ApiResponse<User>>(endpoint, { id })
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  public activateTwoFactor(
    id: string,
    code: string
  ): Observable<ApiResponse<User>> {
    const endpoint = `${this.API_URL}/enable-two-factor`;
    return this._httpClient
      .put<ApiResponse<User>>(endpoint, { id, code })
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  public disableTwoFactor(id: string): Observable<ApiResponse<User>> {
    const endpoint = `${this.API_URL}/disable-two-factor`;
    return this._httpClient
      .put<ApiResponse<User>>(endpoint, { id })
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  public verifyLogin(
    id: number | string,
    code: string
  ): Observable<ApiResponse<User>> {
    const endpoint = `${this.API_URL}/verify-login`;
    return this._httpClient
      .post<ApiResponse<User>>(endpoint, { id, code })
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  public updateUser(id: string, user: object): Observable<ApiResponse<User>> {
    const endpoint = `${this.API_URL}/update-user/${id}`;
    return this._httpClient
      .put<ApiResponse<User>>(endpoint, user)
      .pipe(
        tap((res) =>
          this.showNotification(res.message, 'userManagement.users.title')
        )
      );
  }

  public updateAffiliateStatus(user: object): Observable<ApiResponse<User>> {
    const endpoint = `${this.API_URL}/update-affiliate-status`;
    return this._httpClient
      .put<ApiResponse<User>>(endpoint, user)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  public getMeCashdesks(): Observable<ApiResponse<User>> {
    return this._httpClient.get<ApiResponse<User>>(
      `${this.API_URL}/get-me-cashdesks`
    );
  }

  private showNotification(
    message: ApiMessage,
    title: string = 'profile.title'
  ): void {
    this._notificationService.showNotification({
      title,
      message,
      type: 'success',
    });
  }
}
