import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PERMITTED_VIEWS } from 'src/app/core/helpers/global/global.constant';
import { User } from 'src/app/core/interfaces/api/user.interface';
import { GlobalService } from 'src/app/core/services/ui/global.service';
import { UserActions } from 'src/app/core/states/auth/auth.actions';

@Component({
  selector: 'shared-header-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  public profileImg: string = 'assets/images/dashboard/profile.jpg';
  public urlProfile: string = 'profile/profile-detail';
  public profile: User = {} as User;
  public profile$: Observable<User> = this._globalService.profile;
  public name: string = '';
  public role: string | null | undefined = '';

  public PERMITTED_VIEWS = PERMITTED_VIEWS;

  constructor(
    private _router: Router,
    private _globalService: GlobalService,
    private _store: Store
  ) {
    if (localStorage.getItem('user') !== null) {
    } else {
    }
  }

  ngOnInit() {
    this.loadProfile();
  }

  public logoutFunc() {
    this._store.dispatch(UserActions.userLogout());
  }

  private loadProfile() {
    this.profile$.subscribe((profile) => {
      if (profile && profile._id) {
        this.profile = profile;
        this.name = profile.firstName + ' ' + profile.lastName;
        this.role = profile.roleId?.name;
      }
    });
  }
}
