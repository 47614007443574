<div class="row justify-content-center mb-3">
  <div class="col-auto">
    <div
      class="btn-group"
      role="group"
      aria-label="Basic radio toggle button group"
    >
      <input
        type="radio"
        class="btn-check"
        name="btnradio"
        id="btnradio1"
        autocomplete="off"
        (change)="onOptionChange(TYPE_TRANSACTIONS.DEPOSIT)"
        [checked]="transaction_type === TYPE_TRANSACTIONS.DEPOSIT"
      />
      <label class="btn btn-outline-secondary" for="btnradio1">
        {{ 'words.deposit' | translate }}
      </label>

      <input
        type="radio"
        class="btn-check"
        name="btnradio"
        id="btnradio2"
        autocomplete="off"
        (change)="onOptionChange(TYPE_TRANSACTIONS.WITHDRAWAL)"
        [checked]="transaction_type === TYPE_TRANSACTIONS.WITHDRAWAL"
      />
      <label class="btn btn-outline-secondary" for="btnradio2">
        {{ 'words.withdrawal' | translate }}
      </label>
    </div>
  </div>
</div>
<app-balance-cards-form
  [balance]="selectedPlayerBalance || selectedUserBalance"
  [credit]="selectedPlayerCredit || selectedUserCredit"
  [currencyCode]="selectedCodeCurrency || selectedCodeCurrencyPlayer"
></app-balance-cards-form>
<form [formGroup]="transferForm" class="mt-2" (ngSubmit)="submitForm()">
  <div class="media">
    <label class="col-form-label m-r-10 fs-6">
      {{ 'transfer.newTransfer' | translate }}
    </label>
    <div
      *ngIf="
        selectedOption !== USER_TYPE.PLAYER &&
        transaction_type !== TYPE_TRANSACTIONS.WITHDRAWAL
      "
      class="media-body d-flex align-items-center justify-content-end switch-sm icon-state"
    >
      <label class="switch">
        <input
          type="checkbox"
          (change)="toggleTransactionMode()"
          [checked]="isCredit"
        />
        <span class="switch-state"></span>
      </label>
      <span class="ms-2 mt-2 font-roboto">
        {{
          isCredit
            ? ('transfer.credit' | translate)
            : ('transfer.cash' | translate)
        }}
      </span>
    </div>
  </div>
  @if (selectedOption === USER_TYPE.AGENT || selectedOption ===
  USER_TYPE.PLAYER) {
  <div class="row mt-2">
    @if (selectedOption === USER_TYPE.PLAYER) {
    <div class="col-md-6">
      <label for="playerId" class="form-label">
        {{ 'transfer.player' | translate }} *
      </label>
      <ng-select
        id="playerId"
        bindLabel="label"
        bindValue="value"
        formControlName="playerId"
        placeholder="{{ 'transfer.placeholders.selectPlayer' | translate }}"
        [items]="players$ | async"
        (change)="onPlayerSelected($event)"
        (search)="onSearchSelect(NG_SELECT_QUERIES.PLAYERS, $event.term)"
        (clear)="onSearchSelect(NG_SELECT_QUERIES.PLAYERS)"
        (close)="onSearchSelect(NG_SELECT_QUERIES.PLAYERS)"
        (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.PLAYERS)"
      ></ng-select>
    </div>
    } @else if (selectedOption === USER_TYPE.AGENT) {
    <div class="col-md-6">
      <label for="agentId" class="form-label">
        {{ 'transfer.agent' | translate }} *
      </label>
      <ng-select
        id="agentId"
        bindLabel="label"
        bindValue="value"
        formControlName="agentId"
        placeholder="{{ 'transfer.placeholders.selectAgent' | translate }}"
        [items]="users$ | async"
        (change)="onUserSelected($event)"
        (search)="onSearchSelect(NG_SELECT_QUERIES.USERS, $event.term)"
        (clear)="onSearchSelect(NG_SELECT_QUERIES.USERS)"
        (close)="onSearchSelect(NG_SELECT_QUERIES.USERS)"
        (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.USERS)"
      ></ng-select>
    </div>
    }
    <div class="col-md-6">
      <label for="amount" class="form-label">
        {{ 'transfer.amount' | translate }} *
      </label>
      <input
        type="text"
        class="form-control"
        appOnlyNumbersWhitTwoDecimal
        formControlName="amount"
        placeholder="0.00"
        required=""
        id="amount"
      />
    </div>
  </div>
  } @else if (selectedOption === USER_TYPE.COLABORATOR) {
  <div class="row mt-2">
    <div class="col-md-4">
      <label for="colaboratorId" class="form-label">
        {{ 'transfer.colaborator' | translate }} *
      </label>
      <ng-select
        id="colaboratorId"
        bindLabel="label"
        bindValue="value"
        formControlName="colaboratorId"
        placeholder="{{
          'transfer.placeholders.selectColaborator' | translate
        }}"
        [items]="users$ | async"
        (change)="onUserSelected($event)"
        (search)="onSearchSelect(NG_SELECT_QUERIES.USERS, $event.term)"
        (clear)="onSearchSelect(NG_SELECT_QUERIES.USERS)"
        (close)="onSearchSelect(NG_SELECT_QUERIES.USERS)"
        (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.USERS)"
      ></ng-select>
    </div>
    <div class="col-md-4">
      <label for="currencyId" class="form-label">
        {{ 'transfer.currency' | translate }} *
      </label>
      <ng-select
        id="currencyId"
        bindLabel="label"
        bindValue="value"
        formControlName="currencyId"
        placeholder="{{ 'transfer.placeholders.selectCurrency' | translate }}"
        [items]="currencies$ | async"
        (search)="onSearchSelect(NG_SELECT_QUERIES.CURRENCIES, $event.term)"
        (clear)="onSearchSelect(NG_SELECT_QUERIES.CURRENCIES)"
        (close)="onSearchSelect(NG_SELECT_QUERIES.CURRENCIES)"
        (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.CURRENCIES)"
        (change)="onCurrencySelected($event)"
      ></ng-select>
    </div>
    <div class="col-md-4">
      <label for="amount" class="form-label">
        {{ 'transfer.amount' | translate }} *
      </label>
      <input
        type="text"
        class="form-control"
        formControlName="amount"
        appOnlyNumbersWhitTwoDecimal
        placeholder="0.00"
        required=""
        id="amount"
      />
    </div>
  </div>
  }
  <div class="row mt-3">
    <div class="col-md-12">
      <label for="comment" class="form-label">
        {{ 'transfer.comentary' | translate }}
      </label>
      <input
        type="text"
        class="form-control"
        formControlName="comment"
        placeholder="{{ 'transfer.placeholders.writeComentary' | translate }}"
        id="comment"
      />
    </div>
  </div>
  @if (selectedOption !== USER_TYPE.COLABORATOR) { @if (!isCredit &&
  transaction_type !== TYPE_TRANSACTIONS.WITHDRAWAL) {
  <div class="media mt-3">
    <label class="col-form-label m-r-10 fs-6">
      {{ 'transfer.transferCash' | translate }}
    </label>
    <div
      class="media-body d-flex align-items-center justify-content-end switch-sm icon-state"
    >
      <label class="switch">
        <input
          type="checkbox"
          (change)="toggleBankingMode()"
          [checked]="isBanking"
        />
        <span class="switch-state"></span>
      </label>
      <span class="ms-2 mt-2 font-roboto">
        {{
          isBanking
            ? ('transfer.banking' | translate)
            : ('transfer.direct' | translate)
        }}
      </span>
    </div>
  </div>
  @if (isBanking) {

  <div class="row mt-3">
    <div class="col-md-6">
      <label for="agentId" class="form-label">
        {{ 'financial.holders.bankName' | translate }}
      </label>
      <ng-select
        id="bankId"
        formControlName="bankId"
        bindLabel="label"
        bindValue="value"
        placeholder="{{ 'financial.holders.bankName' | translate }}"
        [items]="banks$ | async"
        (search)="onSearchSelect(NG_SELECT_QUERIES.BANKS, $event.term)"
        (clear)="onSearchSelect(NG_SELECT_QUERIES.BANKS)"
        (close)="onSearchSelect(NG_SELECT_QUERIES.BANKS)"
        (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.BANKS)"
      ></ng-select>
    </div>
    <div class="col-md-6">
      <span>
        {{ 'transfer.receipt' | translate }}
      </span>
      <br />
      <div
        class="light-card rounded p-3 mt-2 text-center d-flex flex-column justify-content-center align-items-center cursor-pointer drop-area-container"
        (click)="triggerFileInput()"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)"
      >
        <input
          type="file"
          class="d-none"
          required
          formControlName="receipt"
          accept="image/png,image/jpeg"
          (change)="onFileSelected($event)"
          #fileInput
        />
        @if (!previewImage) {
        <ng-container>
          <i class="fa fa-cloud-upload text-secondary mb-3 fs-1"></i>
          <div class="text-light-emphasis">
            {{ 'transfer.uploadReceipt' | translate }}
          </div>
        </ng-container>
        }@else {
        <ng-container>
          <img
            [src]="previewImage"
            alt="Vista Previa"
            class="img-fluid preview-img"
          />
        </ng-container>
        }
      </div>
      @if (previewImage) {
      <div class="mt-2 text-center">
        <button class="btn btn-danger btn-sm" (click)="removeImage()">
          <i class="fa fa-trash"></i>
        </button>
      </div>
      }
    </div>
  </div>
  } } }

  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{ 'btn.cancel' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-secondary"
      [disabled]="transferForm.invalid"
    >
      {{ getButtonLabel() | translate }}
    </button>
  </div>
</form>
