import { Component, Input, OnInit } from '@angular/core';
import { CardLightDescription } from 'src/app/core/interfaces/ui/components.interface';

@Component({
  selector: 'shared-light-description',
  templateUrl: './light-description.component.html',
  styleUrls: ['./light-description.component.scss'],
})
export class LightDescriptionComponent implements OnInit {
  @Input({ required: true })
  public tag!: CardLightDescription;

  @Input()
  public isLoading: boolean = true;

  public randomSvgColor!: string;

  private svgColors = [
    'svg-yellow',
    'svg-blue',
    'svg-green',
    'svg-red',
    'svg-purple',
  ];

  ngOnInit(): void {
    this.assignRandomIconColor();
  }

  private assignRandomIconColor(): void {
    this.randomSvgColor =
      this.svgColors[Math.floor(Math.random() * this.svgColors.length)];
  }
}
