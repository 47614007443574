export const USER_VALIDATIONS = {
  USER_NAME: { MAX_LENGTH: 20 },
  EMAIL: { MAX_LENGTH: 50 },
};

export const USER_AGENT = "Agents";

export const OPTION_STATUS = [
  {
    value: true,
    label: "words.active",
  },
  {
    value: false,
    label: "words.inactive",
  },
];

export const ROUTE_USER_MANAGEMENT = {
  USER: "/user-management/users",
  AGENT: "/user-management/agents",
};
