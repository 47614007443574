import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ToastrNotificationService } from "../ui/toastr-notification.service";
import {
  ApiData,
  ApiMessage,
  ApiResponse,
} from "../../interfaces/api/api-response.interface";
import { map, Observable, tap } from "rxjs";
import { CommissionPlan } from "../../interfaces/api/commission-plan.interface";
import { NgSelect } from "../../interfaces/ui/ui.interface";

@Injectable({
  providedIn: "root",
})
export class CommissionPlanService {
  public apiUrl = `${environment.apiUrl}/commission-plans`;

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: ToastrNotificationService
  ) {}

  public getCommissionPlans(
    userId: string
  ): Observable<ApiResponse<ApiData<CommissionPlan[]>>> {
    const endpoint = `${this.apiUrl}/${userId}`;
    return this._httpClient.get<ApiResponse<ApiData<CommissionPlan[]>>>(
      endpoint
    );
  }

  public findAllCommissionPlans(
    filter: object
  ): Observable<ApiResponse<ApiData<CommissionPlan[]>>> {
    const endpoint = `${this.apiUrl}/find-all-commission-plans`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<CommissionPlan[]>>>(
      endpoint,
      { params }
    );
  }

  public findCommissionPlansForSelect(
    filter: object
  ): Observable<ApiData<NgSelect<string>[]>> {
    return this.findAllCommissionPlans(filter).pipe(
      map((response) => ({
        totalCount: response.data.totalCount,
        result: response.data.result.map((commissionPlan) => ({
          label: commissionPlan.planName,
          value: commissionPlan._id,
        })),
      }))
    );
  }
  public createCommissionPlan(
    request: CommissionPlan
  ): Observable<ApiResponse<ApiData<CommissionPlan>>> {
    const endpoint = `${this.apiUrl}/`;
    return this._httpClient
      .post<ApiResponse<ApiData<CommissionPlan>>>(endpoint, request)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  public updateCommissionPlans(
    request: CommissionPlan
  ): Observable<ApiResponse<ApiData<CommissionPlan>>> {
    const endpoint = `${this.apiUrl}/`;
    return this._httpClient
      .put<ApiResponse<ApiData<CommissionPlan>>>(endpoint, request)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  private showNotification(message: ApiMessage): void {
    this._notificationService.showNotification({
      title: "tools.commissionPlans.title",
      message: message,
      type: "success",
    });
  }
}
