import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'alert-page-configuration',
  templateUrl: './alert-page-configuration.component.html',
})
export class AlertPageConfigurationComponent implements OnInit {
  @Input() time: number = 30000;
  @Input() showAlert: boolean = true;
  @Input() message: string =
    'pageManagement.pageConfiguration.notificationMessage';

  ngOnInit(): void {
    setTimeout(() => {
      this.showAlert = false;
    }, this.time);
  }

  closeAlert() {
    this.showAlert = false;
  }
}
