import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isBefore } from 'date-fns';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { USER_SESSION } from '../../helpers/global/global.constant';
import { ApiResponse } from '../../interfaces/api/api-response.interface';
import { User, UserLoginRequest } from '../../interfaces/api/user.interface';
import { StorageService } from '../ui/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public url: string;
  constructor(
    private _http: HttpClient,
    private _storageService: StorageService,
    private router: Router
  ) {
    this.url = environment.apiUrl;
  }
  login = (request: UserLoginRequest): Observable<ApiResponse<User>> => {
    const endpoint = `${this.url}/user/login`;
    const headers = new HttpHeaders({ skip: 'skip' });
    const options = { headers };
    return this._http.post<ApiResponse<User>>(endpoint, request, options).pipe(
      tap((response: ApiResponse<User>) => {
        this._storageService.secureStorage.setItem(
          USER_SESSION,
          JSON.stringify(response.data)
        );
      })
    );
  };

  logout(): void {
    this._storageService.secureStorage.removeItem(USER_SESSION);
    this.router.navigate([`/auth/login`]);
  }

  public isLoggedIn(): boolean {
    const expirationDate = this.getExpiration();
    if (!expirationDate) {
      return false;
    }
    return isBefore(new Date(), expirationDate);
  }
  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  getExpiration = () => {
    const session = JSON.parse(
      this._storageService.secureStorage.getItem(USER_SESSION)
    );
    if (!session) {
      return null;
    }
    const { exp } = this._storageService.parseJwt(session);
    return new Date(exp * 1000);
  };
  /**************************
   *  Manejar Locación
   * ************************/
  getDeviceInfo(): { isMobile: boolean; userAgent: string } {
    return {
      isMobile: this.isMobile(),
      userAgent: navigator.userAgent,
    };
  }
  isMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  }
  getCurrentPosition(): Observable<{ latitude: number; longitude: number }> {
    return new Observable((observer) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            observer.next({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            observer.complete();
          },
          (error) => observer.error(error)
        );
      } else {
        observer.error(new Error('Geolocation not supported'));
      }
    });
  }
}
