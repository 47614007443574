import { Routes } from "@angular/router";
import { permissionGuard } from "src/app/core/guards/permission.guard";
import { PERMITTED_VIEWS } from "src/app/core/helpers/global/global.constant";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../features/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.DASHBOARD },
  },
  {
    path: "players",
    loadChildren: () =>
      import("../../features/player/player.module").then((m) => m.PlayerModule),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.PLAYERS },
  },
  {
    path: "financial",
    loadChildren: () =>
      import("../../features/financial/financial.module").then(
        (m) => m.FinancialModule
      ),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.FINANCIAL },
  },
  {
    path: "tools",
    loadChildren: () =>
      import("../../features/tools/tools.module").then((m) => m.ToolsModule),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.TOOLS },
  },
  {
    path: "user-management",
    loadChildren: () =>
      import("../../features/user-management/user-management.module").then(
        (m) => m.UserManagementModule
      ),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.USER_MANAGEMENT },
  },
  {
    path: "sport-management",
    loadChildren: () =>
      import("../../features/sport-management/sport-management.module").then(
        (m) => m.SportManagementModule
      ),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.SPORT_MANAGEMENT },
  },
  {
    path: "page-management",
    loadChildren: () =>
      import("../../features/page-management/page-management.module").then(
        (m) => m.PageManagementModule
      ),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.PAGE_MANAGEMENT },
  },
  {
    path: "real-time",
    loadChildren: () =>
      import("../../features/real-time/real-time.module").then(
        (m) => m.RealTimeModule
      ),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.REAL_TIME },
  },
  {
    path: "profile",
    loadChildren: () =>
      import("../../features/profile/profile.module").then(
        (m) => m.ProfileModule
      ),
  },
  {
    path: "betshop-management",
    loadChildren: () =>
      import(
        "../../features/betshop-management/betshop-management.module"
      ).then((m) => m.BetshopManagementModule),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.BETSHOP_MANAGEMENT },
  },
  {
    path: "promotional-tools",
    loadChildren: () =>
      import("../../features/promotional-tools/promotional-tools.module").then(
        (m) => m.PromotionalToolsModule
      ),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.PROMOTIONAL_TOOLS },
  },
  {
    path: "affiliates",
    loadChildren: () =>
      import("../../features/affiliates/affiliates.module").then(
        (m) => m.AffiliatesModule
      ),
  },
  {
    path: "reports",
    loadChildren: () =>
      import("../../features/reports/reports.module").then(
        (m) => m.ReportsModule
      ),
    canActivate: [permissionGuard],
    data: { permission: PERMITTED_VIEWS.REPORTS },
  },
];
