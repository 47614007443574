import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';

export function validateIpAddress(): ValidatorFn {
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return (control: AbstractControl): { invalidIp: { value: any } } | null => {
    const valid = ipRegex.test(control.value);
    return valid ? null : { invalidIp: { value: control.value } };
  };
}

export function duplicateCurrency(): ValidatorFn {
  return (formArray: AbstractControl): { [key: string]: boolean } | null => {
    if (!(formArray instanceof FormArray)) {
      return null;
    }

    const currencies = formArray.value.map(
      (currency: any) => currency.currencyId
    );
    const hasDuplicates = currencies.some(
      (item: any, index: number) => currencies.indexOf(item) !== index
    );

    return hasDuplicates ? { duplicateCurrency: true } : null;
  };
}

export const maxSizeFileValidator = (maxSizeInMB: number) => {
  const maxSize = maxSizeInMB * 1024 * 1024;

  return (control: AbstractControl) => {
    const base64String =
      typeof control.value === 'string' ? control.value : control.value[0];

    if (base64String) {
      const sizeInBytes =
        base64String.length * (3 / 4) -
        (base64String.endsWith('==') ? 2 : base64String.endsWith('=') ? 1 : 0);
      if (sizeInBytes > maxSize) {
        return { maxSize: true };
      }
    }
    return null;
  };
};
