import { Menu } from 'src/app/core/interfaces/ui/nav.interface';
import { PERMITTED_VIEWS } from '../global/global.constant';

export const MENU_ITEMS: Menu[] = [
  {
    id: 'backOffice',
    headTitle1: 'words.backOffice',
  },
  {
    id: PERMITTED_VIEWS.DASHBOARD,
    title: 'dashboard.titleSidebar',
    icon: 'home',
    type: 'link',
    badgeType: 'light-primary',
    path: '/dashboard',
    bookmark: true,
  },
  {
    id: PERMITTED_VIEWS.PLAYERS,
    title: 'players.titleSidebar',
    icon: 'user',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.PLAYER_LIST,
        path: '/players/players-list',
        title: 'players.titleSidebar',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.PLAYERS_BLOCKED,
        path: '/players/players-blocked',
        title: 'players.blocked.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.PLAYERS_TRANSFER,
        path: '/players/players-transfer',
        title: 'players.transferred.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.PLAYERS_ACTIVE,
        path: '/players/active-players-list',
        title: 'players.activePlayer.title',
        type: 'link',
      },
    ],
  },
  {
    id: PERMITTED_VIEWS.REPORTS,
    title: 'reports.title',
    icon: 'home',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.REPORTS_SPORTS_BETTING,
        title: 'reports.sportsBetting.title',
        type: 'sub',
        children: [
          {
            id: PERMITTED_VIEWS.REPORTS_SPORTS_BETTING_BETTING_REPORT,
            path: '/reports/betting',
            title: 'reports.sportsBetting.bettingReport',
            type: 'link',
            children: [
              {
                id: PERMITTED_VIEWS.BETTING_REPORT_RELOAD,
                title: 'btn.reload',
              },
            ],
          },
          {
            id: PERMITTED_VIEWS.REPORTS_SPORTS_BETTING_SPORTS_REPORT,
            path: '/reports/sports',
            title: 'reports.sportsBetting.sportsReport',
            type: 'link',
          },
        ],
      },
      {
        id: PERMITTED_VIEWS.REPORTS_CASINO,
        title: 'reports.casino.title',
        type: 'sub',
        children: [
          {
            id: PERMITTED_VIEWS.REPORTS_CASINO_BY_BETS,
            path: '/reports/report-by-bet',
            title: 'reports.casino.reportBets',
            type: 'link',
          },
          {
            id: PERMITTED_VIEWS.REPORTS_CASINO_BY_GAMES,
            path: '/reports/report-by-games',
            title: 'reports.casino.reportGames',
            type: 'link',
          },
          {
            id: PERMITTED_VIEWS.REPORTS_CASINO_BY_PARTNER,
            path: '/reports/report-by-partner',
            title: 'reports.casino.reportPartner',
            type: 'link',
          },
          {
            id: PERMITTED_VIEWS.REPORTS_CASINO_BY_PLAYER,
            path: '/reports/report-by-players',
            title: 'reports.casino.reportPlayer',
            type: 'link',
          },
        ],
      },
      {
        id: PERMITTED_VIEWS.REPORTS_LOADS_AND_WITHDRAWALS,
        title: 'reports.loadsAndWithdrawals.title',
        type: 'sub',
        children: [
          {
            id: PERMITTED_VIEWS.REPORTS_AGENTS_LIST_LOADS_AND_WITHDRAWALS,
            path: '/reports/agents',
            title: 'reports.loadsAndWithdrawals.agents',
            type: 'link',
          },
          {
            id: PERMITTED_VIEWS.REPORTS_COLLABORATORS_LIST_LOADS_AND_WITHDRAWALS,
            path: '/reports/collaborators',
            title: 'reports.loadsAndWithdrawals.colaborators',
            type: 'link',
          },
          {
            id: PERMITTED_VIEWS.REPORTS_PLAYERS_LIST_LOADS_AND_WITHDRAWALS,
            path: '/reports/players',
            title: 'reports.loadsAndWithdrawals.players',
            type: 'link',
          },
        ],
      },
      {
        id: PERMITTED_VIEWS.REPORTS_TRANSACTION_REPORTS,
        title: 'reports.transactionReports.title',
        type: 'sub',
        children: [
          {
            id: PERMITTED_VIEWS.REPORTS_TRANSACTION_REPORTS_ALL_TRANSACTIONS,
            path: '/reports/all-transactions',
            title: 'financial.transactions.title',
            type: 'link',
          },
          {
            id: PERMITTED_VIEWS.REPORTS_TRANSACTION_REPORTS_AGENTS,
            path: '/reports/agents-transactions',
            title: 'reports.loadsAndWithdrawals.agents',
            type: 'link',
          },
          {
            id: PERMITTED_VIEWS.REPORTS_TRANSACTION_REPORTS_PLAYERS,
            path: '/reports/players-transactions',
            title: 'reports.loadsAndWithdrawals.players',
            type: 'link',
          },
        ],
      },
      {
        id: PERMITTED_VIEWS.REPORTS_LOGIN_HISTORY,
        title: 'reports.loginHistory.title',
        type: 'sub',
        children: [
          {
            id: PERMITTED_VIEWS.REPORTS_LOGIN_HISTORY_BACKOFFICE,
            path: '/reports/login-histories/backoffice',
            title: 'tools.ipAddressRestriction.backOffice',
            type: 'link',
          },
          {
            id: PERMITTED_VIEWS.REPORTS_LOGIN_HISTORY_PLAYER,
            path: '/reports/login-histories/player',
            title: 'players.player.title',
            type: 'link',
          },
        ],
      },
    ],
  },
  {
    id: PERMITTED_VIEWS.PROMOTIONAL_TOOLS,
    title: 'promotionalTools.titleSidebar',
    icon: 'bonus-kit',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.PROMOTIONAL_TOOLS_BONUS,
        path: '/promotional-tools/bonus',
        title: 'promotionalTools.bonus.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.PROMOTIONAL_TOOLS_CODES,
        path: '/promotional-tools/promotional-codes',
        title: 'promotionalTools.promotionalCodes.title',
        type: 'link',
      },
    ],
  },
  {
    id: PERMITTED_VIEWS.FINANCIAL,
    title: 'financial.titleSidebar',
    icon: 'ecommerce',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.FINANCIAL_BANK_ENTITIES,
        path: '/financial/bank-entities',
        title: 'financial.bankEntities.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.FINANCIAL_HOLDERS,
        path: '/financial/holders',
        title: 'financial.holders.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.FINANCIAL_TRANSACTIONS,
        path: '/financial/transactions',
        title: 'financial.transactions.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.FINANCIAL_WITHDRAWAL_REQUEST,
        path: '/financial/withdrawal-request',
        title: 'financial.withdrawalRequest.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.FINANCIAL_DEPOSIT_REQUEST,
        path: '/financial/deposit-request',
        title: 'financial.depositRequest.title',
        type: 'link',
      },
    ],
  },
  {
    id: PERMITTED_VIEWS.TOOLS,
    title: 'tools.titleSidebar',
    icon: 'icons',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.TOOLS_COMMISSION_PLANS,
        path: '/tools/commission-plans',
        title: 'tools.commissionPlans.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.TOOLS_RESTRICTIONS_IP_ADDRESS,
        path: '/tools/restrictions-ip-address',
        title: 'tools.ipAddressRestriction.title',
        type: 'link',
      },
    ],
  },
  {
    id: PERMITTED_VIEWS.USER_MANAGEMENT,
    title: 'userManagement.titleSidebar',
    icon: 'builders',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.USER_MANAGEMENT_USERS,
        path: '/user-management/users',
        title: 'userManagement.users.title',
        type: 'link',
        children: [
          {
            id: PERMITTED_VIEWS.USER_ACCOUNT_VIEW,
            title: 'profile.showProfile',
          },
          {
            id: PERMITTED_VIEWS.BTN_ADD_USER_NAV,
            title: 'profile.utilsNav.btnAddUserViewNav',
            children: [
              {
                id: PERMITTED_VIEWS.BTN_ADD_AGENT,
                title: 'profile.utilsNav.addAgentView',
              },
              {
                id: PERMITTED_VIEWS.BTN_ADD_PLAYER,
                title: 'profile.utilsNav.addPlayerView',
              },
              {
                id: PERMITTED_VIEWS.BTN_ADD_COLLABORATOR,
                title: 'profile.utilsNav.addCollaboratorView',
              },
            ],
          },
          {
            id: PERMITTED_VIEWS.BTN_TRANSFER_NAV,
            title: 'profile.utilsNav.btnTransferViewNav',
            children: [
              {
                id: PERMITTED_VIEWS.BTN_TRANSFER_AGENT,
                title: 'profile.utilsNav.transferAgentView',
              },
              {
                id: PERMITTED_VIEWS.BTN_TRANSFER_PLAYER,
                title: 'profile.utilsNav.transferPlayerView',
              },
              {
                id: PERMITTED_VIEWS.BTN_TRANSFER_COLLABORATOR,
                title: 'profile.utilsNav.transferCollaboratorView',
              },
            ],
          },
          {
            id: PERMITTED_VIEWS.VIEW_BALANCE_NAV,
            title: 'profile.utilsNav.viewBalanceViewNav',
          },
          {
            id: PERMITTED_VIEWS.VIEW_CREDITS_NAV,
            title: 'profile.utilsNav.viewCreditsViewNav',
          },
        ],
      },
      {
        id: PERMITTED_VIEWS.USER_MANAGEMENT_AGENTS,
        path: '/user-management/agents',
        title: 'agents.titleSidebar',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.USER_MANAGEMENT_ROLES_PERMISSION,
        path: '/user-management/roles-permission',
        title: 'userManagement.role.title',
        type: 'link',
      },
    ],
  },
  {
    id: PERMITTED_VIEWS.SPORT_MANAGEMENT,
    title: 'sportManagement.titleSidebar',
    icon: 'internationalization',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.SPORT_MANAGEMENT_BET_LIMIT,
        path: '/sport-management/bet-limit',
        title: 'sportManagement.betLimit.title',
        type: 'sub',
        children: [
          {
            id: PERMITTED_VIEWS.SPORT_MANAGEMENT_BET_LIMIT_GLOBAL,
            path: '/sport-management/bet-limit/global',
            title: 'sportManagement.betLimit.globalLimits.title',
            type: 'link',
            children: [
              {
                id: PERMITTED_VIEWS.BET_LIMIT_GLOBAL_VIEW,
                title: 'btn.view',
              },
              {
                id: PERMITTED_VIEWS.BET_LIMIT_GLOBAL_EDIT,
                title: 'btn.edit',
              },
              {
                id: PERMITTED_VIEWS.BET_LIMIT_GLOBAL_ADD,
                title: 'btn.add',
              },
            ],
          },
        ],
      },
      {
        id: PERMITTED_VIEWS.SPORT_MANAGEMENT_EVENT_MANAGER,
        path: '/sport-management/event-manager',
        title: 'sportManagement.eventManager.title',
        type: 'sub',
        children: [
          {
            id: PERMITTED_VIEWS.SPORT_MANAGEMENT_EVENT_MANAGER_PREMATCH,
            path: '/sport-management/event-manager/pre-match',
            title: 'sportManagement.eventManager.prematch.title',
            type: 'link',
          },
          {
            id: PERMITTED_VIEWS.SPORT_MANAGEMENT_EVENT_MANAGER_LIVE,
            path: '/sport-management/event-manager/live',
            title: 'sportManagement.eventManager.live.title',
            type: 'link',
          },
        ],
      },
    ],
  },
  {
    id: PERMITTED_VIEWS.PAGE_MANAGEMENT,
    title: 'pageManagement.titleSidebar',
    icon: 'landing-page',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.PAGE_MANAGEMENT_TOURNAMENT,
        path: '/page-management/tournament-management',
        title: 'pageManagement.tournamentManagement.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.PAGE_MANAGEMENT_SECTION,
        path: '/page-management/section-management',
        title: 'pageManagement.sectionManagement.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.PAGE_MANAGEMENT_PROMOTION,
        path: '/page-management/promotion-management',
        title: 'pageManagement.promotionManagement.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.PAGE_CONFIGURATION,
        path: "/page-management/page-configuration",
        title: "pageManagement.pageConfiguration.title",
        type: "link",
      },
    ],
  },
  {
    id: PERMITTED_VIEWS.REAL_TIME,
    title: 'realTime.titleSidebar',
    icon: 'table',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.REAL_TIME_JOURNEY,
        path: '/real-time/journey',
        title: 'realTime.journey.journey',
        type: 'link',
      },
    ],
  },
  {
    id: PERMITTED_VIEWS.BETSHOP_MANAGEMENT,
    title: 'betshopManagement.title',
    icon: 'home',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.BETSHOP_MANAGEMENT_BETSHOP,
        path: '/betshop-management/betshop',
        title: 'betshopManagement.betshops.title',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.BETSHOP_MANAGEMENT_CASHDESK,
        path: '/betshop-management/cashdesk',
        title: 'betshopManagement.cashdesks.title',
        type: 'link',
      },
    ],
  },
  {
    id: PERMITTED_VIEWS.AFFILIATES,
    title: 'affiliates.titleSidebar',
    icon: 'social',
    type: 'sub',
    badgeType: 'light-primary',
    children: [
      {
        id: PERMITTED_VIEWS.AFFILIATES_REFERRAL_LINK,
        path: '/affiliates/referrals-link',
        title: 'affiliates.referralsLink',
        type: 'link',
      },
      {
        id: PERMITTED_VIEWS.AFFILIATES_ANALYTICS,
        path: '/affiliates/affiliate-analytics',
        title: 'affiliates.analytics',
        type: 'link',
      },
    ],
  },
];
