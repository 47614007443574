<div class="media profile-media">
  <img
    class="b-r-10 img-40"
    src="assets/images/avtar/client_attention.png"
    alt=""
  />
  <div class="media-body">
    <span>{{ name }}</span>
    <p class="mb-0 font-roboto">
      {{ role }} <i class="middle fa fa-angle-down"></i>
    </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li>
    <div *appShowWithPermission="PERMITTED_VIEWS.USER_ACCOUNT_VIEW">
      <a routerLink="{{ urlProfile }}">
        <shared-feather-icons [icon]="'user'"></shared-feather-icons>
        <span>{{ 'profile.account' | translate }}</span>
      </a>
    </div>
  </li>
  <li>
    <a href="email"
      ><shared-feather-icons [icon]="'mail'"> </shared-feather-icons>
      <span>{{ 'profile.inbox' | translate }}</span>
    </a>
  </li>
  <li (click)="logoutFunc()">
    <shared-feather-icons [icon]="'log-in'"></shared-feather-icons>
    <span>{{ 'profile.logOut' | translate }}</span>
  </li>
</ul>
