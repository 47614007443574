@if (showAlert){
<ngb-alert
  type="custom"
  [animation]="true"
  [dismissible]="false"
  class="alert custom_color_alert"
>
  <div class="d-flex justify-content-between align-items-center">
    <p class="d-flex justify-content-start align-items-center gap-2">
      <i class="fa fa-info-circle"></i>
      {{ 'pageManagement.pageConfiguration.notificationMessage' | translate }}
    </p>
    <button class="custom_button_alert_close" (click)="closeAlert()">
      <i class="icofont icofont-close-line"></i>
    </button>
  </div>
</ngb-alert>
}
