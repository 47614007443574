import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'shared-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string = '';
  @Input() items: any[] = [];
  @Input() active_item: string = '';
  @Input() activeBackButton: boolean = false;

  constructor(private _location: Location) {}

  ngOnInit() {}

  public onBack(): void {
    this._location.back();
  }
}
