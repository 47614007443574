import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ToastrNotificationService } from "../ui/toastr-notification.service";
import {
  TransferredPlayer,
  Player,
} from "../../interfaces/api/player.interface";
import {
  ApiData,
  ApiMessage,
  ApiResponse,
} from "../../interfaces/api/api-response.interface";
import { map, Observable, tap } from "rxjs";
import { NgSelect } from "../../interfaces/ui/ui.interface";

@Injectable({
  providedIn: "root",
})
export class PlayerService {
  private apiUrl: string = environment.apiUrl;
  private path: string = "/player";
  private pathTransferred: string = "/transferred-player";

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: ToastrNotificationService
  ) {}

  getAllBlockedPlayersFilter(
    filter: object
  ): Observable<ApiResponse<ApiData<Player[]>>> {
    const endpoint = `${this.apiUrl}${this.path}/find-all-blocked-players-filters`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<Player[]>>>(endpoint, {
      params,
    });
  }

  getAllTransferredPlayersFilter(
    filter: object
  ): Observable<ApiResponse<ApiData<Player[]>>> {
    const endpoint = `${this.apiUrl}${this.pathTransferred}/find-all-transferred-players`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<Player[]>>>(endpoint, {
      params,
    });
  }

  getPlayersFilter(filters?: any): Observable<ApiResponse<ApiData<Player[]>>> {
    const endpoint = `${this.apiUrl}${this.path}/get-all`;
    return this._httpClient.get<ApiResponse<ApiData<Player[]>>>(endpoint);
  }

  getPlayerById(id: string): Observable<ApiResponse<Player>> {
    const endpoint = `${this.apiUrl}${this.path}/get-by-id/${id}`;
    return this._httpClient.get<ApiResponse<Player>>(endpoint);
  }

  getAllPlayersFilters(
    filter: object
  ): Observable<ApiResponse<ApiData<Player[]>>> {
    const endpoint = `${this.apiUrl}${this.path}/find-all-players`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<Player[]>>>(endpoint, {
      params,
    });
  }

  public findPlayersForSelect(
    filter: object
  ): Observable<ApiData<NgSelect<string>[]>> {
    return this.getAllActivePlayersFilters(filter).pipe(
      map((response) => ({
        result: response.data.result.map((user) => ({
          label: user.personalInfo.username,
          value: user._id,
          balance: user.accountInfo.balance,
          currencyCode: user.currency.code,
          currencyId: user.currency._id,
        })),
        totalCount: response.data.totalCount,
      }))
    );
  }

  getAllActivePlayersFilters(
    filter: object
  ): Observable<ApiResponse<ApiData<Player[]>>> {
    const endpoint = `${this.apiUrl}${this.path}/find-all-active-players-filters`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<Player[]>>>(endpoint, {
      params,
    });
  }

  getTotalCountPlayers(): Observable<ApiResponse<ApiData<[]>>> {
    const endpoint = `${this.apiUrl}${this.path}/get-total-players`;
    return this._httpClient.get<ApiResponse<ApiData<[]>>>(endpoint);
  }

  updatePassword(request: {
    playerId: string | string[];
    password: string;
  }): Observable<ApiResponse<ApiData<Player>>> {
    const endpoint = `${this.apiUrl}${this.path}/update-password`;
    return this._httpClient
      .put<ApiResponse<ApiData<Player>>>(endpoint, request)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  updatePlayer(request: object): Observable<ApiResponse<ApiData<Player>>> {
    const endpoint = `${this.apiUrl}${this.path}/update-player`;
    return this._httpClient
      .put<ApiResponse<ApiData<Player>>>(endpoint, request)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  updatePlayerPermission(
    request: object
  ): Observable<ApiResponse<ApiData<Player>>> {
    const endpoint = `${this.apiUrl}${this.path}/update-page-permissions`;
    return this._httpClient
      .put<ApiResponse<ApiData<Player>>>(endpoint, request)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  setPlayerParent = (
    request: TransferredPlayer
  ): Observable<ApiResponse<ApiData<Player>>> => {
    const endpoint = `${this.apiUrl}${this.path}/set-player-parent`;
    return this._httpClient
      .put<ApiResponse<ApiData<Player>>>(endpoint, request)
      .pipe(tap((res) => this.showNotification(res.message)));
  };

  setPlayerStatus = (request: {
    playerId: string | string[];
    state: string;
  }): Observable<ApiResponse<ApiData<Player>>> => {
    const endpoint = `${this.apiUrl}${this.path}/set-player-status`;
    return this._httpClient
      .put<ApiResponse<ApiData<Player>>>(endpoint, request)
      .pipe(tap((res) => this.showNotification(res.message)));
  };

  createPlayer(player: object): Observable<ApiResponse<ApiData<Player>>> {
    const endpoint = `${this.apiUrl}${this.path}/register`;
    return this._httpClient
      .post<ApiResponse<ApiData<Player>>>(endpoint, player)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  private showNotification(message: ApiMessage): void {
    this._notificationService.showNotification({
      title: "players.player.title",
      message: message,
      type: "success",
    });
  }
}
