export const TOKEN_HEADER_KEY = 'x-api-key';
export const USER_SESSION = 'USER_SESSION';
export const USER_SESSION_PRE = 'USER_SESSION_PRE';

export const LOCAL_STORAGE_NAMES = {
  LANGUAGE: 'Language',
};

export const AVAILABLE_LANGUAGES = {
  ES: 'es',
  EN: 'en',
} as const;

export const HORARY_ZONE = [
  { label: 'UTC−12:00', value: '-12' },
  { label: 'UTC−11:00', value: '-11' },
  { label: 'UTC−10:00', value: '-10' },
  { label: 'UTC−09:00', value: '-9' },
  { label: 'UTC−08:00', value: '-8' },
  { label: 'UTC−07:00', value: '-7' },
  { label: 'UTC−06:00', value: '-6' },
  { label: 'UTC−05:00', value: '-5' },
  { label: 'UTC−4:00', value: '-4' },
  { label: 'UTC−3:00', value: '-3' },
  { label: 'UTC−2:00', value: '-2' },
  { label: 'UTC−1:00', value: '-1' },
  { label: 'UTC-0:00', value: '0' },
  { label: 'UTC+1:00', value: '1' },
  { label: 'UTC+2:00', value: '2' },
  { label: 'UTC+3:00', value: '3' },
  { label: 'UTC+4:00', value: '4' },
  { label: 'UTC+5:00', value: '5' },
  { label: 'UTC+6:00', value: '6' },
  { label: 'UTC+7:00', value: '7' },
  { label: 'UTC+8:00', value: '8' },
  { label: 'UTC+9:00', value: '9' },
  { label: 'UTC+10:00', value: '10' },
  { label: 'UTC+11:00', value: '11' },
  { label: 'UTC+12:00', value: '12' },
  { label: 'UTC+13:00', value: '13' },
  { label: 'UTC+14:00', value: '14' },
];

export const SELECT_TYPE = {
  COUNTRY: 'country',
  CURRENCY: 'currency',
  GENDER: 'gender',
  LANGUAGE: 'language',
  CITY: 'city',
  BONUS: 'bonus',
};

export const CHANGE_PASSWORD_CONSTANTS = {
  PASSWORD_FIELD: 'password',
  NEW_PASSWORD_FIELD: 'newPassword',
  CONFIRM_PASSWORD_FIELD: 'confirmPassword',
};

export const BALANCE_TYPE = {
  BALANCE: 'balance',
  CREDIT: 'credit',
};

export const USER_TYPE = {
  AGENT: 'Agents',
  COLABORATOR: 'Collaborator',
  PLAYER: 'Player',
  ALL: 'All',
  AFFILIATE: 'Affiliate',
};

export const TYPE_TRANSACTION = {
  DEPOSIT: 'Deposit',
  WITHDRAWAL: 'Withdrawal',
  CREDIT: 'Credit',
  CASH: 'Cash',
  BANKING: 'Banking',
  DIRECT: 'Direct',
};

export const NAV_ITEM_STATE = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const BACK_OFFICE = 'Backoffice';

export const STATE_TRANSACTION = {
  PROCESSED: 'Processed',
};
export const GENDER_OPTIONS = [
  { value: 'male', label: 'words.male' },
  { value: 'female', label: 'words.female' },
  { value: 'notDefined', label: 'words.notDefined' },
];

export const ORIGIN = {
  BACK_OFFICE: 'Backoffice',
  PLAYER: 'Players',
};

export const USER_SESSION_LOGIN = {
  CASHDESK: 'cashdesk',
  USER: 'user',
} as const;

export const PERMITTED_VIEWS = {
  DASHBOARD: 'dashboard',
  PLAYERS: 'players',
  PLAYER_LIST: 'playerList',
  PLAYERS_BLOCKED: 'playersBlocked',
  PLAYERS_TRANSFER: 'playersTransfer',
  PLAYERS_ACTIVE: 'playersActive',
  PROMOTIONAL_TOOLS: 'promotionalTools',
  PROMOTIONAL_TOOLS_BONUS: 'promotionalToolsBonus',
  PROMOTIONAL_TOOLS_CODES: 'promotionalToolsCodes',
  FINANCIAL: 'financial',
  FINANCIAL_BANK_ENTITIES: 'financialBankEntities',
  FINANCIAL_HOLDERS: 'financialHolders',
  FINANCIAL_TRANSACTIONS: 'financialTransactions',
  FINANCIAL_WITHDRAWAL_REQUEST: 'financialWithdrawalRequest',
  FINANCIAL_DEPOSIT_REQUEST: 'financialDepositRequest',
  TOOLS: 'tools',
  TOOLS_COMMISSION_PLANS: 'toolsCommissionPlans',
  TOOLS_RESTRICTIONS_IP_ADDRESS: 'toolsRestrictionsIpAddress',
  USER_MANAGEMENT: 'userManagement',
  USER_MANAGEMENT_USERS: 'userManagementUsers',
  USER_ACCOUNT_VIEW: 'userManagementAccountView',
  BTN_ADD_USER_NAV: 'addUserNav',
  BTN_ADD_AGENT: 'addAgent',
  BTN_ADD_PLAYER: 'addPlayer',
  BTN_ADD_COLLABORATOR: 'addCollaborator',
  BTN_TRANSFER_NAV: 'transferBtnNav',
  BTN_TRANSFER_AGENT: 'transferAgent',
  BTN_TRANSFER_PLAYER: 'transferPlayer',
  BTN_TRANSFER_COLLABORATOR: 'transferCollaborator',
  VIEW_BALANCE_NAV: 'viewBalanceNav',
  VIEW_CREDITS_NAV: 'viewCreditsNav',
  USER_MANAGEMENT_COLLABORATORS: 'userManagementCollaborators',
  USER_MANAGEMENT_AGENTS: 'userManagementAgents',
  USER_MANAGEMENT_ROLES_PERMISSION: 'userManagementRolesPermission',
  SPORT_MANAGEMENT: 'sportManagement',
  SPORT_MANAGEMENT_BET_LIMIT: 'sportManagementBetLimit',
  SPORT_MANAGEMENT_BET_LIMIT_GLOBAL: 'sportManagementBetLimitGlobal',
  BET_LIMIT_GLOBAL_VIEW: 'betLimitGlobalView',
  BET_LIMIT_GLOBAL_EDIT: 'betLimitGlobalEdit',
  BET_LIMIT_GLOBAL_ADD: 'betLimitGlobalAdd',
  SPORT_MANAGEMENT_EVENT_MANAGER: 'sportManagementEventManager',
  SPORT_MANAGEMENT_EVENT_MANAGER_PREMATCH:
    "sportManagementEventManagerPrematch",
  SPORT_MANAGEMENT_EVENT_MANAGER_LIVE: "sportManagementEventManagerLive",
  PAGE_MANAGEMENT: "pageManagement",
  PAGE_MANAGEMENT_TOURNAMENT: "pageManagementTournament",
  PAGE_MANAGEMENT_SECTION: "pageManagementSection",
  PAGE_MANAGEMENT_PROMOTION: "pageManagementPromotion",
  PAGE_CONFIGURATION: "pageConfiguration",
  REAL_TIME: "realTime",
  REAL_TIME_JOURNEY: "realTimeJourney",
  BETSHOP_MANAGEMENT: "betshopManagement",
  BETSHOP_MANAGEMENT_BETSHOP: "betshopManagementBetshop",
  BETSHOP_MANAGEMENT_CASHDESK: "betshopManagementCashdesk",
  REPORTS: "reports",
  REPORTS_SPORTS_BETTING: "reportsSportsBetting",
  REPORTS_SPORTS_BETTING_BETTING_REPORT: "reportsSportsBettingBettingReport",
  BETTING_REPORT_RELOAD: "bettingReportReload",
  REPORTS_SPORTS_BETTING_SPORTS_REPORT: "reportsSportsBettingSportsReport",
  REPORTS_LOADS_AND_WITHDRAWALS: "reportsLoadsAndWithdrawals",
  REPORTS_AGENTS_LIST_LOADS_AND_WITHDRAWALS:
    'reportsAgentsListLoadsAndWithdrawals',
  REPORTS_PLAYERS_LIST_LOADS_AND_WITHDRAWALS:
    'reportsPlayersListLoadsAndWithdrawals',
  REPORTS_COLLABORATORS_LIST_LOADS_AND_WITHDRAWALS:
    'reportsCollaboratorsListLoadsAndWithdrawals',
  REPORTS_TRANSACTION_REPORTS: 'reportsTransactionReports',
  REPORTS_TRANSACTION_REPORTS_ALL_TRANSACTIONS:
    'reportsTransactionAllTransactions',
  REPORTS_TRANSACTION_REPORTS_AGENTS: 'reportsLoadsAndWithdrawalsAgents',
  REPORTS_TRANSACTION_REPORTS_PLAYERS: 'reportsLoadsAndWithdrawalsPlayers',
  REPORTS_CASINO: 'reportsCasino',
  REPORTS_CASINO_BY_GAMES: 'reportsCasinoByGames',
  REPORTS_CASINO_BY_PARTNER: 'reportsCasinoByPartner',
  REPORTS_CASINO_BY_BETS: 'reportsCasinoByBets',
  REPORTS_CASINO_BY_PLAYER: 'reportsCasinoByPlayers',
  REPORTS_LOGIN_HISTORY: 'reportsLoginHistory',
  REPORTS_LOGIN_HISTORY_BACKOFFICE: 'reportsLoginHistoryBackoffice',
  REPORTS_LOGIN_HISTORY_PLAYER: 'reportsLoginHistoryPlayer',
  AFFILIATES: 'affiliates',
  AFFILIATES_ANALYTICS: 'affiliatesAnalytics',
  AFFILIATES_REFERRAL_LINK: 'affiliatesReferralLink',
};

export const ROLE_TYPE = {
  SUPER_ADMIN: 'Super Admin',
};

export const ROUTES = {
  ALL_TRASACTIONS: 'all-transactions',
  AGENTS_TRANSACTIONS: 'agents-transactions',
  PLAYERS_TRANSACTIONS: 'players-transactions',
};
