<div class="row g-sm-3 g-2">
  <div class="col-xl-4 col-md-4 col-sm-12">
    <div
      class="light-card balance-card widget-hover d-flex justify-content-between align-items-center"
    >
      <div class="d-flex flex-column">
        <div>
          <span class="f-light">
            {{ "transfer.balanceProfile" | translate }}
          </span>
          <h6 class="mt-1 mb-0">
            {{ currencyCodeProfile }} {{ balanceProfile | number : "1.2-2" }}
          </h6>
        </div>
        <div class="mt-2">
          <span class="f-light"> {{ "transfer.myCredit" | translate }} </span>
          <h6 class="mt-1 mb-0">
            {{ currencyCodeProfile }} {{ creditProfile | number : "1.2-2" }}
          </h6>
        </div>
      </div>
      <div class="svg-box d-flex justify-content-center">
        <i class="fa fa-dollar fs-5 text-secondary"></i>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-md-4 col-sm-12">
    <div
      class="light-card balance-card widget-hover d-flex justify-content-between align-items-center"
    >
      <div class="d-flex flex-column">
        <div>
          <span class="f-light">
            {{ "transfer.balance" | translate }} {{ cardTitle }}
          </span>
          <h6 class="mt-1 mb-0">
            {{ currencyCode }} {{ balance | number : "1.2-2" }}
          </h6>
        </div>
        @if (selectedOption !== USER_TYPE.PLAYER) {
        <div class="mt-2">
          <span class="f-light">
            {{ "transfer.credit" | translate }} {{ cardTitle }}
          </span>
          <h6 class="mt-1 mb-0">
            {{ currencyCode }} {{ credit | number : "1.2-2" }}
          </h6>
        </div>
        }
      </div>
      <div class="svg-box d-flex justify-content-center">
        <i [ngClass]="getIconClass()" class="fs-5 text-secondary"></i>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-md-4 col-sm-12">
    <div
      class="light-card balance-card widget-hover d-flex justify-content-between align-items-center"
    >
      <div class="d-flex flex-column">
        <div>
          <span class="f-light"> {{ "transfer.myTotal" | translate }} </span>
          <h6 class="mt-1 mb-0">
            {{ currencyCodeProfile }}
            {{ totalBalanceProfile | number : "1.2-2" }}
          </h6>
        </div>
        <div class="mt-2">
          <span class="f-light">
            {{ "transfer.total" | translate }} {{ cardTitle }}
          </span>
          <h6 class="mt-1 mb-0">
            {{ currencyCode }} {{ totalBalanceAgent | number : "1.2-2" }}
          </h6>
        </div>
      </div>
      <div class="svg-box d-flex justify-content-center">
        <i class="fa fa-money fs-5 text-secondary"></i>
      </div>
    </div>
  </div>
</div>
