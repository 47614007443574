import { Component } from "@angular/core";
import { PLAYER_TABS } from "src/app/core/helpers/global/player.constant";
import { BUTTON_ACTIONS } from "src/app/core/helpers/ui/ui.constant";
import { ModalWithAction } from "src/app/core/interfaces/ui/bootstrap-modal.interface";
import { ButtonAction } from "src/app/core/interfaces/ui/ui.interface";
import { BootstrapModalService } from "src/app/core/services/ui/bootstrap-modal.service";
import { AgentCreateModalComponent } from "src/app/features/user-management/components/agent-create-modal/agent-create-modal.component";
import { PlayerModalFormComponent } from "src/app/features/player/components/modals/player-modal-form/player-modal-form.component";
import { UserModalFormComponent } from "src/app/features/user-management/components/user-modal-form/user-modal-form.component";

@Component({
  selector: "shared-add-users",
  templateUrl: "./add-users.component.html",
  styleUrl: "./add-users.component.scss",
})
export class AddUsersComponent {
  public readonly TABS = PLAYER_TABS;
  public activePill: string = this.TABS.CHANGE_PASSWORD;
  public BUTTON_ACTIONS = BUTTON_ACTIONS;

  constructor(
    private _bsModalService: BootstrapModalService<ModalWithAction<any>>
  ) {}
  public closeModal(): void {
    this._bsModalService.closeModal();
  }

  public openAddPlayerModal(buttonAction: ButtonAction): void {
    this._bsModalService.openModal({
      component: PlayerModalFormComponent,
      data: { buttonAction },
    });
    this.closeModal();
  }
  public openAddCollaboratorModal(buttonAction: ButtonAction): void {
    this._bsModalService.openModal({
      component: UserModalFormComponent,
      data: { buttonAction },
    });
    this.closeModal();
  }
  public openAddAgentModal(buttonAction: ButtonAction): void {
    this._bsModalService.openModal({
      component: AgentCreateModalComponent,
      data: { buttonAction },
    });
    this.closeModal();
  }
}
