import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  ApiData,
  ApiResponse,
} from "../../interfaces/api/api-response.interface";
import { City } from "../../interfaces/api/city.interface";
import { NgSelect } from "../../interfaces/ui/ui.interface";

@Injectable({
  providedIn: "root",
})
export class CityService {
  private apiUrl: string = `${environment.apiUrl}/city`;

  constructor(private _http: HttpClient) {}

  getAllCities(): Observable<ApiResponse<ApiData<City[]>>> {
    const endpoint = `${this.apiUrl}/get-all`;
    return this._http.get<ApiResponse<ApiData<City[]>>>(endpoint);
  }

  getCitiesByCountry(
    countryId: string
  ): Observable<ApiResponse<ApiData<City[]>>> {
    const endpoint = `${this.apiUrl}/get-all-by-country`;
    return this._http.post<ApiResponse<ApiData<City[]>>>(endpoint, {
      countryId,
    });
  }
  getAllCitiesDataSelect(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<ApiResponse<ApiData<City[]>>> {
    let params = new HttpParams()
      .set("page", page.toString())
      .set("limit", limit.toString());
    if (searchTerm) {
      params = params.set("searchTerm", searchTerm);
    }

    const endpoint = `${this.apiUrl}/get-all`;

    return this._http.get<ApiResponse<ApiData<City[]>>>(endpoint, { params });
  }

  public findCities(filter: object): Observable<ApiResponse<ApiData<City[]>>> {
    const endpoint = `${this.apiUrl}/find`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._http.get<ApiResponse<ApiData<City[]>>>(endpoint, { params });
  }

  public findCitiesForSelect(
    filter: object
  ): Observable<ApiData<NgSelect<string>[]>> {
    return this.findCities(filter).pipe(
      map((response) => ({
        totalCount: response.data.totalCount,
        result: response.data.result.map((city) => ({
          label: city.name,
          value: city._id,
        })),
      }))
    );
  }
}
