import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  ApiData,
  ApiResponse,
} from "../../interfaces/api/api-response.interface";
import { City } from "../../interfaces/api/city.interface";
import { Country } from "../../interfaces/api/country.interface";
import { Province } from "../../interfaces/api/province.interface";
import { NgSelect } from "../../interfaces/ui/ui.interface";

@Injectable({
  providedIn: "root",
})
export class CountryService {
  private apiUrl: string = `${environment.apiUrl}/country`;

  constructor(private _http: HttpClient) {}

  getAllCountries(): Observable<ApiResponse<ApiData<Country[]>>> {
    const endpoint = `${this.apiUrl}/get-all`;
    return this._http.get<ApiResponse<ApiData<Country[]>>>(endpoint);
  }

  public findCountries(
    filter: object
  ): Observable<ApiResponse<ApiData<Country[]>>> {
    const endpoint = `${this.apiUrl}/find`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._http.get<ApiResponse<ApiData<Country[]>>>(endpoint, {
      params,
    });
  }

  public findCountriesForSelect(
    filter: object
  ): Observable<ApiData<NgSelect<string>[]>> {
    return this.findCountries(filter).pipe(
      map((response) => ({
        totalCount: response.data.totalCount,
        result: response.data.result.map((country) => ({
          label: country.name,
          value: country._id,
          icon: country.code,
          group: "words.all",
          currencyId: country.currencyId,
        })),
      }))
    );
  }

  getProvinceByCountry(
    countryId: string
  ): Observable<ApiResponse<ApiData<Province[]>>> {
    const endpoint = `${this.apiUrl}/${countryId}`;
    return this._http.get<ApiResponse<ApiData<Province[]>>>(endpoint);
  }

  getCityByCountryAndProvince(
    countryId: string,
    provinceId: string
  ): Observable<ApiResponse<ApiData<City[]>>> {
    const endpoint = `${this.apiUrl}/${countryId}/province/${provinceId}`;
    return this._http.get<ApiResponse<ApiData<City[]>>>(endpoint);
  }
}
