<div class="card card-according">
  <div role="button" (click)="collapseAccordion(accordion)">
    <div class="card-header" [ngClass]="{ collapsed: isCollapsed }">
      <ng-container
        *ngTemplateOutlet="headerTemplate ?? defaultHeaderTemplate"
      ></ng-container>
    </div>
  </div>
  <div class="collapse-accordion" ngbAccordion #accordion="ngbAccordion">
    <div ngbAccordionItem="first">
      <div id="collapseExample" ngbAccordionCollapse>
        <ng-container
          *ngTemplateOutlet="contentTemplate ?? null"
        ></ng-container>

        <ng-container
          *ngComponentOutlet="contentComponent ?? null"
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultHeaderTemplate>
  <div class="d-flex justify-content-between align-items-center">
    <h5>{{ title | translate }}</h5>
    <i
      class="fa"
      [ngClass]="{
        'fa-chevron-up': !isCollapsed,
        'fa-chevron-down': isCollapsed
      }"
    ></i>
  </div>
</ng-template>
