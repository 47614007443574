import { Component } from '@angular/core';

@Component({
  selector: 'app-set-limits',
  templateUrl: './set-limits.component.html',
  styleUrl: './set-limits.component.scss'
})
export class SetLimitsComponent {
  setLimits() {
    // Lógica para establecer los límites
  }
}
