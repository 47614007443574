import { Component, OnInit } from '@angular/core';
import {
  BALANCE_TYPE,
  PERMITTED_VIEWS,
} from 'src/app/core/helpers/global/global.constant';
import { Currency } from 'src/app/core/interfaces/api/currency.interface';
import { User } from 'src/app/core/interfaces/api/user.interface';
import { GlobalService } from 'src/app/core/services/ui/global.service';

@Component({
  selector: 'app-balance-user-cards',
  templateUrl: './balance-user-cards.component.html',
  styleUrls: ['./balance-user-cards.component.scss'],
})
export class BalanceUserCardsComponent implements OnInit {
  public isBalanceListVisible = false;
  public isCreditListVisible = false;
  public BALANCE_TYPE = BALANCE_TYPE;
  public profile: User = {} as User;
  public balanceUser: number = 0;
  public creditUser: number = 0;
  public PERMITTED_VIEWS = PERMITTED_VIEWS;

  public otherCurrencies: { name: string; balance: number; credit: number }[] =
    [];

  constructor(private _profileService: GlobalService) {}

  ngOnInit(): void {
    this.loadProfile();
  }

  private loadProfile() {
    this._profileService.profile.subscribe((profile) => {
      this.profile = profile;
      this.getBalanceData(profile);
    });
  }

  //* UI

  showList(type: string): void {
    if (type === BALANCE_TYPE.BALANCE) {
      this.isBalanceListVisible = true;
    } else if (type === BALANCE_TYPE.CREDIT) {
      this.isCreditListVisible = true;
    }
  }

  hideList(type: string): void {
    if (type === BALANCE_TYPE.BALANCE) {
      this.isBalanceListVisible = false;
    } else if (type === BALANCE_TYPE.CREDIT) {
      this.isCreditListVisible = false;
    }
  }

  toggleList(type: string): void {
    if (type === BALANCE_TYPE.BALANCE) {
      this.isBalanceListVisible = !this.isBalanceListVisible;
    } else if (type === BALANCE_TYPE.CREDIT) {
      this.isCreditListVisible = !this.isCreditListVisible;
    }
  }

  //? GET DATA
  private getBalanceData(profile: User): void {
    if (!profile || !profile.currencies || profile.currencies.length === 0) {
      return;
    }

    const primaryCurrency = profile.currencies.find(
      (currency) => currency.currencyId._id === profile.currencyId?._id
    );

    if (primaryCurrency) {
      this.balanceUser = primaryCurrency.balance;
      this.creditUser = primaryCurrency.credit;
    }

    this.otherCurrencies = profile.currencies
      .filter((currency) => currency.currencyId._id !== profile.currencyId?._id)
      .map((currency) => ({
        name: `${currency.currencyId.code}`,
        balance: currency.balance,
        credit: currency.credit,
      }));
  }
}
