import { createReducer, on } from "@ngrx/store";
import { UserActions } from "./auth.actions";
import { UserState } from "../../interfaces/api/user.interface";

export const initialUserState: UserState = {
  user: null, // TODO: change to profile
  message: null,
  loading: false,
};

export const authReducer = createReducer(
  initialUserState,

  // Sesión y Autenticación
  on(UserActions.loadUserSession, (state) => ({
    ...state,
    loading: true,
    message: "Loading user session...",
  })),
  on(UserActions.userAuthenticationRequest, (state) => ({
    ...state,
    loading: true,
    message: "Authenticating user...",
  })),
  on(UserActions.userAuthenticationSuccess, (state, { user }) => ({
    ...state,
    user,
    loading: false,
    message: "Authentication successful.",
  })),
  on(UserActions.userAuthenticationFailure, (state, { message }) => ({
    ...state,
    loading: false,
    message: `Authentication failed: ${message}`,
  })),

  // Logout
  on(UserActions.userLogout, (state) => ({
    ...state,
    loading: true,
    message: "Logging out...",
  })),
  on(UserActions.completeUserLogout, () => ({
    ...initialUserState,
    message: "User logged out successfully.",
  })),

  // Two-Factor Authentication
  on(UserActions.enableTwoFactorAuthentication, (state) => ({
    ...state,
    loading: true,
    message: "Enabling Two-Factor Authentication...",
  })),
  on(UserActions.enableTwoFactorAuthenticationSuccess, (state, { user }) => ({
    ...state,
    user,
    loading: false,
    message: "Two-Factor Authentication enabled successfully.",
  })),
  on(
    UserActions.enableTwoFactorAuthenticationFailure,
    (state, { message }) => ({
      ...state,
      loading: false,
      message: `Failed to enable Two-Factor Authentication: ${message}`,
    })
  ),
  on(UserActions.disableTwoFactorAuthentication, (state) => ({
    ...state,
    loading: true,
    message: "Disabling Two-Factor Authentication...",
  })),
  on(UserActions.disableTwoFactorAuthenticationSuccess, (state, { user }) => ({
    ...state,
    user,
    loading: false,
    message: "Two-Factor Authentication disabled successfully.",
  })),
  on(
    UserActions.disableTwoFactorAuthenticationFailure,
    (state, { message }) => ({
      ...state,
      loading: false,
      message: `Failed to disable Two-Factor Authentication: ${message}`,
    })
  )
);

export const preAuthReducer = createReducer(
  initialUserState,
  // Pre-autenticación
  on(UserActions.preAuthenticateUser, (state) => ({
    ...state,
    loading: true,
    message: "Pre-authenticating user...",
  })),
  on(UserActions.preAuthenticationSuccess, (state, { user }) => ({
    ...state,
    user,
    loading: false,
    message: "Pre-authentication successful.",
  })),
  on(UserActions.preAuthenticationFailure, (state, { message }) => ({
    ...state,
    loading: false,
    message: `Pre-authentication failed: ${message}`,
  }))
);

export const profileReducer = createReducer(
  initialUserState,
  // Actualización de perfil de usuario
  on(UserActions.requestUserProfileUpdate, (state) => ({
    ...state,
    loading: true,
    message: "Updating user profile...",
  })),
  on(UserActions.userProfileUpdateSuccess, (state, { user }) => ({
    ...state,
    user,
    loading: false,
    message: "Profile updated successfully.",
  })),
  on(UserActions.userProfileUpdateFailure, (state, { message }) => ({
    ...state,
    loading: false,
    message: `Failed to update profile: ${message}`,
  }))
);
