<div class="card">
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">{{ cardTitle }}</h5>
    <button type="button" class="btn-close" (click)="closeModal()"></button>
  </div>
  <div class="card-body">
    <app-transfer-user-form
      (transactionTypeChange)="onTransactionTypeChange($event)"
    ></app-transfer-user-form>
  </div>
</div>
