import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ApiData,
  ApiMessage,
  ApiResponse,
} from '../../interfaces/api/api-response.interface';
import { ToastrNotificationService } from '../ui/toastr-notification.service';
import { TransferData } from '../../interfaces/api/financial.interface';
import {
  RequestTransferData,
  TransactionAdmin,
} from '../../interfaces/api/transaction.interface';

@Injectable({
  providedIn: 'root',
})
export class TransferService {
  public API_URL = `${environment.apiUrl}/transaction`;

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: ToastrNotificationService
  ) {}

  getAllTransactions(
    filter: object
  ): Observable<ApiResponse<ApiData<TransactionAdmin[]>>> {
    const endpoint = `${this.API_URL}/find-all-load-and-withdraw-reports`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<TransactionAdmin[]>>>(
      endpoint,
      {
        params,
      }
    );
  }

  getAllWithdrawalRequest(
    filter: object
  ): Observable<ApiResponse<ApiData<TransactionAdmin[]>>> {
    const endpoint = `${this.API_URL}/find-all-withdrawal-request`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<TransactionAdmin[]>>>(
      endpoint,
      {
        params,
      }
    );
  }

  getAllDepositRequest(
    filter: object
  ): Observable<ApiResponse<ApiData<TransactionAdmin[]>>> {
    const endpoint = `${this.API_URL}/find-all-deposit-request`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<TransactionAdmin[]>>>(
      endpoint,
      {
        params,
      }
    );
  }

  findReceiptNumberRepeated(
    filter: object
  ): Observable<ApiResponse<ApiData<TransactionAdmin[]>>> {
    const endpoint = `${this.API_URL}/find-receipt-number-repeated`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<TransactionAdmin[]>>>(
      endpoint,
      {
        params,
      }
    );
  }

  findAllTransactionsReports(
    filter: object
  ): Observable<ApiResponse<ApiData<TransactionAdmin[]>>> {
    const endpoint = `${this.API_URL}/find-all-transaction-reports`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<TransactionAdmin[]>>>(
      endpoint,
      {
        params,
      }
    );
  }

  public saveTransfer(
    formData: FormData
  ): Observable<ApiResponse<TransferData>> {
    return this._httpClient
      .post<ApiResponse<TransferData>>(
        `${this.API_URL}/save-transaction`,
        formData
      )
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  public setRequestTransfer(
    requestData: RequestTransferData
  ): Observable<ApiResponse<TransferData>> {
    return this._httpClient
      .post<ApiResponse<TransferData>>(
        `${this.API_URL}/set-request-transaction`,
        requestData
      )
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  private showNotification(message: ApiMessage): void {
    this._notificationService.showNotification({
      title: 'transfer.title',
      message: message,
      type: 'success',
    });
  }
}
