import {
  BannerGridForm,
  CategoryHomeForm,
  FormFieldSections,
  HeroCasinoForm,
  HeroMultipleSliderForm,
} from '../../interfaces/ui/page-section-object.interface';

export const TOURNAMENT_MANAGEMENT_COLUMNS = [
  {
    name: 'pageManagement.tournamentManagement.totalEvents',
    prop: 'eventCount',
    width: 150,
  },
];

export const SECTION_MANAGEMENT_COLUMNS = [
  {
    name: 'pageManagement.sectionManagement.layoutName',
    prop: 'title',
    sortable: false,
  },
  {
    name: 'pageManagement.sectionManagement.layoutCustomName',
    prop: 'data.sectionName',
    sortable: false,
  },
  {
    name: 'pageManagement.sectionManagement.createdBy',
    prop: 'createdBy.username',
    sortable: false,
  },
  {
    name: 'pageManagement.sectionManagement.updatedBy',
    prop: 'updatedBy.username',
    sortable: false,
  },
];

export const PLAYER_PAGES = {
  HOME: 'home',
  SPORT: 'sport',
  CASINO: 'casino',
  LIVE_CASINO: 'live casino',
};

export const PAGE_SECTIONS = {
  HERO_MULTIPLE_SLIDER: {
    layout: 'hero-multiple-slider',
    title: 'Hero Multiple Slider',
    page: PLAYER_PAGES.HOME,
  },
  CATEGORY_HOME_SLIDER: {
    layout: 'category-home-slider',
    title: 'Category Home Slider',
    page: PLAYER_PAGES.HOME,
  },
  CASINO_SLIDER_GRID_GAMES: {
    layout: 'casino-slider-grid-games',
    title: 'Gasino Slider Grid Games',
    page: PLAYER_PAGES.HOME,
  },
  SLIDER_PROMOTIONS: {
    layout: 'slider-promotions',
    title: 'Slider Promotions',
    page: PLAYER_PAGES.HOME,
  },
  CASINO_GRID: {
    layout: 'casino-grid',
    title: 'Casino Grid',
    page: PLAYER_PAGES.HOME,
  },
  STATIC_BANNERS: {
    layout: 'static-banners',
    title: 'Static Banners',
    page: PLAYER_PAGES.HOME,
  },
  BANNER_GRID: {
    layout: 'banner-grid',
    title: 'Banner Grid',
    page: PLAYER_PAGES.HOME,
  },
  CATEGORY_CARDS: {
    layout: 'category-cards',
    title: 'Category Cards',
    page: PLAYER_PAGES.HOME,
  },
  BETTING_CARDS: {
    layout: 'betting-card',
    title: 'Betting Cards',
    page: PLAYER_PAGES.HOME,
  },
  HOME_SLIDER: {
    layout: 'home-slider',
    title: 'Home Slider',
    page: PLAYER_PAGES.HOME,
  },
  REGISTER_USER: {
    layout: 'register-user',
    title: 'Register User',
    page: PLAYER_PAGES.HOME,
  },
  SPORT_LEFT_BANNER: {
    layout: 'sport-left-banner',
    title: 'Sport Left Banner',
    page: PLAYER_PAGES.SPORT,
  },
  SPORT_RIGHT_BANNER: {
    layout: 'sport-right-banner',
    title: 'Sport Right Banner',
    page: PLAYER_PAGES.SPORT,
  },
  CASINO_HERO_SLIDER: {
    layout: 'casino-hero-slider',
    title: 'Casino Hero Slider',
    page: PLAYER_PAGES.CASINO,
  },
  CASINO_CATEGORIES_IMAGES: {
    layout: 'casino-categories-images',
    title: 'Casino Categories Images',
    page: PLAYER_PAGES.CASINO,
  },
  CASINO_CATEGORY_PARALLAX: {
    layout: 'casino-category-parallax',
    title: 'Casino Category Parallax',
    page: PLAYER_PAGES.CASINO,
  },
  LIVE_CASINO_HERO_SLIDER: {
    layout: 'live_casino-hero-slider',
    title: 'Live Casino Hero Slider',
    page: PLAYER_PAGES.LIVE_CASINO,
  },
  LIVE_CASINO_CATEGORY_PARALLAX: {
    layout: 'live-casino-category-parallax',
    title: 'Live Casino Category Parallax',
    page: PLAYER_PAGES.LIVE_CASINO,
  },
};

export const ANIMATIONS = [
  { label: 'jump', value: 'jump' },
  { label: 'spin', value: 'spin' },
  { label: 'grow', value: 'grow' },
];

export const SECTION_NG_SELECT_ICONS = [
  {
    label: 'live',
    value: 'live',
  },
  {
    label: 'casino',
    value: 'casino',
  },
  {
    label: 'curved-up-arrow',
    value: 'curved-up-arrow',
  },
  {
    label: 'betting-history',
    value: 'betting-history',
  },
  {
    label: 'today',
    value: 'today',
  },
  {
    label: 'gift-bonus',
    value: 'gift-bonus',
  },
  {
    label: 'soccer-uno',
    value: 'soccer-uno',
  },
];

export const MAX_FILE_SIZE_IN_MB = 1;

export const FORM_FIELD_SECTIONS: FormFieldSections = {
  SECTION_NAME: 'sectionName',
  SECTION_TITLE: { es: 'sectionTitleEs', en: 'sectionTitleEn' },
  SECTION_URL: { es: 'sectionUrlEs', en: 'sectionUrlEn' },
  WIDTH: { es: 'widthEs', en: 'widthEn' },
  HEIGHT: { es: 'heightEs', en: 'heightEn' },
  KEEP_ASPECT: { es: 'keepAspectEs', en: 'keepAspectEn' },
  IMAGE_TITLE: { es: 'imageTitleEs', en: 'imageTitleEn' },
  IMAGE_ALT: { es: 'imageAltEs', en: 'imageAltEn' },
  URL: { es: 'urlEs', en: 'urlEn' },
  IMAGE: { es: 'imageEs', en: 'imageEn' },
  IS_SHOWED_DESKTOP: { es: 'isShowedInDesktopEs', en: 'isShowedInDesktopEn' },
  IS_SHOWED_MOBILE: { es: 'isShowedInMobileEs', en: 'isShowedInMobileEn' },
  TITLE: { es: 'titleEs', en: 'titleEn' },
  DESCRIPTION: { es: 'descriptionEs', en: 'descriptionEn' },
  BUTTON_TITLE: { es: 'buttonTitleEs', en: 'buttonTitleEn' },
  BUTTON_URL: { es: 'buttonUrlEs', en: 'buttonUrlEn' },
  IS_EXTERNAL_LINK: { es: 'isExternalLinkEs', en: 'isExternalLinkEn' },
  CASINO_CATEGORY: { es: 'casinoCategoryEs', en: 'casinoCategoryEn' },
};

export const FORM_FIELDS_CATEGORY_SLIDER: CategoryHomeForm = {
  SECTION_TITLE: { es: 'sectionTitleEs', en: 'sectionTitleEn' },
  SECTION_URL: { es: 'sectionUrlEs', en: 'sectionUrlEn' },
  WIDTH: { es: 'widthEs', en: 'widthEn' },
  HEIGHT: { es: 'heightEs', en: 'heightEn' },
  KEEP_ASPECT: { es: 'keepAspectEs', en: 'keepAspectEn' },
  IMAGE_TITLE: { es: 'imageTitleEs', en: 'imageTitleEn' },
  IMAGE_ALT: { es: 'imageAltEs', en: 'imageAltEn' },
  URL: { es: 'urlEs', en: 'urlEn' },
  IMAGE: { es: 'imageEs', en: 'imageEn' },
  IS_SHOWED_DESKTOP: { es: 'isShowedInDesktopEs', en: 'isShowedInDesktopEn' },
  IS_SHOWED_MOBILE: { es: 'isShowedInMobileEs', en: 'isShowedInMobileEn' },
};

export const FORM_FIELDS_HERO_CASINO: HeroCasinoForm = {
  TITLE: { es: 'titleEs', en: 'titleEn' },
  DESCRIPTION: { es: 'descriptionEs', en: 'descriptionEn' },
  IMAGE: { es: 'imageEs', en: 'imageEn' },
  BUTTON_TITLE: { es: 'buttonTitleEs', en: 'buttonTitleEn' },
  BUTTON_URL: { es: 'buttonUrlEs', en: 'buttonUrlEn' },
};

export const FORM_FIELDS_BANNER_GRID: BannerGridForm = {
  IMAGE_TITLE: { es: 'imageTitleEs', en: 'imageTitleEn' },
  IMAGE_ALT: { es: 'imageAltEs', en: 'imageAltEn' },
  IMAGE: { es: 'imageEs', en: 'imageEn' },
  URL: { es: 'urlEs', en: 'urlEn' },
  IS_EXTERNAL_LINK: { es: 'isExternalLinkEs', en: 'isExternalLinkEn' },
};
