import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { GlobalService } from '../services/ui/global.service';
import { map, Subject, takeUntil } from 'rxjs';
import { RoleService } from '../services/api/role.service';

export const permissionGuard: CanActivateFn = (route, state) => {
  let unsubscribe$: Subject<boolean> = new Subject<boolean>();
  let _globalService = inject(GlobalService);
  let _roleService = inject(RoleService);
  let router = inject(Router);

  return _globalService.profile.pipe(
    takeUntil(unsubscribe$),
    map((hasPermission) => {
      if (hasPermission && hasPermission.roleId) {
        const permissions: string[] = _roleService.getIdsWithTrueStatus(
          hasPermission.roleId.permissions
        );

        const requiredPermission = route.data['permission'] as string;

        if (permissions.includes(requiredPermission)) {
          return true;
        } else {
          router.navigate(['/profile/profile-detail']);

          return false;
        }
      }
      return false;
    })
  );
};
