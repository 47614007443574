<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">
    {{ "userManagement.users.add" | translate }}
  </h5>
  <button type="button" class="btn-close" (click)="closeModal()"></button>
</div>
<h6 class="text-center mt-2 mb-2">
  {{ "words.selectUserType" | translate }}
</h6>
<div class="modal-body search-page">
  <ul id="top-tab" role="tablist" class="nav nav-tabs search-list">
    <li class="nav-item bg-light-blue">
      <a
        class="nav-link txt-blue"
        (click)="openAddAgentModal(BUTTON_ACTIONS.ADD)"
      >
        <i class="fa fa-user-secret"></i>
        {{ "agents.agent.addAgent" | translate }}
      </a>
    </li>
    <li class="nav-item bg-light-warning">
      <a
        class="nav-link txt-warning"
        (click)="openAddCollaboratorModal(BUTTON_ACTIONS.ADD)"
      >
        <i class="fa fa-users"></i>
        {{ "collaborators.collaborator.addCollaborator" | translate }}
      </a>
    </li>
    <li class="nav-item bg-light-danger">
      <a
        class="nav-link txt-danger"
        (click)="openAddPlayerModal(BUTTON_ACTIONS.ADD)"
      >
        <i class="fa fa-gamepad"></i>
        {{ "players.player.addPlayer" | translate }}
      </a>
    </li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="closeModal()">
    {{ "btn.cancel" | translate }}
  </button>
</div>
