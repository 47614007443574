import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "socialNetworkBadge",
})
export class SocialNetworkBadgePipe implements PipeTransform {
  transform(value: string): string {
    switch (value.toLowerCase()) {
      case "facebook":
        return "badge bg-facebook";
      case "instagram":
        return "badge bg-instagram";
      case "youtube":
        return "badge bg-youtube";
      case "tiktok":
        return "badge bg-tiktok";
      default:
        return "badge bg-default";
    }
  }
}
