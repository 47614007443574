<div class="card widget-1">
  <div class="card-body">
    <div class="widget-content">
      <div class="widget-round {{ randomSvgColor }}">
        <div class="bg-round">
          <svg class="svg-fill" [ngClass]="randomSvgColor">
            <use
              attr.xlink:href="assets/svg/icon-sprite.svg#{{ tag.icon }}"
            ></use>
          </svg>
          <svg class="half-circle svg-fill">
            <use href="assets/svg/icon-sprite.svg#halfcircle"></use>
          </svg>
        </div>
      </div>

      <div class="d-flex align-items-end gap-1">
        @if (isLoading) {
        <div class="skeleton text-skeleton rounded-3"></div>
        } @else {
        <div>
          <h4>{{ tag.price }}</h4>
          <span class="f-light">{{ tag.title | translate }}</span>
        </div>
        }
        <span class="f-12 f-w-500 font-{{ tag.colorClass }}">
          <span class="f-light"> {{ tag.growth }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
