import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { map, Subject, takeUntil } from 'rxjs';
import { RoleService } from 'src/app/core/services/api/role.service';
import { GlobalService } from 'src/app/core/services/ui/global.service';

@Directive({
  selector: '[appShowWithPermission]',
})
export class ShowWithPermissionDirective implements OnInit, OnDestroy {
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  private permissionIds: string | null = null;
  private currentUser: string[] = [];
  private isActive: boolean = false;

  constructor(
    private _globalService: GlobalService,
    private _viewContainerRef: ViewContainerRef,
    private _templateRef: TemplateRef<HTMLElement>,
    private _roleService: RoleService
  ) {}

  ngOnInit(): void {
    this.loadUserRoleWhithPermissions();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  loadUserRoleWhithPermissions(): void {
    this._globalService.profile
      .pipe(
        takeUntil(this.unsubscribe$),
        map((hasPermission) => hasPermission?.roleId || [])
      )
      .subscribe((hasPermission) => {
        this.isActive = hasPermission.isActive;

        this.currentUser = this._roleService.getIdsWithTrueStatus(
          hasPermission.permissions
        );

        if (this.isActive && this.permissionIds) {
          this.updateView();
        }
      });
  }

  @Input()
  set appShowWithPermission(menuId: string) {
    this._viewContainerRef.createEmbeddedView(this._templateRef);

    this.permissionIds = menuId;

    if (this.currentUser.length > 0 && this.isActive) {
      this.updateView();
    }
  }

  private updateView(): void {
    this._viewContainerRef.clear();

    if (this.checkPermission()) {
      this._viewContainerRef.createEmbeddedView(this._templateRef);
    }
  }

  private checkPermission(): boolean {
    if (this.currentUser.length === 0 || !this.permissionIds) {
      return false;
    }

    return this.currentUser.includes(this.permissionIds);
  }
}
