@if (playerNotesForm) {
<form [formGroup]="playerNotesForm" (ngSubmit)="onSubmit()">
  <div class="card-body">
    <div class="modal-body">
      <div class="form-group">
        <label for="title" class="form-label">
          {{ "players.notes.title" | translate }}:
        </label>
        <input
          type="text"
          id="title"
          formControlName="title"
          class="form-control form-control-sm"
          placeholder="{{ 'players.notes.titlePlaceholder' | translate }}"
        />
      </div>
      <div class="form-group mt-3">
        <label for="content" class="form-label">
          {{ "players.notes.label" | translate }}:
        </label>
        <textarea
          id="content"
          formControlName="content"
          class="form-control"
          placeholder="{{ 'players.notes.placeholder' | translate }}"
          rows="4"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{ "btn.cancel" | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-secondary"
      [disabled]="playerNotesForm.invalid"
    >
      {{ "players.notes.agregateNote" | translate }}
    </button>
  </div>
</form>
}
