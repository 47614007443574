import { Component } from '@angular/core';
import {
  PERMITTED_VIEWS,
  USER_TYPE,
} from 'src/app/core/helpers/global/global.constant';
import { BUTTON_ACTIONS } from 'src/app/core/helpers/ui/ui.constant';
import { ModalWithAction } from 'src/app/core/interfaces/ui/bootstrap-modal.interface';
import { BootstrapModalService } from 'src/app/core/services/ui/bootstrap-modal.service';
import { PlayerModalFormComponent } from 'src/app/features/player/components/modals/player-modal-form/player-modal-form.component';
import { AgentCreateModalComponent } from 'src/app/features/user-management/components/agent-create-modal/agent-create-modal.component';
import { UserModalFormComponent } from 'src/app/features/user-management/components/user-modal-form/user-modal-form.component';

@Component({
  selector: 'app-user-type-selector',
  templateUrl: './user-type-selector.component.html',
})
export class UserTypeSelectorComponent {
  public BUTTON_ACTIONS = BUTTON_ACTIONS;
  public USER_TYPES = USER_TYPE;
  public PERMITTED_VIEWS = PERMITTED_VIEWS;

  constructor(
    private modalService: BootstrapModalService<ModalWithAction<any>>
  ) {}

  openModal(selection: string) {
    let component = undefined;
    switch (selection) {
      case this.USER_TYPES.AGENT:
        component = AgentCreateModalComponent;
        break;
      case this.USER_TYPES.COLABORATOR:
        component = UserModalFormComponent;
        break;
      case this.USER_TYPES.PLAYER:
        component = PlayerModalFormComponent;
        break;
      default:
        break;
    }

    this.modalService.openModal({
      component: component,
      data: {
        buttonAction: BUTTON_ACTIONS.ADD,
      },
    });
  }
}
