export const USER_VALIDATIONS = {
  USER_NAME: { MAX_LENGTH: 20 },
  EMAIL: { MAX_LENGTH: 50 },
  BALANCE: { INT: 6, DEC: 2 },
  CREDIT: { INT: 6, DEC: 2 },
};

export const USER_AGENT = 'Agents';

export const TYPES_USER_PERMISSIONS = {
  ADMIN: 'admin',
  AGENT: 'agent',
  CASHDESK: 'cashdesk',
  COLLABORATOR_AGENT: 'collaboratorAgent',
  COLLABORATOR_OPERATOR: 'collaboratorOperator',
};

export const OPTION_STATUS = [
  {
    value: true,
    label: 'words.active',
  },
  {
    value: false,
    label: 'words.inactive',
  },
];

export const ROUTE_USER_MANAGEMENT = {
  USER: '/user-management/users',
  AGENT: '/user-management/agents',
};

export const MAX_ALLOWED_USER_CURRENCIES = 8;

export const AFFILIATE_STATUS = {
  NOT_AFFILIATE: 'not_affiliate',
  PENDING: 'pending',
  AFFILIATE: 'affiliate',
  REJECTED: 'rejected',
};
