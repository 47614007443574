import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { BadgeStyleConfig } from 'src/app/core/interfaces/ui/ui.interface';

@Directive({
  selector: '[badgeStyler]',
})
export class CustomBadgeDirective implements OnChanges {
  @Input({ required: true })
  public badgeValue!: string;

  @Input({ required: true })
  public badgeEnum!: { [key: string]: string };

  @Input({ required: true })
  public badgeStyles!: Record<string, BadgeStyleConfig>;

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    const key = Object.keys(this.badgeEnum).find(
      (key) => this.badgeEnum[key] === this.badgeValue
    );

    if (key) {
      const badgeStyleConfig = this.badgeStyles[this.badgeEnum[key]];

      if (badgeStyleConfig) {
        this._renderer.setStyle(
          this._elementRef.nativeElement,
          'color',
          badgeStyleConfig.color
        );

        const backgroundColorWithOpacity = this.hexToRgba(
          badgeStyleConfig.color,
          0.1
        );

        this._renderer.setStyle(
          this._elementRef.nativeElement,
          'backgroundColor',
          backgroundColorWithOpacity
        );
      }
    }
  }

  private hexToRgba(hex: string, opacity: number): string {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgba(${parseInt(result[1], 16)}, ${parseInt(
          result[2],
          16
        )}, ${parseInt(result[3], 16)}, ${opacity})`
      : '';
  }
}
