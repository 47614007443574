import { Injectable } from "@angular/core";
import {
  ApiData,
  ApiMessage,
  ApiResponse,
} from "../../interfaces/api/api-response.interface";
import { Observable, tap } from "rxjs";
import { Player } from "../../interfaces/api/player.interface";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ToastrNotificationService } from "../ui/toastr-notification.service";
import { Note } from "../../interfaces/api/note.interface";

@Injectable({
  providedIn: "root",
})
export class NoteService {
  private apiUrl: string = environment.apiUrl;
  private path: string = "/note";

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: ToastrNotificationService
  ) {}

  getNotesbyAssigningId(
    assigningId: string
  ): Observable<ApiResponse<ApiData<Note[]>>> {
    const $URL = `${this.apiUrl}${this.path}/get-by-assigning-id`;
    return this._httpClient.get<ApiResponse<ApiData<Note[]>>>($URL, {
      params: { assigningId },
    });
  }

  createPlayerNote(request: object): Observable<ApiResponse<ApiData<{}>>> {
    const $URL = `${this.apiUrl}${this.path}/create`;
    return this._httpClient
      .post<ApiResponse<ApiData<{}>>>($URL, request)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  private showNotification(message: ApiMessage): void {
    this._notificationService.showNotification({
      title: "players.notes.note",
      message: message,
      type: "success",
    });
  }
}
