import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, tap } from "rxjs";

import { environment } from "src/environments/environment";
import {
  ApiData,
  ApiMessage,
  ApiResponse,
} from "../../interfaces/api/api-response.interface";
import {
  BankAccount,
  BankEntity,
  Transactions,
} from "../../interfaces/api/financial.interface";
import { NgSelect } from "../../interfaces/ui/ui.interface";
import { ToastrNotificationService } from "../ui/toastr-notification.service";

@Injectable({
  providedIn: "root",
})
export class FinancialService {
  private apiUrl: string = environment.apiUrl;
  private path: string = "/bank-account";
  private pathBank: string = "/bank";
  private pathTransactions: string = "/transaction";
  constructor(
    private _http: HttpClient,
    private _notificationService: ToastrNotificationService
  ) {}

  getAllBankFinancial(
    filter: object
  ): Observable<ApiResponse<ApiData<BankEntity[]>>> {
    const endpoint = `${this.apiUrl}${this.pathBank}/get-all-bank-financial`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._http.get<ApiResponse<ApiData<BankEntity[]>>>(endpoint, {
      params,
    });
  }

  getAllActiveHolders(
    filter: object
  ): Observable<ApiResponse<ApiData<BankAccount[]>>> {
    const endpoint = `${this.apiUrl}${this.path}/get-all-active-holders`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._http.get<ApiResponse<ApiData<BankAccount[]>>>(endpoint, {
      params,
    });
  }

  getAllDeleteHolders(
    filter: object
  ): Observable<ApiResponse<ApiData<BankAccount[]>>> {
    const endpoint = `${this.apiUrl}${this.path}/get-all-delete-holders`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._http.get<ApiResponse<ApiData<BankAccount[]>>>(endpoint, {
      params,
    });
  }

  registerBankEntities(
    request: BankEntity
  ): Observable<ApiResponse<ApiData<BankEntity>>> {
    const endpoint = `${this.apiUrl}${this.pathBank}/register`;
    return this._http
      .post<ApiResponse<ApiData<BankEntity>>>(endpoint, request)
      .pipe(
        tap((res) =>
          this.showNotification("financial.bankEntities.title", res.message)
        )
      );
  }

  registerHolders(
    request: BankAccount
  ): Observable<ApiResponse<ApiData<BankAccount>>> {
    const endpoint = `${this.apiUrl}${this.path}/create`;
    return this._http
      .post<ApiResponse<ApiData<BankAccount>>>(endpoint, request)
      .pipe(
        tap((res) =>
          this.showNotification("financial.holders.title", res.message)
        )
      );
  }

  updateBankEntities(
    id: string,
    request: Partial<BankEntity>
  ): Observable<ApiResponse<ApiData<BankEntity>>> {
    const endpoint = `${this.apiUrl}${this.pathBank}/update/${id}`;
    return this._http
      .put<ApiResponse<ApiData<BankEntity>>>(endpoint, request)
      .pipe(
        tap((res) =>
          this.showNotification("financial.bankEntities.title", res.message)
        )
      );
  }

  updateBankAccount(
    id: string,
    request: any
  ): Observable<ApiResponse<ApiData<BankEntity>>> {
    const endpoint = `${this.apiUrl}${this.path}/update/${id}`;
    return this._http
      .put<ApiResponse<ApiData<BankEntity>>>(endpoint, request)
      .pipe(
        tap((res) =>
          this.showNotification("financial.holders.title", res.message)
        )
      );
  }

  deleteBankEntity(id: string): Observable<ApiData<null>> {
    const endpoint = `${this.apiUrl}${this.pathBank}/delete/${id}`;
    return this._http.delete<ApiData<null>>(endpoint);
  }

  deleteAccountBank(id: string): Observable<ApiData<BankAccount>> {
    const endpoint = `${this.apiUrl}${this.path}/delete-bank-account/${id}`;
    return this._http.put<ApiData<BankAccount>>(endpoint, id);
  }

  restoreAccountBank(id: string): Observable<ApiData<BankAccount>> {
    const endpoint = `${this.apiUrl}${this.path}/restore-bank-account/${id}`;
    return this._http.put<ApiData<BankAccount>>(endpoint, id);
  }

  public findBanks(
    filter: object
  ): Observable<ApiResponse<ApiData<BankEntity[]>>> {
    const endpoint = `${this.apiUrl}${this.pathBank}/find`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._http.get<ApiResponse<ApiData<BankEntity[]>>>(endpoint, {
      params,
    });
  }

  public findBanksForSelect(
    filter: object
  ): Observable<ApiData<NgSelect<string>[]>> {
    return this.findBanks(filter).pipe(
      map((response) => ({
        result: response.data.result.map((bank) => ({
          label: bank.name,
          value: bank._id,
        })),
        totalCount: response.data.totalCount,
      }))
    );
  }

  public getAllTransactions(
    filter: object
  ): Observable<ApiResponse<ApiData<Transactions[]>>> {
    const endpoint = `${this.apiUrl}${this.pathTransactions}/find-all-transactions-filters`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._http.get<ApiResponse<ApiData<Transactions[]>>>(endpoint, {
      params,
    });
  }

  private showNotification(title: string, message: ApiMessage): void {
    this._notificationService.showNotification({
      title,
      message,
      type: "success",
    });
  }
}
