import { Pipe, PipeTransform } from "@angular/core";
import { CASHDESK_COMMISION_TYPE } from "src/app/core/helpers/global/betshop-management.constant";

@Pipe({
  name: "cashdeskCommisionType",
})
export class CashdeskCommisionTypePipe implements PipeTransform {
  transform(value: CASHDESK_COMMISION_TYPE): string {
    const key = Object.keys(CASHDESK_COMMISION_TYPE).find(
      (k) =>
        CASHDESK_COMMISION_TYPE[k as keyof typeof CASHDESK_COMMISION_TYPE] ===
        value
    );

    if (key) {
      return `betshopManagement.cashdesks.${key.toLowerCase()}`;
    }

    return value;
  }
}
