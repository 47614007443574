export const MESSAGE_TABLE_COLUMNS_INBOX = [
  {
    name: "players.messages.fromId",
    prop: "fromId.personalInfo.firstName",
  },
  {
    name: "players.messages.to",
    prop: "toId.firstName",
  },
  {
    name: "players.messages.subject",
    prop: "subject",
  },
  {
    name: "players.messages.message",
    prop: "message",
  },
];

export const MESSAGE_TABLE_COLUMNS_SENT = [
  {
    name: "players.messages.fromId",
    prop: "fromId.firstName",
  },
  {
    name: "players.messages.to",
    prop: "toId.personalInfo.firstName",
  },
  {
    name: "players.messages.subject",
    prop: "subject",
  },
  {
    name: "players.messages.message",
    prop: "message",
  },
];

export const MESSAGE_STATE = {
  0: "players.messages.sentTo",
  2: "players.messages.read",
  3: "players.messages.unread",
};

export const MESSAGE_TYPE_TAB = {
  INBOX: "inbox",
  SENT: "sent",
};
