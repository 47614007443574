import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ToastrNotificationService } from "../ui/toastr-notification.service";
import {
  ApiData,
  ApiResponse,
} from "../../interfaces/api/api-response.interface";
import { map, Observable } from "rxjs";
import { Language } from "../../interfaces/ui/language.interface";
import { NgSelect } from "../../interfaces/ui/ui.interface";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private apiUrl: string = environment.apiUrl;
  private path: string = "/language";

  constructor(private _http: HttpClient) {}

  public findLanguages(
    page: number = 1,
    limit: number = 10
  ): Observable<ApiResponse<ApiData<Language[]>>> {
    const endpoint = `${this.apiUrl}${this.path}/get-all?page=${page}&limit=${limit}`;
    return this._http.get<ApiResponse<ApiData<Language[]>>>(endpoint);
  }

  public findLanguagesForSelect(): Observable<ApiData<NgSelect<string>[]>> {
    return this.findLanguages().pipe(
      map((response) => ({
        result: response.data.result.map((language) => ({
          label: language.name,
          value: language._id,
        })),
        totalCount: response.data.totalCount,
      }))
    );
  }
}
