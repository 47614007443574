import { Pipe, PipeTransform } from '@angular/core';
import { CASHDESK_TYPE } from 'src/app/core/helpers/global/betshop-management.constant';

@Pipe({
  name: 'cashdeskType',
})
export class CashdeskTypePipe implements PipeTransform {
  transform(value: CASHDESK_TYPE): string {
    const key = Object.keys(CASHDESK_TYPE).find(
      (k) => CASHDESK_TYPE[k as keyof typeof CASHDESK_TYPE] === value
    );

    if (key) {
      return `betshopManagement.cashdesks.${key.toLowerCase()}`;
    }

    return value;
  }
}
