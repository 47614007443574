import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable, of, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { USER_AGENT } from "src/app/core/helpers/global/user-management.constant";
import { NG_SELECT_QUERIES } from "src/app/core/helpers/ui/ui.constant";
import { User } from "src/app/core/interfaces/api/user.interface";
import { ModalWithAction } from "src/app/core/interfaces/ui/bootstrap-modal.interface";
import {
  ButtonAction,
  NgSelect,
  NgSelectQuery,
} from "src/app/core/interfaces/ui/ui.interface";
import { PlayerService } from "src/app/core/services/api/player.service";
import { UserService } from "src/app/core/services/api/user.service";
import { BootstrapModalService } from "src/app/core/services/ui/bootstrap-modal.service";
import { FilterService } from "src/app/core/services/ui/filter.service";
import { GlobalService } from "src/app/core/services/ui/global.service";
import { SearchNgSelectService } from "src/app/core/services/ui/search-ng-select.service";
import { AppState } from "src/app/core/states/app.state";
import { selectAuth } from "src/app/core/states/auth/auth.selectors";

@Component({
  selector: "app-move-player",
  templateUrl: "./move-player.component.html",
  providers: [{ provide: "ngUsers", useClass: SearchNgSelectService }],
})
export class MovePlayerComponent implements OnInit, OnDestroy {
  public playerTransferForm: FormGroup | undefined = undefined;
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  public activeButtonAction: ButtonAction | undefined;
  private playerId: string | undefined;
  public users$: Observable<NgSelect<string>[]> = of([]);
  public userProfile!: User;
  private _subscriptions: Subscription[] = [];
  public NG_SELECT_QUERIES = NG_SELECT_QUERIES;
  constructor(
    @Inject("ngUsers")
    private _ngUsers: SearchNgSelectService<string>,

    private _formBuilder: FormBuilder,
    private _playerService: PlayerService,
    private _bsModalService: BootstrapModalService<ModalWithAction<any>>,
    private _userService: UserService,
    private _filterService: FilterService<object>,
    private _profileService: GlobalService
  ) {}

  ngOnInit(): void {
    this._profileService.profile.subscribe((profile) => {
      this.userProfile = profile;
    });
    this.playerTransferForm = this.initializeForm();
    this.subscribeToModalData();
    this.setConfigNgSelect();
  }

  private initializeForm(): FormGroup {
    return this._formBuilder.group({
      currAgentId: [null, [Validators.required]],
    });
  }

  private subscribeToModalData(): void {
    this._bsModalService
      .getDataIssued()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ buttonAction, selectedRow }) => {
          this.activeButtonAction = buttonAction;
          this.playerId = selectedRow?._id;
        },
        error: () => this.closeModal(),
      });
  }

  public onSubmit(): void {
    if (this.playerTransferForm?.invalid || !this.playerId) {
      return;
    }
    const transferData = {
      ...this.playerTransferForm?.value,
      playerId: [this.playerId],
      transferredById: this.userProfile._id,
    };

    this._playerService
      .setPlayerParent(transferData)
      .subscribe(() => this.afterSubmitForm());
  }

  public onSearchSelect(query: NgSelectQuery, term?: string): void {
    const termText = term || "";
    const actionMap = new Map<NgSelectQuery, () => void>([
      [NG_SELECT_QUERIES.USERS, () => this._ngUsers.searchTerm(termText)],
    ]);
    const action = actionMap.get(query);
    if (action) action();
  }

  public onScrollToEndSelect(query: NgSelectQuery): void {
    const actionMap = new Map<NgSelectQuery, () => void>([
      [NG_SELECT_QUERIES.USERS, () => this._ngUsers.scrollToEnd()],
    ]);
    const action = actionMap.get(query);
    if (action) action();
  }

  private setConfigNgSelect(): void {
    this._ngUsers.setSearchTermKey("username");
    this._ngUsers.setFetchDataFunction(
      this._userService.findUsersForSelect.bind(this._userService)
    );
    this.users$ = this._ngUsers.getData();
    this._ngUsers.extendFilter({ type: USER_AGENT });
    this._ngUsers.triggerFetchData();
  }

  public onReset(): void {
    this.playerTransferForm?.reset();
  }

  public closeModal(): void {
    this._bsModalService.closeModal();
  }

  private afterSubmitForm(): void {
    this._filterService.updateFilterData({});
    this.closeModal();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
