import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { LanguageComponent } from "src/app/core/interfaces/ui/language.interface";
import { NavService } from "src/app/core/services/ui/nav.service";
import { change } from "src/app/core/states/language/language.actions";
import { selectLanguage } from "src/app/core/states/language/language.selectors";

@Component({
  selector: "shared-header-languages",
  templateUrl: "./languages.component.html",
  styleUrls: ["./languages.component.scss"],
})
export class LanguagesComponent implements OnInit {
  public language: boolean = false;

  public languages: LanguageComponent[] = [
    {
      language: "English",
      code: "en",
      type: "US",
      icon: "us",
    },
    {
      language: "Español",
      code: "es",
      type: "ES",
      icon: "es",
    },
  ];

  public selectedLanguage: LanguageComponent = {
    language: "English",
    code: "en",
    type: "US",
    icon: "us",
  };

  public language$ = this.store.select(selectLanguage);
  constructor(
    public navServices: NavService,
    private translate: TranslateService,
    private store: Store
  ) {
    this.language$.subscribe((language) => {
      const userLanguage = this.languages.find(
        (lang) => lang.code === language
      );
      if (userLanguage) {
        this.selectedLanguage = userLanguage;
      }
    });
  }
  //
  ngOnInit() {}

  public changeLanguage(lang: LanguageComponent) {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
    this.store.dispatch(change({ language: lang.code }));
  }
}
