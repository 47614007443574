import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, catchError, filter, of, tap } from "rxjs";
import { AVAILABLE_LANGUAGES } from "../../helpers/global/global.constant";
import { LanguageCode } from "../../interfaces/ui/language.interface";
import { AppState } from "../../states/app.state";
import { selectLanguage } from "../../states/language/language.selectors";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public code: BehaviorSubject<LanguageCode> =
    new BehaviorSubject<LanguageCode>(AVAILABLE_LANGUAGES.ES);

  constructor(private _store: Store<AppState>) {
    this.initializeLanguage();
  }

  private initializeLanguage(): void {
    this._store
      .select(selectLanguage)
      .pipe(
        filter((language) => !!language),
        tap((language) => this.code.next(language)),
        catchError(() => {
          this.code.next(AVAILABLE_LANGUAGES.ES);
          return of(AVAILABLE_LANGUAGES.ES);
        })
      )
      .subscribe();
  }
}
