import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PlayerService } from "src/app/core/services/api/player.service";
import { BootstrapModalService } from "src/app/core/services/ui/bootstrap-modal.service";
import { ModalWithAction } from "src/app/core/interfaces/ui/bootstrap-modal.interface";
import { selectAuth } from "src/app/core/states/auth/auth.selectors";
import { User } from "src/app/core/interfaces/api/user.interface";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/core/states/app.state";
import { FilterService } from "src/app/core/services/ui/filter.service";
import { title } from "process";
import { NoteService } from "src/app/core/services/api/note.service";
import { GlobalService } from "src/app/core/services/ui/global.service";

@Component({
  selector: "app-player-notes",
  templateUrl: "./player-notes.component.html",
})
export class PlayerNoteFormComponent implements OnInit, OnDestroy {
  public playerId: string | undefined;

  public playerNotesForm: FormGroup | undefined = undefined;
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  public userProfile!: User;
  private profile$ = this._globalService.profile

  private _subscriptions: Subscription[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _playerService: PlayerService,
    private _bsModalService: BootstrapModalService<ModalWithAction<any>>,
    private _toastr: ToastrService,
    private _translateService: TranslateService,
    private _store: Store<AppState>,
    private _filterService: FilterService<object>,
    private _noteService: NoteService,
    private _globalService: GlobalService
  ) {}

  ngOnInit(): void {
    this.getProfile();
    this.playerNotesForm = this.initializeForm();
    this.subscribeToModalData();
  }

  private getProfile(): void {
    this._subscriptions.push(
      this.profile$.subscribe((value) => {
        this.userProfile = value;
      })
    );
  }

  private initializeForm(): FormGroup {
    return this._formBuilder.group({
      content: ["", [Validators.required]],
      title: ["", [Validators.required]],
    });
  }

  private subscribeToModalData(): void {
    this._bsModalService
      .getDataIssued()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ selectedRow }) => {
          this.playerId = selectedRow?._id;
        },
        error: () => this.closeModal(),
      });
  }

  public onSubmit(): void {
    if (this.playerNotesForm?.invalid || !this.playerId) {
      return;
    }

    const formValues = this.playerNotesForm?.value;

    const request = {
      assigningId: this.playerId,
      assigningType: "Player",
      assignedId: this.userProfile._id,
      assignedType: "User",
      title: formValues.title,
      content: formValues.content,
    };

    this._noteService.createPlayerNote(request).subscribe({
      next: () => {
        this.afterSubmitForm();
        this.closeModal();
      },
    });
  }

  public closeModal(): void {
    this._bsModalService.closeModal();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  private afterSubmitForm(): void {
    this._filterService.updateFilterData({});
    this.closeModal();
  }
}
