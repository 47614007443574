<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">
    {{ 'userManagement.collaboratorAg.add' | translate }}
  </h5>
  <button type="button" class="btn-close" (click)="closeModal()"></button>
</div>

@if (agentModalForm) {
<form
  autocomplete="off"
  class="form theme-form"
  [formGroup]="agentModalForm"
  (ngSubmit)="onSubmit()"
>
  <div class="modal-body">
    <div class="row g-2">
      <h5 class="mb-0 mt-3 mt-3">
        {{ 'userManagement.collaboratorAg.personalInfo' | translate }}
      </h5>
      <hr />
      <div class="col-md-6 col-lg-4">
        <div class="form-group" appRequiredAsterisk>
          <label for="firstName" class="form-label">
            {{ 'userManagement.users.firstName' | translate }}
          </label>
          <input
            type="text"
            id="firstName"
            formControlName="firstName"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group" appRequiredAsterisk>
          <label for="lastName" class="form-label">
            {{ 'userManagement.users.lastName' | translate }}
          </label>
          <input
            type="text"
            id="lastName"
            formControlName="lastName"
            class="form-control"
          />
        </div>
      </div>

      <div class="form-group col-md-4" appRequiredAsterisk>
        <label for="documentNumber" class="form-label">
          {{ 'agents.agent.personalInfo.dni' | translate }}
        </label>
        <input
          type="text"
          id="documentNumber"
          formControlName="documentNumber"
          class="form-control"
        />
      </div>
      @if (!(profile.userType === USER_TYPE.AGENT || profile.userType ===
      USER_TYPE.CASHDESK)) {
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="countryId" class="form-label">
            {{ 'words.country' | translate }} <span class="text-danger">*</span>
          </label>
          <ng-select
            id="countryId"
            formControlName="countryId"
            [items]="countries$ | async"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [clearable]="false"
            [notFoundText]="'words.noItemsFound' | translate"
            (search)="onSearchSelect(NG_SELECT_QUERIES.COUNTRIES, $event.term)"
            (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.COUNTRIES)"
            (change)="onCountryChange($event)"
            [readonly]="
              profile.userType === USER_TYPE.AGENT ||
              profile.userType === USER_TYPE.CASHDESK
            "
          >
            <ng-template ng-option-tmp let-item="item">
              @if (item.icon) {
              <i
                class="flag-icon flag-icon-{{ item.icon | lowercase }} m-r-5 "
              ></i>
              }
              <span>{{ item.label }}</span>
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
              @if (item.icon) {
              <i
                class="flag-icon flag-icon-{{ item.icon | lowercase }} m-r-5"
              ></i>
              }
              <span>{{ item.label }}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="cityId" class="form-label">
            {{ 'words.city' | translate }} <span class="text-danger">*</span>
          </label>
          <ng-select
            id="cityId"
            formControlName="cityId"
            [items]="cities$ | async"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [clearable]="false"
            [notFoundText]="'words.noItemsFound' | translate"
            (search)="onSearchSelect(NG_SELECT_QUERIES.CITIES, $event.term)"
            (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.CITIES)"
            [readonly]="
              profile.userType === USER_TYPE.AGENT ||
              profile.userType === USER_TYPE.CASHDESK
            "
          >
          </ng-select>
        </div>
      </div>
      }

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="birthDate" class="form-label">
            {{ 'agents.agent.personalInfo.birthDate' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="date"
            id="birthDate"
            formControlName="birthDate"
            class="form-control"
          />
          <shared-form-field-error
            [control]="agentModalForm.get('birthDate')"
            [errorMessages]="{
              underage: 'words.mustBeAdult',
              required: 'words.requiredField'
            }"
          >
          </shared-form-field-error>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <div class="form-group">
          <label for="address" class="form-label">
            {{ 'userManagement.users.address' | translate }}
          </label>
          <input
            type="text"
            id="address"
            formControlName="address"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-4" appRequiredAsterisk>
        <label for="gender" class="form-label">
          {{ 'players.player.gender' | translate }}
        </label>
        <ng-select
          id="gender"
          formControlName="gender"
          [items]="genders$ | async"
          bindLabel="label"
          bindValue="value"
        >
          <ng-template ng-option-tmp let-item="item">
            {{ item.label | translate }}
          </ng-template>

          <ng-template ng-label-tmp let-item="item">
            {{ item.label | translate }}
          </ng-template></ng-select
        >
      </div>
      <h5 class="mb-0 mt-3 mt-3">
        {{ 'agents.agent.contactInfo.contactInfo' | translate }}
      </h5>
      <hr />
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="email" class="form-label">
            {{ 'userManagement.users.email' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="email"
            id="email"
            formControlName="email"
            class="form-control"
          />
          <shared-form-field-error
            [control]="agentModalForm.get('email')"
            [errorMessages]="{
              email: 'words.invalidEmail',
              required: 'words.requiredField'
            }"
          >
          </shared-form-field-error>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="mobileNumber" class="form-label">
            {{ 'userManagement.users.mobileNumber' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            id="mobileNumber"
            formControlName="mobileNumber"
            class="form-control"
            (keypress)="validateKeypress($event)"
            (paste)="validatePaste($event)"
          />
          <shared-form-field-error
            [control]="agentModalForm.get('mobileNumber')?.errors?.['invalidPhoneNumber']"
            [errorMessages]="{ required: 'words.requiredField' }"
          >
          </shared-form-field-error>

          <shared-form-field-error
            [control]="agentModalForm.get('mobileNumber')"
            [errorMessages]="{
              invalidPhoneNumber: 'words.invalidPhoneNumber',
              required: 'words.requiredField'
            }"
          >
          </shared-form-field-error>
        </div>
      </div>
      @if (!(profile.userType === USER_TYPE.AGENT || profile.userType ===
      USER_TYPE.CASHDESK)) {
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="languageId" class="form-label">
            {{ 'words.language' | translate }}
            <span class="text-danger">*</span>
          </label>
          <ng-select
            id="languageId"
            formControlName="languageId"
            [items]="languages$ | async"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [clearable]="false"
            [notFoundText]="'words.noItemsFound' | translate"
            (search)="onSearchSelect(NG_SELECT_QUERIES.LANGUAGE, $event.term)"
            (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.LANGUAGE)"
            [readonly]="
              profile.userType === USER_TYPE.AGENT ||
              profile.userType === USER_TYPE.CASHDESK
            "
          >
          </ng-select>
        </div>
      </div>
      }
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="conventionalNumber" class="form-label">
            {{ 'userManagement.users.conventionalNumber' | translate }}
          </label>
          <input
            type="text"
            id="conventionalNumber"
            formControlName="conventionalNumber"
            class="form-control"
            onlyNumbers
          />
        </div>
      </div>
      <h5 class="mb-0 mt-3">
        {{ 'agents.agent.agencyInfo.agencyInfo' | translate }}
      </h5>
      <hr />
      <div class="col-md-6 col-lg-8">
        <div class="form-group">
          <label for="username" class="form-label">
            {{ 'userManagement.users.username' | translate }}
            <span class="text-danger">*</span>
          </label>
          <i
            class="fa fa-info-circle text-secondary mx-1"
            ngbTooltip="{{ 'agents.agent.usernameInfo' | translate }}"
            tooltipClass="custom-tooltip"
          ></i>
          <input
            type="text"
            id="username"
            formControlName="username"
            class="form-control"
          />
          <shared-form-field-error
            [control]="agentModalForm.get('username')"
            [errorMessages]="{
              pattern: 'words.usernameError',
              required: 'words.requiredField'
            }"
          >
          </shared-form-field-error>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="roleId" class="form-label">
            {{ 'userManagement.users.role' | translate }}
            <span class="text-danger">*</span>
          </label>
          <ng-select
            id="roleId"
            formControlName="roleId"
            [items]="roles$ | async"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [notFoundText]="'words.noItemsFound' | translate"
            (change)="onRoleChange($event)"
            (search)="onSearchSelect(NG_SELECT_QUERIES.USER_ROLES, $event.term)"
            (clear)="onSearchSelect(NG_SELECT_QUERIES.USER_ROLES)"
            (close)="onSearchSelect(NG_SELECT_QUERIES.USER_ROLES)"
            (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.USER_ROLES)"
          >
          </ng-select>
          <shared-form-field-error
            [control]="agentModalForm.get('roleId')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>
      @if (activeButtonAction === BUTTON_ACTIONS.ADD) {
      <div class="col-md-6 col-lg-6">
        <div class="form-group position-relative">
          <label for="password" class="form-label">
            {{ 'userManagement.users.password' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            [type]="showPassword ? 'text' : 'password'"
            id="password"
            formControlName="password"
            class="form-control"
          />
          <i
            class="fas"
            [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
            (click)="togglePasswordVisibility()"
            style="
              position: absolute;
              top: 65%;
              right: 10px;
              cursor: pointer;
              transform: translateY(-50%);
            "
          ></i>
        </div>
        <shared-form-field-error
          [control]="agentModalForm.get('password')"
          [errorMessages]="{
            strongPassword: 'words.passwordWeak',
            required: 'words.requiredField'
          }"
        >
        </shared-form-field-error>
      </div>

      }
      <div class="col-md-6 col-lg-6">
        <label for="parentId" class="form-label">
          {{ 'userManagement.collaboratorAg.collabotadorAgenciTo' | translate }}
        </label>
        @if (this.profile.userType === USER_TYPE.AGENT || this.profile.userType
        === USER_TYPE.CASHDESK) {
        <div class="form-group">
          <input
            id="parentId"
            class="form-control disabled"
            [value]="profile.username"
            [readonly]="true"
          />
        </div>
        } @if (this.profile.userType !== USER_TYPE.AGENT &&
        this.profile.userType !== USER_TYPE.CASHDESK) {
        <ng-select
          id="parentId"
          formControlName="parentId"
          [items]="users$ | async"
          [bindLabel]="'label'"
          [bindValue]="'value'"
          [clearable]="true"
          [notFoundText]="'words.noItemsFound' | translate"
          (change)="onParentUserChange($event)"
          (search)="onSearchSelect(NG_SELECT_QUERIES.USERS, $event.term)"
          (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.USERS)"
        >
        </ng-select>
        }
      </div>
      @if (activeButtonAction !== BUTTON_ACTIONS.ADD) {
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="createdAt" class="form-label">
            {{ 'words.createdAt' | translate }}
          </label>
          <input
            type="text"
            id="createdAt"
            class="form-control"
            [disabled]="true"
            [value]="createdAt | date : FORMAT_FOR_DATES"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="updatedAt" class="form-label">
            {{ 'words.updatedAt' | translate }}
          </label>
          <input
            type="text"
            id="updatedAt"
            class="form-control"
            [disabled]="true"
            [value]="updatedAt | date : FORMAT_FOR_DATES"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <div class="animate-chk">
            <label for="state" class="d-block">
              {{ 'userManagement.users.state' | translate }}
              <input
                id="state"
                type="checkbox"
                class="checkbox_animated m-l-5"
                formControlName="state"
              />
            </label>
          </div>
        </div>
      </div>

      @if (activeButtonAction === BUTTON_ACTIONS.VIEW) {
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <div class="animate-chk">
            <label for="TFA" class="d-block">
              TFA
              <input
                id="TFA"
                type="checkbox"
                class="checkbox_animated m-l-5"
                formControlName="TFA"
              />
            </label>
          </div>
        </div>
      </div>
      }
      <!--  -->
      }
    </div>
  </div>

  @if (activeButtonAction !== BUTTON_ACTIONS.VIEW) {
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{ 'btn.cancel' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-secondary"
      [disabled]="agentModalForm.invalid"
    >
      {{ 'btn.save' | translate }}
    </button>
  </div>
  }
</form>
}
