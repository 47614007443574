<p class="text-center mt-5 mb-5">
  {{ "players.activePlayer.confirmationMessagePlayerBlocked" | translate }}
</p>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="closeModal()">
    {{ "btn.cancel" | translate }}
  </button>
  <button type="button" class="btn btn-secondary" (click)="blockPlayer()">
    {{ "players.activePlayer.blockPlayer" | translate }}
  </button>
</div>
