import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import {
  BACK_OFFICE,
  STATE_TRANSACTION,
  TYPE_TRANSACTION,
  USER_TYPE,
} from "src/app/core/helpers/global/global.constant";
import { NG_SELECT_QUERIES } from "src/app/core/helpers/ui/ui.constant";
import { SelectedPlayer } from "src/app/core/interfaces/api/player.interface";
import { SelectedUser, User } from "src/app/core/interfaces/api/user.interface";
import {
  NgSelect,
  NgSelectQuery,
} from "src/app/core/interfaces/ui/ui.interface";
import { CurrencyService } from "src/app/core/services/api/currency.service";
import { FinancialService } from "src/app/core/services/api/financial.service";
import { PlayerService } from "src/app/core/services/api/player.service";
import { TransferService } from "src/app/core/services/api/transfer-user.service";
import { UserService } from "src/app/core/services/api/user.service";
import { BootstrapModalService } from "src/app/core/services/ui/bootstrap-modal.service";
import { GlobalService } from "src/app/core/services/ui/global.service";
import { ImageCompressionService } from "src/app/core/services/ui/image-compression.service";
import { SearchNgSelectService } from "src/app/core/services/ui/search-ng-select.service";
import { UserActions } from "src/app/core/states/auth/auth.actions";
interface FilePreview {
  file: File;
  isPreviewVisible: boolean;
  urlFile: string;
}

@Component({
  selector: "app-transfer-user-form",
  templateUrl: "./transfer-user-form.component.html",
  styleUrl: "./transfer-user-form.component.scss",
  providers: [
    { provide: "ngUsers", useClass: SearchNgSelectService },
    { provide: "ngBanks", useClass: SearchNgSelectService },
    { provide: "ngPlayers", useClass: SearchNgSelectService },
    { provide: "ngCurrencies", useClass: SearchNgSelectService },
  ],
})
export class TransferUserFormComponent implements OnInit, OnDestroy {
  @ViewChild("fileInput") fileInput!: ElementRef;
  @Output() transactionTypeChange = new EventEmitter<string>();

  public users$: Observable<NgSelect<string>[]> = of([]);
  public banks$: Observable<NgSelect<string>[]> = of([]);
  public players$: Observable<NgSelect<string>[]> = of([]);
  public currencies$: Observable<NgSelect<string>[]> = of([]);
  public transferForm: FormGroup = new FormGroup({});
  public selectedOption: string | null = null;
  public USER_TYPE = USER_TYPE;
  public selectedUserBalance: number = 0;
  public selectedUserCredit: number = 0;
  public selectedCodeCurrency: string = "";
  public selectedPlayerBalance: number = 0;
  public selectedPlayerCredit: number = 0;
  public selectedCodeCurrencyPlayer: string = "";
  public previousBalance: number = 0;
  public currentBalance: number = 0;
  public selectedIdCurrencyPlayer: string = "";
  public selectedIdCurrency: string = "";
  public selectedUserData: SelectedUser | null = null;
  public NG_SELECT_QUERIES = NG_SELECT_QUERIES;

  //IMG
  public previewImage: string | undefined;
  public selectedFiles: FilePreview[] = [];

  //SWITCHES
  public TYPE_TRANSACTIONS = TYPE_TRANSACTION;
  public transaction_type: string = TYPE_TRANSACTION.DEPOSIT;
  public isCredit: boolean = false;
  public isBanking: boolean = false;
  public profile: User = {} as User;

  constructor(
    @Inject("ngUsers")
    private _ngUsers: SearchNgSelectService<string>,
    @Inject("ngBanks")
    private _ngBanks: SearchNgSelectService<string>,
    @Inject("ngPlayers")
    private _ngPlayers: SearchNgSelectService<string>,
    @Inject("ngCurrencies")
    private _ngCurrencies: SearchNgSelectService<string>,
    private _userService: UserService,
    private _playerService: PlayerService,
    private _currenciesService: CurrencyService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private _financialService: FinancialService,
    private modalService: BootstrapModalService<string>,
    private toastr: ToastrService,
    private translate: TranslateService,
    private _profileService: GlobalService,
    private imageCompressionService: ImageCompressionService,
    private _transferService: TransferService,
    private store: Store
  ) {
    this.transferForm = this.getConfigForm();
  }

  ngOnInit(): void {
    this.getModalData();
    this.setConfigNgSelect();
    this.setRequiredValidators();
    this.loadProfile();
    this.transactionTypeChange.emit(this.transaction_type);
  }

  ngOnDestroy(): void {
    this._ngUsers.resetNgSelect();
  }

  loadProfile(): void {
    this._profileService.profile.subscribe((profile) => {
      this.profile = profile;
    });
  }

  //? GET DATA
  public getModalData(): void {
    this.modalService.getDataIssued().subscribe((data: string | null) => {
      this.selectedOption = data;
      this.setRequiredValidators();
    });
  }

  private setConfigNgSelect(): void {
    this._ngUsers.setSearchTermKey("name");
    this._ngUsers.extendFilter({ type: this.selectedOption });
    this._ngUsers.setFetchDataFunction(
      this._userService.findUsersForSelect.bind(this._userService)
    );
    this.users$ = this._ngUsers.getData();
    this._ngUsers.triggerFetchData();

    this._ngBanks.setSearchTermKey("name");
    this._ngBanks.setFetchDataFunction(
      this._financialService.findBanksForSelect.bind(this._financialService)
    );
    this.banks$ = this._ngBanks.getData();
    this._ngBanks.triggerFetchData();

    this._ngPlayers.setSearchTermKey("username");
    this._ngPlayers.setFetchDataFunction(
      this._playerService.findPlayersForSelect.bind(this._playerService)
    );
    this.players$ = this._ngPlayers.getData();
    this._ngPlayers.triggerFetchData();

    this._ngCurrencies.setSearchTermKey("username");
    this._ngCurrencies.setFetchDataFunction(
      this._currenciesService.findCurrenciesForSelect.bind(
        this._currenciesService
      )
    );
    this.currencies$ = this._ngCurrencies.getData();
    this._ngCurrencies.triggerFetchData();
  }

  public onSearchSelect(type: NgSelectQuery, term?: string): void {
    if (type === NG_SELECT_QUERIES.CURRENCIES) {
      this._ngCurrencies.searchTerm(term || "");
    } else if (type === NG_SELECT_QUERIES.USERS) {
      this._ngUsers.searchTerm(term || "");
    } else if (type === NG_SELECT_QUERIES.BANKS) {
      this._ngBanks.searchTerm(term || "");
    } else if (type === NG_SELECT_QUERIES.PLAYERS) {
      this._ngPlayers.searchTerm(term || "");
    }
  }

  public onScrollToEndSelect(type: NgSelectQuery): void {
    if (type === NG_SELECT_QUERIES.CURRENCIES) {
      this._ngCurrencies.scrollToEnd();
    } else if (type === NG_SELECT_QUERIES.USERS) {
      this._ngUsers.scrollToEnd();
    } else if (type === NG_SELECT_QUERIES.BANKS) {
      this._ngBanks.scrollToEnd();
    } else if (type === NG_SELECT_QUERIES.PLAYERS) {
      this._ngPlayers.scrollToEnd();
    }
  }

  public getConfigForm(): FormGroup {
    return this.fb.group({
      agentId: [null],
      amount: [null, [Validators.required, Validators.min(0.01)]],
      comment: ["", [Validators.required]],
      bankId: [null],
      currencyId: [null],
      receipt: [null],
      colaboratorId: [null],
      playerId: [null],
    });
  }

  public submitForm(): void {
    if (this.transferForm.valid) {
      const formData = new FormData();
      const filteredData = this.getFilteredFormData();
      for (const key in filteredData) {
        if (filteredData.hasOwnProperty(key) && key !== "receipt") {
          formData.append(key, filteredData[key]);
        }
      }

      const receiptFile =
        this.selectedFiles.length > 0 ? this.selectedFiles[0].file : undefined;
      if (receiptFile) {
        formData.append("receipt", receiptFile);
      }

      this._transferService.saveTransfer(formData).subscribe({
        next: () => {
          this.closeModal();
          this.store.dispatch(UserActions.loadUserSession());
        },
      });
    }
  }

  private getFilteredFormData(): any {
    const formValue = this.transferForm.value;
    const amountNumber = Number(formValue.amount);
    const previousBalance = this.previousBalance;

    const amount =
      this.transaction_type === TYPE_TRANSACTION.WITHDRAWAL
        ? -Math.abs(amountNumber)
        : amountNumber;

    this.currentBalance = previousBalance + amount;

    let filteredData: any = {
      amount: amount.toFixed(2),
      comment: formValue.comment,
      operationType: this.transaction_type,
      type: this.isCredit ? TYPE_TRANSACTION.CREDIT : TYPE_TRANSACTION.CASH,
      cashType: this.isBanking
        ? TYPE_TRANSACTION.BANKING
        : TYPE_TRANSACTION.DIRECT,
      registeredBy: this.profile._id,
      stateTransaction: STATE_TRANSACTION.PROCESSED,
      origin: BACK_OFFICE,
      previousBalance: this.previousBalance.toFixed(2),
      currentBalance: this.currentBalance.toFixed(2),
    };

    if (this.selectedOption === USER_TYPE.AGENT && formValue.agentId) {
      filteredData.agentId = formValue.agentId;
      filteredData.currencyId = this.selectedIdCurrency;
    } else if (this.selectedOption === USER_TYPE.PLAYER && formValue.playerId) {
      filteredData.playerId = formValue.playerId;
      filteredData.currencyId = this.selectedIdCurrencyPlayer;
    } else if (
      this.selectedOption === USER_TYPE.COLABORATOR &&
      formValue.colaboratorId
    ) {
      filteredData.colaboratorId = formValue.colaboratorId;
      filteredData.currencyId = formValue.currencyId;
    }

    if (
      this.isCredit ||
      this.transaction_type !== TYPE_TRANSACTION.WITHDRAWAL
    ) {
      if (formValue.bankId) {
        filteredData.bankId = formValue.bankId;
      }
      if (formValue.receipt) {
        filteredData.receipt = formValue.receipt;
      }
    }

    return filteredData;
  }

  public closeModal(): void {
    this.modalService.closeModal();
  }

  //* IMG
  async onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      if (!file.type.startsWith("image/")) {
        this.translate
          .get("transfer.errors.invalidFileType")
          .subscribe((translatedText: string) => {
            this.toastr.error(translatedText, "Error");
          });
        return;
      }

      try {
        const compressedFile = await this.imageCompressionService.compressImage(
          file,
          800,
          800,
          0.7
        );
        const url = this.fileUrl(file);

        this.selectedFiles = [
          {
            file: compressedFile,
            isPreviewVisible: true,
            urlFile: url,
          },
        ];
        this.previewImage = url;
        event.target.value = "";
      } catch (error) {
        this.translate
          .get("transfer.errors.imageCompressionError")
          .subscribe((translatedText: string) => {
            this.toastr.error(translatedText, "Error");
          });
      }
    }
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  fileUrl(file: File): any {
    const url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async uploadFiles(files: FileList) {
    if (files.length > 0) {
      const file = files[0];

      const url = this.fileUrl(file);

      this.selectedFiles = [
        {
          file: file,
          isPreviewVisible: true,
          urlFile: url,
        },
      ];
      this.previewImage = url;
    }
  }
  onDrop($event: DragEvent) {
    $event.preventDefault();
    const files = $event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.uploadFiles(files);
    }
  }
  onDragOver($event: DragEvent) {
    $event.preventDefault();
  }
  removeImage() {
    this.previewImage = undefined;
  }

  //* SWITCH
  toggleTransactionMode(): void {
    this.isCredit = !this.isCredit;
    this.previousBalance = this.isCredit
      ? this.selectedPlayerCredit || this.selectedUserCredit
      : this.selectedPlayerBalance || this.selectedUserBalance;

    if (this.isCredit) {
      this.toggleBankingMode();
      this.isBanking = false;
    }
  }

  toggleBankingMode(): void {
    this.isBanking = !this.isBanking;

    if (!this.isBanking) {
      this.clearImage();
      this.transferForm.get("receipt")?.clearValidators();
      this.transferForm.get("bankId")?.clearValidators();
      this.transferForm.get("receipt")?.reset();
      this.transferForm.get("bankId")?.reset();
    } else {
      this.transferForm.get("bankId")?.setValidators([Validators.required]);
    }

    this.transferForm.get("receipt")?.updateValueAndValidity();
  }

  //* SELECTOR
  onOptionChange(option: string) {
    this.clearImage();
    this.transaction_type = option;
    this.transferForm.reset();
    this.setRequiredValidators();
    this.transferForm.get("receipt")?.reset();
    this.isCredit = false;
    this.isBanking = false;
    this.onUserCleared();
    this.onPlayerCleared();
    this.transaction_type = option;
    this.transactionTypeChange.emit(this.transaction_type);
  }
  clearImage(): void {
    this.previewImage = undefined;
    this.transferForm.get("receipt")?.clearValidators();
    this.transferForm.get("receipt")?.reset();
    this.transferForm.get("bankId")?.clearValidators();
    this.transferForm.get("bankId")?.reset();
    this.selectedFiles = [];
  }

  public onUserCleared(): void {
    this.selectedUserBalance = 0;
    this.selectedUserCredit = 0;
  }

  public onUserSelected(selectedUser: SelectedUser): void {
    if (
      selectedUser &&
      selectedUser.currencies &&
      selectedUser.currencies.length > 0
    ) {
      if (this.selectedOption === USER_TYPE.COLABORATOR) {
        const firstCurrency = selectedUser.currencies[0];
        this.selectedUserBalance = firstCurrency.balance;
        this.selectedUserCredit = firstCurrency.credit;
        this.selectedCodeCurrency = firstCurrency.currencyId.code;
        this.selectedIdCurrency = firstCurrency.currencyId._id;
        this.selectedUserData = selectedUser;

        this.previousBalance = this.isCredit
          ? this.selectedUserCredit
          : this.selectedUserBalance;
      } else {
        this.selectedUserBalance = selectedUser.currencies[0].balance;
        this.selectedUserCredit = selectedUser.currencies[0].credit;
        this.selectedCodeCurrency = selectedUser.currencyCode.code;
        this.selectedIdCurrency = selectedUser.currencyCode._id;
        this.selectedUserData = selectedUser;

        this.previousBalance = this.isCredit
          ? this.selectedUserCredit
          : this.selectedUserBalance;
      }
    } else {
      this.onUserCleared();
    }
  }

  public onCurrencySelected(selectedCurrency: any): void {
    if (
      this.selectedOption === USER_TYPE.COLABORATOR &&
      this.selectedUserData &&
      this.selectedUserData.currencies
    ) {
      const selectedCurrencyData = this.selectedUserData.currencies.find(
        (currency) => currency.currencyId._id === selectedCurrency.value
      );

      if (selectedCurrencyData) {
        this.selectedUserBalance = selectedCurrencyData.balance;
        this.selectedUserCredit = selectedCurrencyData.credit;
        this.selectedCodeCurrency = selectedCurrencyData.currencyId.code;
        this.selectedIdCurrency = selectedCurrencyData.currencyId._id;

        this.previousBalance = this.isCredit
          ? this.selectedUserCredit
          : this.selectedUserBalance;
      } else {
        this.clearCurrencyData();
      }
    }
  }

  private clearCurrencyData(): void {
    this.selectedUserBalance = 0;
    this.selectedUserCredit = 0;
    this.selectedCodeCurrency = "";
    this.selectedIdCurrency = "";
    this.previousBalance = 0;
  }

  public onPlayerCleared(): void {
    this.selectedPlayerBalance = 0;
    this.selectedPlayerCredit = 0;
  }

  public onPlayerSelected(selectedPlayer: SelectedPlayer): void {
    if (selectedPlayer) {
      this.selectedPlayerBalance = selectedPlayer.balance;
      this.selectedPlayerCredit = selectedPlayer.credit;
      this.selectedCodeCurrencyPlayer = selectedPlayer.currencyCode;
      this.selectedIdCurrencyPlayer = selectedPlayer.currencyId;
      this.previousBalance = this.isCredit
        ? this.selectedPlayerCredit
        : this.selectedPlayerBalance;
    } else {
      this.onPlayerCleared();
    }
  }

  private setRequiredValidators(): void {
    this.transferForm.get("agentId")?.clearValidators();
    this.transferForm.get("playerId")?.clearValidators();
    this.transferForm.get("colaboratorId")?.clearValidators();
    this.transferForm.get("currencyId")?.clearValidators();

    if (this.selectedOption === USER_TYPE.AGENT) {
      this.transferForm.get("agentId")?.setValidators([Validators.required]);
    } else if (this.selectedOption === USER_TYPE.PLAYER) {
      this.transferForm.get("playerId")?.setValidators([Validators.required]);
    } else if (this.selectedOption === USER_TYPE.COLABORATOR) {
      this.transferForm
        .get("colaboratorId")
        ?.setValidators([Validators.required]);
      this.transferForm.get("currencyId")?.setValidators([Validators.required]);
    }

    this.transferForm.get("agentId")?.updateValueAndValidity();
    this.transferForm.get("playerId")?.updateValueAndValidity();
    this.transferForm.get("colaboratorId")?.updateValueAndValidity();
  }

  public getButtonLabel(): string {
    return this.transaction_type === TYPE_TRANSACTION.DEPOSIT
      ? "words.toDeposit"
      : "words.toWithdrawal";
  }
}
