import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  TYPE_TRANSACTION,
  USER_TYPE,
} from "src/app/core/helpers/global/global.constant";
import { BootstrapModalService } from "src/app/core/services/ui/bootstrap-modal.service";

@Component({
  selector: "app-transfer-user-modal",
  templateUrl: "./transfer-user-modal.component.html",
})
export class TransferUserModalComponent implements OnInit {
  public selectedOption: string | null = null;
  public cardTitle: string = "";
  public transactionType: string = "";

  constructor(
    private modalService: BootstrapModalService<string>,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getModalData();
  }

  getModalData() {
    this.modalService.getDataIssued().subscribe((data: string | null) => {
      this.selectedOption = data;
      this.setCardTitle();
    });
  }

  setCardTitle(): void {
    let action = "";
    if (this.transactionType === TYPE_TRANSACTION.DEPOSIT) {
      action = this._translateService.instant("words.deposit");
    } else if (this.transactionType === TYPE_TRANSACTION.WITHDRAWAL) {
      action = this._translateService.instant("words.withdrawal");
    }

    let entity = "";
    if (this.selectedOption === USER_TYPE.AGENT) {
      entity = this._translateService.instant("transfer.agent");
    } else if (this.selectedOption === USER_TYPE.COLABORATOR) {
      entity = this._translateService.instant("transfer.colaborator");
    } else if (this.selectedOption === USER_TYPE.PLAYER) {
      entity = this._translateService.instant("transfer.player");
    } else {
      entity = this._translateService.instant("transfer.titleUnknow");
    }

    this.cardTitle = `${action} ${entity.toLowerCase()}`;
  }

  closeModal(): void {
    this.modalService.closeModal();
  }

  onTransactionTypeChange(newType: string) {
    this.transactionType = newType;
    this.setCardTitle();
  }
}
