import { Pipe, type PipeTransform } from "@angular/core";
import { MESSAGE_STATE } from "src/app/core/helpers/global/message.constants";

@Pipe({
  name: "statusMessage",
})
export class StatusMessagePipe implements PipeTransform {
  transform(value: keyof typeof MESSAGE_STATE): string {
    const messageOption = MESSAGE_STATE[value];
    return messageOption ? messageOption : "words.notDefined";
  }
}
