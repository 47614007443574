<ngx-datatable
  #ngxDatatable
  class="material"
  [rows]="data"
  [loadingIndicator]="ngxConfig.loadingIndicator"
  [columnMode]="ngxConfig.columnMode"
  [selectionType]="ngxConfig.selectionType"
  [scrollbarH]="ngxConfig.scrollbarH"
  [rowHeight]="ngxConfig.rowHeight"
  [footerHeight]="1"
  [messages]="{ emptyMessage: ngxConfig.emptyMessage | translate }"
  [externalSorting]="ngxConfig.externalSorting"
  [sorts]="ngxConfig.sort ? [ngxConfig.sort] : []"
  (select)="emitOnSelectRow($event)"
  (sort)="emitOnSort($event)"
>
  <!-- Columnas de la tabla -->
  @for (col of ngxConfig.columns; track $index) {
    @if (
      !col.isExpandable &&
      !col.expandableCellTemplate &&
      !col.expandableRowTemplate
    ) {
      <ngx-datatable-column
        [name]="col.name || '' | translate"
        [prop]="col.prop || ''"
        [width]="col.width || 200"
        [headerClass]="col.headerClass || ''"
        [sortable]="col.sortable || false"
        [frozenLeft]="col.frozenLeft || false"
        [frozenRight]="col.frozenRight || false"
        [cellTemplate]="col.cellTemplate ?? undefined!"
      ></ngx-datatable-column>
    }

    @if (
      col.isExpandable ||
      col.expandableCellTemplate ||
      col.expandableRowTemplate
    ) {
      <!-- Template por defecto o personalizado para la columna con función expandible -->
      <ngx-datatable-column
        [name]="col.name || '' | translate"
        [width]="col.width || 75"
        [headerClass]="col.headerClass || ''"
        [sortable]="col.sortable || false"
        [frozenLeft]="col.frozenLeft || false"
        [frozenRight]="col.frozenRight || false"
      >
        <ng-template
          let-row="row"
          let-rowIndex="rowIndex"
          let-expanded="expanded"
          let-table="table"
          ngx-datatable-cell-template
        >
          <ng-container
            *ngTemplateOutlet="
              col.expandableCellTemplate ?? defaultExpandableCellTemplate;
              context: { row, rowIndex, expanded, table: ngxDatatable }
            "
          ></ng-container>
        </ng-template>
      </ngx-datatable-column>

      <!-- Template por defecto o personalizado para la fila expandida -->
      <ngx-datatable-row-detail>
        <ng-template
          let-row="row"
          let-rowIndex="rowIndex"
          let-expanded="expanded"
          ngx-datatable-row-detail-template
        >
          <ng-container
            *ngTemplateOutlet="
              col.expandableRowTemplate ?? defaultExpandableRowTemplate;
              context: { row, rowIndex, expanded }
            "
          ></ng-container>
        </ng-template>
      </ngx-datatable-row-detail>
    }
    <!--  -->
  }

  <!-- Footer de la tabla -->
  @if (ngxConfig.showFooter) {
    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template>
        <div class="footer-left">
          <div class="page-size-container">
            <select class="form-select" (change)="emitOnChangeLimit($event)">
              @for (option of ngxConfig.limitOptions; track $index) {
                <option
                  [value]="option.value"
                  [selected]="option.value == ngxConfig.limit"
                >
                  {{ option.label | translate }}
                </option>
              }
            </select>
          </div>
          <div class="total-rows-container">
            <span>
              {{ 'words.results' | translate }}: {{ ngxConfig.count }}
            </span>
          </div>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="ngxConfig.page"
          [size]="ngxConfig.limit"
          [count]="ngxConfig.count"
          [hidden]="!(ngxConfig.count / ngxConfig.limit > 1)"
          (change)="emitOnChangePage($event)"
        />
      </ng-template>
    </ngx-datatable-footer>
  }
</ngx-datatable>

<!-- Template por defecto para la columna con función expandible -->
<ng-template
  #defaultExpandableCellTemplate
  let-row="row"
  let-rowIndex="rowIndex"
  let-expanded="expanded"
  let-table="table"
>
  <div class="d-flex justify-content-end align-items-center gap-2">
    <a
      href="javascript:void(0)"
      title="Expand/Collapse Row"
      (click)="toggleExpandRow(table, row)"
    >
      <i
        class="fa"
        [class.fa-chevron-right]="!expanded"
        [class.fa-chevron-down]="expanded"
      ></i>
    </a>
  </div>
</ng-template>

<!-- Template por defecto para la fila expandida -->
<ng-template
  #defaultExpandableRowTemplate
  let-row="row"
  let-rowIndex="rowIndex"
  let-expanded="expanded"
>
  <span> Default expandable row template </span>
</ng-template>
