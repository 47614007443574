import { Component, Input, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { USER_TYPE } from "src/app/core/helpers/global/global.constant";
import { User } from "src/app/core/interfaces/api/user.interface";
import { BootstrapModalService } from "src/app/core/services/ui/bootstrap-modal.service";
import { GlobalService } from "src/app/core/services/ui/global.service";

@Component({
  selector: "app-balance-cards-form",
  templateUrl: "./balance-cards-form.component.html",
})
export class BalanceCardsFormComponent {
  @Input() balance: number = 0.0;
  @Input() credit: number = 0.0;
  @Input() currencyCode: string = "";

  public balanceProfile: number = 0.0;
  public creditProfile: number = 0.0;
  public totalBalanceProfile: number = 0.0;
  public currencyCodeProfile: string = "";
  public totalBalanceAgent: number = 0.0;
  public selectedOption: string | null = null;
  public cardTitle: string = "";
  USER_TYPE = USER_TYPE;

  constructor(
    private _profileService: GlobalService,
    private modalService: BootstrapModalService<string>,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadProfile();
    this.getModalData();
  }

  ngOnChanges(changes: any): void {
    if (changes.balance || changes.credit || changes.currencyCode) {
      this.loadProfile();
    }
  }

  private loadProfile() {
    this._profileService.profile.subscribe((profile) => {
      if (profile && profile.currencies.length > 0) {
        const currency = profile.currencies.find(
          (c) => c.currencyId.code === this.currencyCode
        );
        if (currency) {
          this.balanceProfile = currency.balance || 0.0;
          this.creditProfile = currency.credit || 0.0;
          this.currencyCodeProfile = currency.currencyId.code;
          this.calculateTotals();
        } else {
          this.resetProfileData();
        }
      }
    });
  }

  private calculateTotals() {
    this.totalBalanceProfile = this.balanceProfile + this.creditProfile;
    this.totalBalanceAgent = this.balance + this.credit;
  }

  private resetProfileData() {
    this.balanceProfile = 0.0;
    this.creditProfile = 0.0;
    this.currencyCodeProfile = "";
    this.totalBalanceProfile = 0.0;
    this.totalBalanceAgent = 0.0;
  }

  getModalData() {
    this.modalService.getDataIssued().subscribe((data: string | null) => {
      this.selectedOption = data;
      this.setCardTitle();
    });
  }
  setCardTitle(): void {
    switch (this.selectedOption) {
      case USER_TYPE.AGENT:
        this.cardTitle = this._translateService
          .instant("transfer.agent")
          .toLowerCase();
        break;
      case USER_TYPE.COLABORATOR:
        this.cardTitle = this._translateService
          .instant("transfer.colaborator")
          .toLowerCase();
        break;
      case USER_TYPE.PLAYER:
        this.cardTitle = this._translateService
          .instant("transfer.player")
          .toLowerCase();
        break;
      default:
        this.cardTitle = this._translateService
          .instant("transfer.titleUnknow")
          .toLowerCase();
    }
  }

  getIconClass(): string {
    switch (this.selectedOption) {
      case USER_TYPE.PLAYER:
        return "fa fa-gamepad";
      case USER_TYPE.AGENT:
        return "fa fa-user-secret";
      case USER_TYPE.COLABORATOR:
        return "fa fa-users";
      default:
        return "fa fa-question-circle";
    }
  }
}
