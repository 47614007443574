import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/api/auth.service";

@Injectable({
  providedIn: "root",
})
export class LoggedInAuthGuard {
  constructor(private _authService: AuthService, private _router: Router) {}
  canActivate(): boolean {
    if (this._authService.isLoggedIn()) {
      this._router.navigateByUrl("/");
      return false;
    } else {
      return true;
    }
  }
}
