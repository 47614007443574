// auth-''.interceptor.ts
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  TOKEN_HEADER_KEY,
  USER_SESSION,
} from "src/app/core/helpers/global/global.constant";
import { Router } from "@angular/router";
import { StorageService } from "../services/ui/storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private _storageService: StorageService,
    private router: Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get("skip")) {
      return next.handle(req);
    } else {
      const session = JSON.parse(
        this._storageService.secureStorage.getItem(USER_SESSION)
      );
      let authReq = req;
      const token = session?.token;
      if (token != null) {
        // Si el token existe, agrega el encabezado de autenticación a la solicitud
        const authReq = req.clone({
          headers: req.headers.set(TOKEN_HEADER_KEY, token),
        });
        return next.handle(authReq);
      } else {
        // Si el token no existe, redirige al usuario al componente de login
        this.router.navigate(["/auth/login"]);
        // Retorna un Observable vacío para evitar que la solicitud continúe
        return next.handle(authReq);
      }
    }
  }
}

export const AUTH_TOKEN_INTERCEPTOR_PROVIDERS = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
];
