import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns/esm';
import { map, Observable, of, Subject, takeUntil } from 'rxjs';
import { GENDER_OPTIONS } from 'src/app/core/helpers/global/global.constant';
import { getDifferingPropertiesArray } from 'src/app/core/helpers/global/global.util';
import {
  TYPES_USER_PERMISSIONS,
  USER_AGENT,
  USER_VALIDATIONS,
} from 'src/app/core/helpers/global/user-management.constant';
import {
  BUTTON_ACTIONS,
  FORMAT_FOR_DATES,
  NG_SELECT_QUERIES,
} from 'src/app/core/helpers/ui/ui.constant';
import { User } from 'src/app/core/interfaces/api/user.interface';
import { ModalWithAction } from 'src/app/core/interfaces/ui/bootstrap-modal.interface';
import {
  ButtonAction,
  NgSelect,
  NgSelectQuery,
} from 'src/app/core/interfaces/ui/ui.interface';
import { CityService } from 'src/app/core/services/api/city.service';
import { CommissionPlanService } from 'src/app/core/services/api/commission-plan.service';
import { CountryService } from 'src/app/core/services/api/country.service';
import { CurrencyService } from 'src/app/core/services/api/currency.service';
import { LanguageService } from 'src/app/core/services/api/language.service';
import { RoleService } from 'src/app/core/services/api/role.service';
import { UserService } from 'src/app/core/services/api/user.service';
import { BootstrapModalService } from 'src/app/core/services/ui/bootstrap-modal.service';
import { FilterService } from 'src/app/core/services/ui/filter.service';
import { GlobalService } from 'src/app/core/services/ui/global.service';
import { SearchNgSelectService } from 'src/app/core/services/ui/search-ng-select.service';
import { ToastrNotificationService } from 'src/app/core/services/ui/toastr-notification.service';
import { strongPasswordValidator } from 'src/app/shared/validators/passwordMatch';
import { adultValidator } from 'src/app/shared/validators/validatorsAdult';
import { phoneNumberValidator } from 'src/app/shared/validators/validatorsPhoneNumber';

@Component({
  selector: 'app-collaborator-agent-modal-form',
  templateUrl: './collaborator-agent-modal-form.component.html',
  providers: [
    { provide: 'ngCurrencies', useClass: SearchNgSelectService },
    { provide: 'ngCountries', useClass: SearchNgSelectService },
    { provide: 'ngCities', useClass: SearchNgSelectService },
    { provide: 'ngLanguages', useClass: SearchNgSelectService },
    { provide: 'ngUsers', useClass: SearchNgSelectService },
    { provide: 'ngRoles', useClass: SearchNgSelectService },
  ],
})
export class CollaboratorAgentModalFormComponent implements OnInit, OnDestroy {
  public BUTTON_ACTIONS = BUTTON_ACTIONS;
  public NG_SELECT_QUERIES = NG_SELECT_QUERIES;
  public USER_VALIDATIONS = USER_VALIDATIONS;
  public FORMAT_FOR_DATES = FORMAT_FOR_DATES;
  public USER_TYPE = TYPES_USER_PERMISSIONS;
  public profile: User = {} as User;
  public agentId: string = '';
  public currencyId: string = '';
  public username: string = '';

  public countries$: Observable<NgSelect<string>[]> = of([]);
  public cities$: Observable<NgSelect<string>[]> = of([]);
  public currencies$: Observable<NgSelect<string>[]> = of([]);
  public languages$: Observable<NgSelect<string>[]> = of([]);
  public genders$: Observable<NgSelect<string>[]> = of(GENDER_OPTIONS);
  public users$: Observable<NgSelect<string>[]> = of([]);
  public roles$: Observable<NgSelect<string>[]> = of([]);

  public titleModal: string = '';
  public createdAt: Date | undefined = undefined;
  public updatedAt: Date | undefined = undefined;
  public agentModalForm: FormGroup | undefined = undefined;
  public activeButtonAction: ButtonAction | undefined = undefined;

  private selectedUser: User | undefined = undefined;
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  showPassword: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _currencyService: CurrencyService,
    private _countryService: CountryService,
    private _cityService: CityService,
    private _languageService: LanguageService,
    private _roleService: RoleService,
    private _commissionService: CommissionPlanService,
    private _bsModalService: BootstrapModalService<ModalWithAction<User>>,
    private _userService: UserService,
    private _filterService: FilterService<object>,
    private _translateService: TranslateService,
    private _notificationService: ToastrNotificationService,
    private _profileService: GlobalService,

    @Inject('ngCurrencies')
    private _ngCurrencies: SearchNgSelectService<string>,
    @Inject('ngCountries')
    private _ngCountries: SearchNgSelectService<string>,
    @Inject('ngCities')
    private _ngCities: SearchNgSelectService<string>,
    @Inject('ngLanguages')
    private _ngLanguages: SearchNgSelectService<string>,
    @Inject('ngUsers')
    private _ngUsers: SearchNgSelectService<string>,
    @Inject('ngRoles')
    private _ngRoles: SearchNgSelectService<string>
  ) {}

  ngOnInit(): void {
    this._profileService.profile
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile) => {
        this.agentId = profile._id;
        this.username = profile.username;
        this.currencyId = profile.currencyId?._id ?? '';
        this.profile = profile;
      });
    this.setConfigNgSelects();
    this.suscribeModalDataIssued();
  }

  private setConfigNgSelects(): void {
    this._ngCurrencies.setSearchTermKey('name');
    this._ngCurrencies.setFetchDataFunction(
      this._currencyService.findCurrenciesForSelect.bind(this._currencyService)
    );
    this.currencies$ = this._ngCurrencies.getData();

    this._ngCountries.setSearchTermKey('name');
    this._ngCountries.setFetchDataFunction(
      this._countryService.findCountriesForSelect.bind(this._countryService)
    );
    this.countries$ = this._ngCountries.getData();

    this._ngCities.setSearchTermKey('name');
    this._ngCities.setFetchDataFunction(
      this._cityService.findCitiesForSelect.bind(this._cityService)
    );
    this.cities$ = this._ngCities.getData();

    this._ngLanguages.setSearchTermKey('name');
    this._ngLanguages.setFetchDataFunction(
      this._languageService.findLanguagesForSelect.bind(this._languageService)
    );
    this.languages$ = this._ngLanguages.getData();

    this._ngUsers.setSearchTermKey('username');
    this._ngUsers.setFetchDataFunction(
      this._userService.findUsersForSelect.bind(this._userService)
    );
    this.users$ = this._ngUsers.getData();

    this._ngRoles.setSearchTermKey('name');
    this._ngRoles.setFetchDataFunction(
      this._roleService.findRoleForSelect.bind(this._roleService)
    );
    this.roles$ = this._ngRoles
      .getData()
      .pipe(
        map((roles) =>
          roles.filter(
            ({ userType }) =>
              userType !== TYPES_USER_PERMISSIONS.ADMIN &&
              userType !== TYPES_USER_PERMISSIONS.COLLABORATOR_OPERATOR &&
              userType !== TYPES_USER_PERMISSIONS.AGENT &&
              userType !== TYPES_USER_PERMISSIONS.CASHDESK
          )
        )
      );
  }

  private suscribeModalDataIssued(): void {
    this._bsModalService
      .getDataIssued()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ buttonAction, selectedRow }) =>
          this.processSelectedUser(buttonAction, selectedRow),
        error: () => this.closeModal(),
      });
  }

  private processSelectedUser(buttonAction: ButtonAction, user?: User): void {
    this.activeButtonAction = buttonAction;
    this.selectedUser = user;
    this.agentModalForm = this.getConfigModalForm(this.activeButtonAction);
    this.populateModalForm(this.selectedUser);

    const executeAction = this.handleProcessButtonAction(buttonAction);
    executeAction();
  }

  private getConfigModalForm(buttonAction: ButtonAction): FormGroup {
    const isAgentOrAffiliate =
      this.profile.userType === TYPES_USER_PERMISSIONS.AGENT ||
      this.profile.userType === TYPES_USER_PERMISSIONS.CASHDESK;

    const defaultConfig = {
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      documentNumber: [null, [Validators.required]],
      countryId: [
        isAgentOrAffiliate ? this.profile.countryId?._id : null,
        [Validators.required],
      ],
      cityId: [
        isAgentOrAffiliate ? this.profile.cityId?._id : null,
        [Validators.required],
      ],
      birthDate: [null, [Validators.required, adultValidator()]],
      address: [null],
      userType: [TYPES_USER_PERMISSIONS.COLLABORATOR_AGENT],
      gender: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      mobileNumber: [null, [Validators.required, phoneNumberValidator()]],
      conventionalNumber: [null],
      username: [
        '',
        [Validators.required, Validators.pattern(/^[A-Za-z\d]{4,}$/)],
      ],
      languageId: [
        isAgentOrAffiliate ? this.profile.languageId?._id : null,
        [Validators.required],
      ],
      currencyId: [isAgentOrAffiliate ? this.profile.currencyId?._id : null],
      roleId: [null, [Validators.required]],
      parentUsername: [isAgentOrAffiliate ? this.profile.username : null],
      parentId: [
        isAgentOrAffiliate ? this.profile._id : null,
        Validators.required,
      ],
    };

    const addUserConfig = {
      ...defaultConfig,
      password: ['', [Validators.required, strongPasswordValidator()]],
    };

    const editUserConfig = {
      ...defaultConfig,
      state: [false],
    };

    const viewUserConfig = {
      ...defaultConfig,
      state: [false],
      TFA: [false],
    };

    const actionsMap = new Map<ButtonAction, object>([
      [BUTTON_ACTIONS.ADD, addUserConfig],
      [BUTTON_ACTIONS.EDIT, editUserConfig],
      [BUTTON_ACTIONS.VIEW, viewUserConfig],
    ]);

    const config = actionsMap.get(buttonAction) || {};
    const formGroup = this._formBuilder.group(config);
    if (isAgentOrAffiliate && this.profile.countryId?._id) {
      this.loadCities(this.profile.countryId._id, formGroup);
    }

    return formGroup;
  }

  private loadCities(countryId: string, formGroup: FormGroup): void {
    this._ngCities.resetNgSelect();
    this._ngCities.extendFilter({ countryId });
    this._ngCities.triggerFetchData();

    this.cities$.pipe(takeUntil(this.unsubscribe$)).subscribe((cities) => {
      if (cities.length > 0) {
        formGroup.patchValue({ cityId: this.profile.cityId?._id || null });
      }
    });
  }

  private populateModalForm(user?: User): void {
    if (!user || !this.agentModalForm) return;

    const {
      firstName,
      lastName,
      documentNumber,
      birthDate,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      currencyId,
      languageId,
      state,
      TFA,
      createdAt,
      updatedAt,
      roleId,
      userType,
      parentUsername,
      parentId,
    } = user;

    const selectedUser = {
      firstName,
      lastName,
      birthDate: birthDate ? format(new Date(birthDate), 'yyyy-MM-dd') : null,
      documentNumber,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      countryId: countryId._id,
      currencyId,
      cityId: cityId._id,
      address,
      gender,
      languageId: languageId._id,
      state: state === 1 ? true : false,
      TFA,
      roleId: roleId._id,
      userType,
      parentUsername,
      parentId: parentId._id,
    };
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;

    this.agentModalForm.patchValue(selectedUser);
  }

  private handleProcessButtonAction(buttonAction: ButtonAction): () => void {
    const actionMap = new Map<ButtonAction, () => void>([
      [BUTTON_ACTIONS.ADD, () => this.handleAddUser()],
      [BUTTON_ACTIONS.EDIT, () => this.handleEditUser()],
      [BUTTON_ACTIONS.VIEW, () => this.handleViewUser()],
    ]);
    return actionMap.get(buttonAction) || (() => {});
  }

  private handleAddUser(): void {
    this.titleModal = 'userManagement.users.add';
    this._ngUsers.extendFilter({ type: USER_AGENT });
    this._ngCurrencies.triggerFetchData();
    this._ngCountries.triggerFetchData();
    this._ngLanguages.triggerFetchData();
    this._ngUsers.triggerFetchData();
    this._ngRoles.triggerFetchData();
  }

  private handleEditUser(): void {
    this.titleModal = 'userManagement.users.edit';

    if (!this.agentModalForm || !this.selectedUser) return;

    this.handleAddDataToStream(this.selectedUser);

    const { countryId } = this.selectedUser;
    this._ngCities.extendFilter({ countryId: countryId._id });
    this._ngUsers.extendFilter({ type: USER_AGENT });

    this._ngCurrencies.triggerFetchData();
    this._ngCountries.triggerFetchData();
    this._ngCities.triggerFetchData();
    this._ngLanguages.triggerFetchData();
    this._ngUsers.triggerFetchData();
    this._ngRoles.triggerFetchData();
  }

  private handleViewUser(): void {
    this.titleModal = 'userManagement.users.view';

    if (!this.agentModalForm || !this.selectedUser) return;

    this.handleAddDataToStream(this.selectedUser);
    this.agentModalForm.disable();
  }

  private handleAddDataToStream(selectedUser: User): void {
    const { countryId, cityId, languageId, parentId, roleId } = selectedUser;
    const country = {
      label: countryId.name,
      value: countryId._id,
      icon: countryId.code,
    };
    const city = { label: cityId.name, value: cityId._id };
    const language = { label: languageId.name, value: languageId._id };

    const role = { label: roleId.name, value: roleId._id };
    const parent = { label: parentId.username, value: parentId._id };

    this._ngCountries.addDataToStream([country]);
    this._ngCities.addDataToStream([city]);
    this._ngLanguages.addDataToStream([language]);
    this._ngRoles.addDataToStream([role]);
    this._ngUsers.addDataToStream([parent]);
  }

  public onSearchSelect(typeQuery: NgSelectQuery, term?: string): void {
    const termText = term || '';

    const actionMap = new Map<NgSelectQuery, () => void>([
      [
        NG_SELECT_QUERIES.COUNTRIES,
        () => this._ngCountries.searchTerm(termText),
      ],
      [
        NG_SELECT_QUERIES.CITIES,
        () => this.handleSearchTermForCities(termText),
      ],
      [
        NG_SELECT_QUERIES.CURRENCIES,
        () => this._ngCurrencies.searchTerm(termText),
      ],
      [
        NG_SELECT_QUERIES.LANGUAGE,
        () => this._ngLanguages.searchTerm(termText),
      ],
      [NG_SELECT_QUERIES.USERS, () => this._ngUsers.searchTerm(termText)],
      [NG_SELECT_QUERIES.USER_ROLES, () => this._ngRoles.searchTerm(termText)],
    ]);

    const action = actionMap.get(typeQuery);
    if (action) action();
  }

  private handleSearchTermForCities(term: string): void {
    if (!this.agentModalForm) return;
    const { countryId } = this.agentModalForm.value;
    this._ngCities.extendFilter({ countryId });
    this._ngCities.searchTerm(term);
  }

  public onScrollToEndSelect(typeQuery: NgSelectQuery): void {
    const actionMap = new Map<NgSelectQuery, () => void>([
      [NG_SELECT_QUERIES.COUNTRIES, () => this._ngCountries.scrollToEnd()],
      [NG_SELECT_QUERIES.CITIES, () => this._ngCities.scrollToEnd()],
      [NG_SELECT_QUERIES.CURRENCIES, () => this._ngCurrencies.scrollToEnd()],
      [NG_SELECT_QUERIES.LANGUAGE, () => this._ngLanguages.scrollToEnd()],
      [NG_SELECT_QUERIES.USERS, () => this._ngUsers.scrollToEnd()],
      [NG_SELECT_QUERIES.USER_ROLES, () => this._ngRoles.scrollToEnd()],
    ]);

    const action = actionMap.get(typeQuery);
    if (action) action();
  }

  public onCountryChange(event: NgSelect<string>): void {
    if (!this.agentModalForm || !event) return;

    this.agentModalForm.patchValue({
      cityId: '',
      currencyId: event.currencyId,
    });

    this._ngCities.resetNgSelect();
    this._ngCities.extendFilter({ countryId: event.value });
    this._ngCities.triggerFetchData();
  }

  public closeModal(): void {
    this._bsModalService.closeModal();
  }

  public onSubmit(): void {
    if (
      !this.activeButtonAction ||
      !this.agentModalForm ||
      this.agentModalForm.invalid
    ) {
      return;
    }
    const executeAction = this.handleSubmitAction(this.activeButtonAction);
    executeAction();
  }

  private handleSubmitAction(buttonAction: ButtonAction): () => void {
    const actionMap = new Map<ButtonAction, () => void>([
      [BUTTON_ACTIONS.ADD, () => this.addUser()],
      [BUTTON_ACTIONS.EDIT, () => this.editUser()],
    ]);
    return actionMap.get(buttonAction) || (() => {});
  }

  private addUser(): void {
    const {
      firstName,
      lastName,
      birthDate,
      documentNumber,
      username,
      email,
      password,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      languageId,
      currencyId,
      roleId,
      userType,
      parentUsername,
      parentId,
    } = this.agentModalForm!.value;

    const userFormData = {
      firstName,
      lastName,
      birthDate,
      documentNumber,
      username,
      email,
      password,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      languageId,
      currencyId,
      roleId,
      userType,
      parentUsername,
      parentId,
    };

    this._userService
      .createUser(userFormData)
      .subscribe({ next: () => this.afterSubmitForm() });
  }

  private editUser(): void {
    if (!this.agentModalForm || !this.selectedUser) return;

    const selectedUser = this.getSelectedUser();
    const userFormData = this.getUserFormData();

    const editedUser = getDifferingPropertiesArray(selectedUser, userFormData);
    if (!Object.keys(editedUser).length) {
      const message = this._translateService.instant('words.noChangesForm');
      this._notificationService.showNotification({
        type: 'warning',
        title: 'userManagement.users.title',
        message,
      });
      return;
    }

    const { _id } = this.selectedUser;

    this._userService
      .updateUser(_id, editedUser)
      .subscribe({ next: () => this.afterSubmitForm() });
  }

  private getSelectedUser(): object {
    const {
      firstName,
      lastName,
      birthDate,
      documentNumber,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      currencyId,
      languageId,
      state,
      roleId,
      userType,
      parentUsername,
      parentId,
    } = this.selectedUser!;

    return {
      firstName,
      lastName,
      birthDate: birthDate ? format(new Date(birthDate), 'yyyy-MM-dd') : null,
      documentNumber,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      currencyId,
      countryId: countryId._id,
      cityId: cityId._id,
      address,
      gender,
      languageId: languageId._id,
      state: state === 1 ? true : false,
      roleId: roleId._id,
      userType,
      parentUsername,
      parentId: parentId._id,
    };
  }

  private getUserFormData(): object {
    const {
      firstName,
      lastName,
      birthDate,
      documentNumber,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      languageId,
      currencyId,
      state,
      roleId,
      userType,
      parentUsername,
      parentId,
    } = this.agentModalForm!.value;
    return {
      firstName,
      lastName,
      birthDate,
      documentNumber,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      languageId,
      currencyId,
      state,
      roleId,
      userType,
      parentUsername,
      parentId,
    };
  }

  public onParentUserChange(selectedUser: NgSelect<string> | null): void {
    if (!selectedUser) {
      this.agentModalForm?.patchValue({ parentUsername: null });
      return;
    }

    this.agentModalForm?.patchValue({ parentUsername: selectedUser.label });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  public validateKeypress(event: KeyboardEvent): void {
    const allowedKeys = ['+', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  public validatePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData?.getData('text');
    const phoneNumberPattern = /^[+0-9]*$/;

    if (clipboardData && !phoneNumberPattern.test(clipboardData)) {
      event.preventDefault();
    }
  }

  public onRoleChange(selectedRole: any): void {
    if (!selectedRole) {
      this.agentModalForm?.patchValue({ userType: null });
      return;
    }

    const { userType } = selectedRole;
    this.agentModalForm?.patchValue({ userType });
  }

  private afterSubmitForm(): void {
    this._filterService.updateFilterData({});
    this.closeModal();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
