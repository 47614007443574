import { ActionReducerMap } from "@ngrx/store";
import { UserState } from "../interfaces/api/user.interface";
import { LanguageState } from "../interfaces/ui/language.interface";
import { AuthEffects } from "./auth/auth.effects";
import { authReducer } from "./auth/auth.reducers";
import { LanguageEffects } from "./language/language.effects";
import { languageReducer } from "./language/language.reducers";

export interface AppState {
  language: Readonly<LanguageState>;
  auth: Readonly<UserState>;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  language: languageReducer,
  auth: authReducer,
};

export const ROOT_EFFECTS = [LanguageEffects, AuthEffects];
