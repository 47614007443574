import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Player } from "src/app/core/interfaces/api/player.interface";
import { ModalWithAction } from "src/app/core/interfaces/ui/bootstrap-modal.interface";
import { ButtonAction } from "src/app/core/interfaces/ui/ui.interface";
import { PlayerService } from "src/app/core/services/api/player.service";
import { BootstrapModalService } from "src/app/core/services/ui/bootstrap-modal.service";
import { FilterService } from "src/app/core/services/ui/filter.service";
import { CHANGE_PASSWORD_CONSTANTS } from "../../../core/helpers/global/global.constant";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @Input()
  public isModal: boolean = true;
  @Input()
  public playerIdInput: Player | null = null;
  @Output()
  public formSubmitted = new EventEmitter<any>();

  public playerId: string | undefined;

  public changePasswordForm: FormGroup | undefined = undefined;
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  public activeButtonAction: ButtonAction | undefined;
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  CHANGE_PASSWORD_CONSTANTS = CHANGE_PASSWORD_CONSTANTS;

  constructor(
    private _formBuilder: FormBuilder,
    private _playerService: PlayerService,
    private _bsModalService: BootstrapModalService<ModalWithAction<any>>,
    private _filterService: FilterService<object>
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    if (!this.isModal) {
      this.changePasswordForm?.disable();
    } else {
      this.subscribeToModalData();
    }
  }

  private initializeForm(): void {
    this.changePasswordForm = this._formBuilder.group(
      {
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", [Validators.required]],
      },
      { validators: this.passwordMatchValidator }
    );
  }

  private passwordMatchValidator(
    formGroup: FormGroup
  ): null | { mismatch: boolean } {
    return formGroup.get(CHANGE_PASSWORD_CONSTANTS.PASSWORD_FIELD)?.value ===
      formGroup.get(CHANGE_PASSWORD_CONSTANTS.CONFIRM_PASSWORD_FIELD)?.value
      ? null
      : { mismatch: true };
  }

  private subscribeToModalData(): void {
    this._bsModalService
      .getDataIssued()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ buttonAction, selectedRow }) => {
          this.activeButtonAction = buttonAction;
          this.playerId = selectedRow?._id;
        },
        error: () => this.closeModal(),
      });
  }

  public onSubmit(): void {
    if (this.changePasswordForm?.invalid || !this.playerId) {
      return;
    }

    const formValues = this.changePasswordForm?.value;

    this._playerService
      .updatePassword({
        playerId: this.playerId,
        password: formValues.password,
      })
      .subscribe(() => this.afterSubmitForm());
    this.formSubmitted.emit();
  }

  public unlockForm(): void {
    this.playerId = !this.isModal
      ? this.playerIdInput?._id.toString()
      : this.playerId;
    this.changePasswordForm?.enable();
  }

  public lockForm(): void {
    this.changePasswordForm?.disable();
    this.changePasswordForm?.reset();
  }

  public closeModal(): void {
    this._bsModalService.closeModal();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
  private afterSubmitForm(): void {
    this._filterService.updateFilterData({});
    this.closeModal();
  }

  public togglePasswordVisibility(field: string): void {
    if (field === CHANGE_PASSWORD_CONSTANTS.PASSWORD_FIELD) {
      this.showPassword = !this.showPassword;
    } else if (field === CHANGE_PASSWORD_CONSTANTS.CONFIRM_PASSWORD_FIELD) {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
}