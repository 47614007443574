import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, Subject, takeUntil } from 'rxjs';
import { duplicateCurrency } from 'src/app/core/helpers/global/form-validation-utils';
import { GENDER_OPTIONS } from 'src/app/core/helpers/global/global.constant';
import { getDifferingProperties } from 'src/app/core/helpers/global/global.util';
import {
  MAX_ALLOWED_USER_CURRENCIES,
  TYPES_USER_PERMISSIONS,
  USER_VALIDATIONS,
} from 'src/app/core/helpers/global/user-management.constant';
import {
  BUTTON_ACTIONS,
  FORMAT_FOR_DATES,
  NG_SELECT_QUERIES,
} from 'src/app/core/helpers/ui/ui.constant';
import { User, UserCurrency } from 'src/app/core/interfaces/api/user.interface';
import { ModalWithAction } from 'src/app/core/interfaces/ui/bootstrap-modal.interface';
import {
  ButtonAction,
  NgSelect,
  NgSelectQuery,
} from 'src/app/core/interfaces/ui/ui.interface';
import { CityService } from 'src/app/core/services/api/city.service';
import { CountryService } from 'src/app/core/services/api/country.service';
import { CurrencyService } from 'src/app/core/services/api/currency.service';
import { LanguageService } from 'src/app/core/services/api/language.service';
import { RoleService } from 'src/app/core/services/api/role.service';
import { UserService } from 'src/app/core/services/api/user.service';
import { BootstrapModalService } from 'src/app/core/services/ui/bootstrap-modal.service';
import { FilterService } from 'src/app/core/services/ui/filter.service';
import { SearchNgSelectService } from 'src/app/core/services/ui/search-ng-select.service';
import { ToastrNotificationService } from 'src/app/core/services/ui/toastr-notification.service';
import { strongPasswordValidator } from 'src/app/shared/validators/passwordMatch';
import { phoneNumberValidator } from 'src/app/shared/validators/validatorsPhoneNumber';

@Component({
  selector: 'user-management-user-modal-form',
  templateUrl: './user-modal-form.component.html',
  providers: [
    { provide: 'ngCurrencies', useClass: SearchNgSelectService },
    { provide: 'ngCountries', useClass: SearchNgSelectService },
    { provide: 'ngCities', useClass: SearchNgSelectService },
    { provide: 'ngLanguages', useClass: SearchNgSelectService },
    { provide: 'ngRoles', useClass: SearchNgSelectService },
  ],
})
export class UserModalFormComponent implements OnInit, OnDestroy {
  public BUTTON_ACTIONS = BUTTON_ACTIONS;
  public NG_SELECT_QUERIES = NG_SELECT_QUERIES;
  public USER_VALIDATIONS = USER_VALIDATIONS;
  public FORMAT_FOR_DATES = FORMAT_FOR_DATES;

  public genders$: Observable<NgSelect<string>[]> = of(GENDER_OPTIONS);
  public countries$: Observable<NgSelect<string>[]> = of([]);
  public cities$: Observable<NgSelect<string>[]> = of([]);
  public currencies$: Observable<NgSelect<string>[]> = of([]);
  public languages$: Observable<NgSelect<string>[]> = of([]);
  public roles$: Observable<NgSelect<string>[]> = of([]);

  public titleModal: string = '';
  public createdAt: Date | undefined = undefined;
  public updatedAt: Date | undefined = undefined;
  public userFormGroup: FormGroup | undefined = undefined;
  public currenciesFormArray: FormArray | undefined = undefined;
  public activeButtonAction: ButtonAction | undefined = undefined;

  private selectedUser: User | undefined = undefined;
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _formBuilder: FormBuilder,
    private _currencyService: CurrencyService,
    private _countryService: CountryService,
    private _cityService: CityService,
    private _languageService: LanguageService,
    private _roleService: RoleService,
    private _bsModalService: BootstrapModalService<ModalWithAction<User>>,
    private _userService: UserService,
    private _filterService: FilterService<object>,
    private _translateService: TranslateService,
    private _notificationService: ToastrNotificationService,

    @Inject('ngCurrencies')
    private _ngCurrencies: SearchNgSelectService<string>,
    @Inject('ngCountries')
    private _ngCountries: SearchNgSelectService<string>,
    @Inject('ngCities')
    private _ngCities: SearchNgSelectService<string>,
    @Inject('ngLanguages')
    private _ngLanguages: SearchNgSelectService<string>,
    @Inject('ngRoles')
    private _ngRoles: SearchNgSelectService<string>
  ) {}

  ngOnInit(): void {
    this.setConfigNgSelects();
    this.suscribeModalDataIssued();
  }

  private setConfigNgSelects(): void {
    this._ngCurrencies.setSearchTermKey('name');
    this._ngCurrencies.setFetchDataFunction(
      this._currencyService.findCurrenciesForSelect.bind(this._currencyService)
    );
    this.currencies$ = this._ngCurrencies.getData();

    this._ngCountries.setSearchTermKey('name');
    this._ngCountries.setFetchDataFunction(
      this._countryService.findCountriesForSelect.bind(this._countryService)
    );
    this.countries$ = this._ngCountries.getData();

    this._ngCities.setSearchTermKey('name');
    this._ngCities.setFetchDataFunction(
      this._cityService.findCitiesForSelect.bind(this._cityService)
    );
    this.cities$ = this._ngCities.getData();

    this._ngLanguages.setSearchTermKey('name');
    this._ngLanguages.setFetchDataFunction(
      this._languageService.findLanguagesForSelect.bind(this._languageService)
    );
    this.languages$ = this._ngLanguages.getData();

    this._ngRoles.setSearchTermKey('name');
    this._ngRoles.setFetchDataFunction(
      this._roleService.findRoleForSelect.bind(this._roleService)
    );
    this.roles$ = this._ngRoles
      .getData()
      .pipe(
        map((roles) =>
          roles.filter(
            ({ userType }) =>
              userType !== TYPES_USER_PERMISSIONS.AGENT &&
              userType !== TYPES_USER_PERMISSIONS.CASHDESK &&
              userType !== TYPES_USER_PERMISSIONS.COLLABORATOR_AGENT
          )
        )
      );
  }

  private suscribeModalDataIssued(): void {
    this._bsModalService
      .getDataIssued()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ buttonAction, selectedRow }) =>
          this.processSelectedUser(buttonAction, selectedRow),
        error: () => this.closeModal(),
      });
  }

  private processSelectedUser(buttonAction: ButtonAction, user?: User): void {
    this.activeButtonAction = buttonAction;
    this.selectedUser = user;
    this.currenciesFormArray = this.getConfigCurrenciesModalForm(
      this.activeButtonAction,
      this.selectedUser
    );
    this.userFormGroup = this.getConfigUserModalForm(this.activeButtonAction);

    this.populateModalForm(this.selectedUser);

    const executeAction = this.handleProcessButtonAction(buttonAction);
    executeAction();
  }

  private getConfigCurrenciesModalForm(
    buttonAction: ButtonAction,
    user?: User
  ): FormArray {
    const configCurrencies: FormGroup[] = [];

    if (buttonAction !== BUTTON_ACTIONS.ADD && user) {
      user.currencies.forEach((currency) => {
        configCurrencies.push(this.getConfigCurrencies());
      });
    } else {
      configCurrencies.push(this.getConfigCurrencies());
    }

    return this._formBuilder.array(configCurrencies, duplicateCurrency());
  }

  private getConfigUserModalForm(buttonAction: ButtonAction): FormGroup {
    const defaultConfig = {
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      documentNumber: [null, [Validators.required]],
      username: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      gender: [null, [Validators.required]],
      birthDate: [null, [Validators.required]],
      mobileNumber: [null, [Validators.required, phoneNumberValidator()]],
      conventionalNumber: [null],
      languageId: [null, [Validators.required]],
      currencyId: [null, [Validators.required]],
      roleId: [null, [Validators.required]],
      countryId: [null, [Validators.required]],
      cityId: [null, [Validators.required]],
      address: [null, [Validators.required]],
      marketingWebsite: [null],
      currencies: this.currenciesFormArray,
      userType: [null],
    };

    const addUserConfig = {
      ...defaultConfig,
      password: [null, [Validators.required, strongPasswordValidator()]],
    };

    const viewAndEditUserConfig = {
      ...defaultConfig,
      state: [false],
      TFA: [false],
    };

    const actionsMap = new Map<ButtonAction, object>([
      [BUTTON_ACTIONS.ADD, addUserConfig],
      [BUTTON_ACTIONS.EDIT, viewAndEditUserConfig],
      [BUTTON_ACTIONS.VIEW, viewAndEditUserConfig],
    ]);

    const config = actionsMap.get(buttonAction) || {};

    return this._formBuilder.group(config);
  }

  private getConfigCurrencies(): FormGroup {
    return this._formBuilder.group({
      currencyId: [null, [Validators.required]],
      balance: [0],
      credit: [0],
    });
  }

  private populateModalForm(user?: User): void {
    if (!user || !this.userFormGroup) return;

    const {
      firstName,
      lastName,
      username,
      documentNumber,
      email,
      birthDate,
      gender,
      mobileNumber,
      conventionalNumber,
      marketingWebsite,
      countryId,
      cityId,
      address,
      languageId,
      roleId,
      currencyId,
      state,
      TFA,
      currencies,
      createdAt,
      updatedAt,
      userType,
    } = user;

    const formattedCurrencies = this.getFormattedCurrencies(currencies);

    const selectedUser = {
      firstName,
      lastName,
      username,
      documentNumber,
      email,
      birthDate: birthDate.toString().split('T')[0],
      gender,
      mobileNumber,
      conventionalNumber,
      marketingWebsite,
      countryId: countryId._id,
      cityId: cityId._id,
      address,
      currencyId: currencyId._id,
      languageId: languageId._id,
      roleId: roleId._id,
      state: state === 1 ? true : false,
      TFA,
      currencies: formattedCurrencies,
      userType,
    };

    this.createdAt = createdAt;
    this.updatedAt = updatedAt;

    this.userFormGroup.patchValue(selectedUser);
  }

  private getFormattedCurrencies(currencies: UserCurrency[]) {
    return currencies.map(({ currencyId, balance, credit }) => ({
      currencyId: currencyId._id,
      balance,
      credit,
    }));
  }

  private handleProcessButtonAction(buttonAction: ButtonAction): () => void {
    const actionMap = new Map<ButtonAction, () => void>([
      [BUTTON_ACTIONS.ADD, () => this.handleAddUser()],
      [BUTTON_ACTIONS.EDIT, () => this.handleEditUser()],
      [BUTTON_ACTIONS.VIEW, () => this.handleViewUser()],
    ]);
    return actionMap.get(buttonAction) || (() => {});
  }

  private handleAddUser(): void {
    this.titleModal = 'userManagement.users.add';

    this._ngCurrencies.triggerFetchData();
    this._ngCountries.triggerFetchData();
    this._ngLanguages.triggerFetchData();
    this._ngRoles.triggerFetchData();
  }

  private handleEditUser(): void {
    this.titleModal = 'userManagement.users.edit';

    if (!this.userFormGroup || !this.selectedUser) return;

    this.handleAddDataToStream(this.selectedUser);

    const { countryId } = this.selectedUser;
    this._ngCities.extendFilter({ countryId: countryId._id });

    this._ngCurrencies.triggerFetchData();
    this._ngCountries.triggerFetchData();
    this._ngCities.triggerFetchData();
    this._ngLanguages.triggerFetchData();
    this._ngRoles.triggerFetchData();
  }

  private handleViewUser(): void {
    this.titleModal = 'userManagement.users.view';

    if (!this.userFormGroup || !this.selectedUser) return;

    this.handleAddDataToStream(this.selectedUser);
    this.userFormGroup.disable();
  }

  private handleAddDataToStream(selectedUser: User): void {
    const { countryId, cityId, languageId, roleId } = selectedUser;

    const country = {
      label: countryId.name,
      value: countryId._id,
      icon: countryId.code,
    };
    const city = { label: cityId.name, value: cityId._id };
    const language = { label: languageId.name, value: languageId._id };
    const role = { label: roleId.name, value: roleId._id };

    this._ngCountries.addDataToStream([country]);
    this._ngCities.addDataToStream([city]);
    this._ngLanguages.addDataToStream([language]);
    this._ngRoles.addDataToStream([role]);

    selectedUser.currencies.forEach(({ currencyId }) => {
      const currency = { label: currencyId.name, value: currencyId._id };
      this._ngCurrencies.addDataToStream([currency]);
    });
  }

  public onSearchSelect(typeQuery: NgSelectQuery, term: string = ''): void {
    const actionMap = new Map<NgSelectQuery, () => void>([
      [NG_SELECT_QUERIES.COUNTRIES, () => this._ngCountries.searchTerm(term)],
      [NG_SELECT_QUERIES.CITIES, () => this.handleSearchTermForCities(term)],
      [NG_SELECT_QUERIES.CURRENCIES, () => this._ngCurrencies.searchTerm(term)],
      [NG_SELECT_QUERIES.LANGUAGE, () => this._ngLanguages.searchTerm(term)],
      [NG_SELECT_QUERIES.USER_ROLES, () => this._ngRoles.searchTerm(term)],
    ]);

    const action = actionMap.get(typeQuery);
    if (action) action();
  }

  private handleSearchTermForCities(term: string): void {
    if (!this.userFormGroup) return;

    const { countryId } = this.userFormGroup.value;
    this._ngCities.extendFilter({ countryId });
    this._ngCities.searchTerm(term);
  }

  public onScrollToEndSelect(typeQuery: NgSelectQuery): void {
    const actionMap = new Map<NgSelectQuery, () => void>([
      [NG_SELECT_QUERIES.COUNTRIES, () => this._ngCountries.scrollToEnd()],
      [NG_SELECT_QUERIES.CITIES, () => this._ngCities.scrollToEnd()],
      [NG_SELECT_QUERIES.CURRENCIES, () => this._ngCurrencies.scrollToEnd()],
      [NG_SELECT_QUERIES.LANGUAGE, () => this._ngLanguages.scrollToEnd()],
      [NG_SELECT_QUERIES.USER_ROLES, () => this._ngRoles.scrollToEnd()],
    ]);

    const action = actionMap.get(typeQuery);
    if (action) action();
  }

  public onCountryChange(event?: NgSelect<string>): void {
    this._ngCities.resetNgSelect();
    this.userFormGroup?.patchValue({ cityId: '' });

    if (!event) return;

    this._ngCities.extendFilter({ countryId: event.value });
    this._ngCities.triggerFetchData();
  }

  public onCurrencyChange($event?: NgSelect<string>): void {
    const currencyFormGroup = this.currenciesFormArray?.at(0) as FormGroup;
    if (!currencyFormGroup) return;

    const withCurrency = { currencyId: $event?.value, balance: 0, credit: 0 };
    const withoutCurrency = { currencyId: null, balance: null, credit: null };
    const data = $event ? withCurrency : withoutCurrency;

    currencyFormGroup.patchValue(data);
  }

  public onCurrencyConfigChange(
    index: number,
    $event?: NgSelect<string>
  ): void {
    if (!this.userFormGroup || index !== 0) return;

    const currencyId = $event ? $event?.value : null;
    this.userFormGroup.patchValue({ currencyId });
  }

  public addConfigCurrency(): void {
    if (!this.currenciesFormArray) return;
    if (this.currenciesFormArray.length >= MAX_ALLOWED_USER_CURRENCIES) return;
    this.currenciesFormArray.push(this.getConfigCurrencies());
  }

  public removeConfigCurrency(index: number): void {
    if (!this.currenciesFormArray) return;
    this.currenciesFormArray.removeAt(index);
  }

  public closeModal(): void {
    this._bsModalService.closeModal();
  }

  public onSubmit(): void {
    if (
      !this.activeButtonAction ||
      !this.userFormGroup ||
      this.userFormGroup.invalid
    ) {
      return;
    }

    const executeAction = this.handleSubmitAction(this.activeButtonAction);
    executeAction();
  }

  private handleSubmitAction(buttonAction: ButtonAction): () => void {
    const actionMap = new Map<ButtonAction, () => void>([
      [BUTTON_ACTIONS.ADD, () => this.addUser()],
      [BUTTON_ACTIONS.EDIT, () => this.editUser()],
    ]);
    return actionMap.get(buttonAction) || (() => {});
  }

  private addUser(): void {
    const {
      firstName,
      lastName,
      documentNumber,
      username,
      email,
      gender,
      birthDate,
      password,
      mobileNumber,
      conventionalNumber,
      languageId,
      currencyId,
      roleId,
      countryId,
      cityId,
      address,
      marketingWebsite,
      currencies,
      userType,
    } = this.userFormGroup!.value;

    const userFormData = {
      firstName,
      lastName,
      documentNumber,
      username,
      email,
      gender,
      birthDate,
      password,
      mobileNumber,
      conventionalNumber,
      languageId,
      currencyId,
      roleId,
      countryId,
      cityId,
      address,
      marketingWebsite,
      currencies,
      userType,
    };

    this._userService
      .createUser(userFormData)
      .subscribe({ next: () => this.afterSubmitForm() });
  }

  private editUser(): void {
    if (!this.userFormGroup || !this.selectedUser) return;

    const selectedUser = this.getSelectedUser();
    const userFormData = this.getUserFormData();

    const editedUser: object = getDifferingProperties(
      selectedUser,
      userFormData
    );

    if (!Object.keys(editedUser).length) {
      const message = this._translateService.instant('words.noChangesForm');
      this._notificationService.showNotification({
        type: 'warning',
        title: 'userManagement.users.title',
        message,
      });
      return;
    }

    if ('currencies' in editedUser) {
      editedUser.currencies = JSON.parse(editedUser.currencies as string);
    }

    const { _id } = this.selectedUser;

    this._userService
      .updateUser(_id, editedUser)
      .subscribe({ next: () => this.afterSubmitForm() });
  }

  private getSelectedUser(): object {
    const {
      firstName,
      lastName,
      documentNumber,
      username,
      email,
      gender,
      birthDate,
      mobileNumber,
      conventionalNumber,
      marketingWebsite,
      languageId,
      currencyId,
      roleId,
      countryId,
      cityId,
      address,
      currencies,
      TFA,
      state,
      userType,
    } = this.selectedUser!;

    return {
      firstName,
      lastName,
      documentNumber,
      username,
      email,
      gender,
      birthDate: birthDate.toString().split('T')[0],
      mobileNumber,
      conventionalNumber,
      marketingWebsite,
      languageId: languageId._id,
      currencyId: currencyId._id,
      roleId: roleId._id,
      countryId: countryId._id,
      cityId: cityId._id,
      address,
      currencies: JSON.stringify(this.getFormattedCurrencies(currencies)),
      TFA,
      state: state === 1 ? true : false,
      userType,
    };
  }

  private getUserFormData(): object {
    const {
      firstName,
      lastName,
      documentNumber,
      username,
      email,
      gender,
      birthDate,
      mobileNumber,
      conventionalNumber,
      marketingWebsite,
      languageId,
      currencyId,
      roleId,
      countryId,
      cityId,
      address,
      currencies,
      TFA,
      state,
      userType,
    } = this.userFormGroup!.value;

    return {
      firstName,
      lastName,
      documentNumber,
      username,
      email,
      gender,
      birthDate,
      mobileNumber,
      conventionalNumber,
      marketingWebsite,
      languageId,
      currencyId,
      roleId,
      countryId,
      cityId,
      address,
      currencies: JSON.stringify(currencies),
      TFA,
      state,
      userType,
    };
  }

  private afterSubmitForm(): void {
    this._filterService.updateFilterData({});
    this.closeModal();
  }

  public onRoleChange(selectedRole: any): void {
    if (!selectedRole) {
      this.userFormGroup?.patchValue({ userType: null });
      return;
    }

    const { userType } = selectedRole;
    this.userFormGroup?.patchValue({ userType });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public validateKeypress(event: KeyboardEvent): void {
    const allowedKeys = ['+', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  public validatePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData?.getData('text');
    const phoneNumberPattern = /^[+0-9]*$/;

    if (clipboardData && !phoneNumberPattern.test(clipboardData)) {
      event.preventDefault();
    }
  }
}
