import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterService<T> {
  private filterDataSubject: Subject<T> = new Subject<T>();
  private filterDataBehaviorSubject: BehaviorSubject<T> =
    new BehaviorSubject<T>(null as T);

  public getFilterData(): Observable<T> {
    return this.filterDataSubject.asObservable();
  }

  public updateFilterData(data: T): void {
    this.filterDataSubject.next(data);
  }

  public getFilterDataBehavior(): Observable<T> {
    return this.filterDataBehaviorSubject.asObservable();
  }

  public updateFilterDataBehavior(data: T): void {
    this.filterDataBehaviorSubject.next(data);
  }
}
