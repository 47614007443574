import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "booleanToActiveInactive",
})
export class BooleanToActiveInactivePipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? "words.active" : "words.inactive";
  }
}
