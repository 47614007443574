import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "statusToActiveInactive",
})
export class StatusToActiveInactivePipe implements PipeTransform {
  transform(value: number): string {
    return value === 1 ? "words.active" : "words.inactive";
  }
}
