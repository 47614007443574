import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { User } from "src/app/core/interfaces/api/user.interface";
import { ModalWithAction } from "src/app/core/interfaces/ui/bootstrap-modal.interface";
import { PlayerService } from "src/app/core/services/api/player.service";
import { BootstrapModalService } from "src/app/core/services/ui/bootstrap-modal.service";
import { FilterService } from "src/app/core/services/ui/filter.service";
import { GlobalService } from "src/app/core/services/ui/global.service";

@Component({
  selector: "app-block-player",
  templateUrl: "./block-player.component.html",
})
export class BlockPlayerComponent implements OnInit, OnDestroy {
  private playerId: string | undefined;
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  public userProfile!: User;
  private _subscriptions: Subscription[] = [];
  constructor(
    private _playerService: PlayerService,
    private _bsModalService: BootstrapModalService<ModalWithAction<any>>,
    private _filterService: FilterService<object>,
    private _profileService: GlobalService
  ) {}

  ngOnInit(): void {
    this._profileService.profile.subscribe((profile) => {
      this.userProfile = profile;
    });
    this.subscribeToModalData();
  }

  private subscribeToModalData(): void {
    this._bsModalService
      .getDataIssued()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ selectedRow }) => {
          this.playerId = selectedRow?._id;
        },
        error: () => this.closeModal(),
      });
  }

  public blockPlayer(): void {
    if (!this.playerId) {
      return;
    }
    const newState = {
      playerId: [this.playerId],
      state: "0",
      blockedById: this.userProfile._id,
    };
    this._playerService
      .setPlayerStatus(newState)
      .subscribe(() => this.afterSubmitForm());
  }

  public closeModal(): void {
    this._bsModalService.closeModal();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  private afterSubmitForm(): void {
    this._filterService.updateFilterData({});
    this.closeModal();
  }
}
