import { Routes } from "@angular/router";
import { LoggedInAuthGuard } from "src/app/core/guards/loggedIn.guard";

export const full: Routes = [
  {
    path: "auth",
    canActivate: [LoggedInAuthGuard],
    loadChildren: () =>
      import("../../features/auth/auth.module").then((m) => m.AuthModule),
  },
];
