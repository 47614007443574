import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ApiData,
  ApiMessage,
  ApiResponse,
} from '../../interfaces/api/api-response.interface';
import {
  Role,
  RolePermission,
  RolePermissions,
} from '../../interfaces/api/role.interface';
import { NgSelect } from '../../interfaces/ui/ui.interface';
import { ToastrNotificationService } from '../ui/toastr-notification.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private apiUrl = `${environment.apiUrl}/role`;

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: ToastrNotificationService
  ) {}

  public createtRoleAndPermissions(
    request: object
  ): Observable<ApiResponse<ApiData<Role[]>>> {
    const endpoint = `${this.apiUrl}/create`;

    return this._httpClient
      .post<ApiResponse<ApiData<Role[]>>>(endpoint, request)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  public findRole(filter: object): Observable<ApiResponse<ApiData<Role[]>>> {
    const endpoint = `${this.apiUrl}/find`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<Role[]>>>(endpoint, {
      params,
    });
  }

  public findRoleForSelect(
    filter: object
  ): Observable<ApiData<NgSelect<string>[]>> {
    return this.findRole(filter).pipe(
      map((response) => ({
        totalCount: response.data.totalCount,
        result: response.data.result.map((betshop) => ({
          label: betshop.name,
          value: betshop._id,
          userType: betshop.userType,
        })),
      }))
    );
  }

  public getUserByRoleCount(roleId: string): Observable<ApiResponse<number>> {
    const endpoint = `${this.apiUrl}/${roleId}/users/count`;

    return this._httpClient.get<ApiResponse<number>>(endpoint);
  }

  public updateRoleAndPermissions(
    request: object
  ): Observable<ApiResponse<ApiData<Role[]>>> {
    const endpoint = `${this.apiUrl}/update`;

    return this._httpClient
      .put<ApiResponse<ApiData<Role[]>>>(endpoint, request)
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  private showNotification(message: ApiMessage): void {
    this._notificationService.showNotification({
      title: 'userManagement.role.title',
      message: message,
      type: 'success',
    });
  }

  public getIdsWithTrueStatus(data: RolePermission[]): string[] {
    let result: string[] = [];

    data.forEach((item) => {
      if (item.status) {
        result.push(item.id);
      }

      if (item.children && item.children.length > 0) {
        result = result.concat(this.getIdsWithTrueStatus(item.children));
      }
    });

    return result;
  }
}
