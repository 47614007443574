import { GlobalConfig, IndividualConfig } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Theme, ThemeKeys } from '../../interfaces/ui/ui.interface';

export const FORMAT_FOR_DATES = 'dd/MM/yyyy HH:mm';

export const FORMAT_FOR_DATES_SHORT = 'dd/MM/yyyy';

export const FORMAT_FOR_DATES_LONG = 'dd/MM/yyyy HH:mm:ss';

export const FORMAT_INPUT_DATE = 'yyyy-MM-ddTHH:mm';

export const DEFAULT_SEARCH_NG_SELECT_TERM_DELAY = 1500;

export const DOWNLOAD_FILE_DELAY_MS = 1000;

export const BUTTON_ACTIONS = {
  ACCEPT: 'accept',
  ADD: 'add',
  CANCEL: 'cancel',
  COPY: 'copy',
  CREATE: 'create',
  CSV: 'csv',
  DELETE: 'delete',
  EDIT: 'edit',
  EXCEL: 'excel',
  FILTER: 'filter',
  RELOAD: 'reload',
  RESET: 'reset',
  RESOURCE: 'resource',
  RESTORE: 'restore',
  SAVE: 'save',
  STATS: 'stats',
  UPDATE: 'update',
  VIEW: 'view',
  REJECT: 'reject',
  PROCESS: 'process',
  STATE: 'state',
} as const;

export const DEFAULT_GLOBAL_TOASTR_CONFIG: Partial<GlobalConfig> = {
  maxOpened: 1,
  autoDismiss: true,
  preventDuplicates: true,
};

export const DEFAULT_INDIVIDUAL_TOASTR_CONFIG: Partial<IndividualConfig<any>> =
  {
    positionClass: 'custom-toastr-top',
    closeButton: true,
    progressBar: true,
    timeOut: 3000,
    easeTime: 500,
    easing: 'ease-in-out',
    progressAnimation: 'decreasing',
  };

export const NG_SELECT_QUERIES = {
  CURRENCIES: 'currencies',
  COUNTRIES: 'countries',
  COUNTRY: 'country',
  LANGUAGE: 'language',
  CITIES: 'cities',
  GENDER: 'gender',
  USERS: 'users',
  BETSHOPS: 'betshops',
  USER_ROLES: 'userRoles',
  COMISSION_PLANS: 'comissionPlans',
  SPORTS: 'sports',
  BANKS: 'banks',
  PLAYERS: 'players',
  COLLABORATORS: 'collaborators',
  CASINO_GAMES: 'casinoGames',
  PROVIDERS: 'providers',
  REGIDERED_BY: 'registeredBy',
  CATEGORIES: 'categories',
  TOURNAMENTS: 'tournaments',
  EVENTS: 'events',
} as const;

export const DEFAULT_SEARCH_NG_SELECT_PAGINATION = {
  LIMIT: 10,
  PAGE: 1,
};

export const YEARS_DATA = [
  2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035,
];

export const MONTH_DATA = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export const DAYS_DATA = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const FORMAT_FOR_CURRENCY_CODE = 'USD';

export const FORMAT_FOR_CURRENCY_DISPLAY = 'symbol';

export const FORMAT_FOR_CURRENCY_DIGITS = '1.2-2';

export const FORMAT_FOR_PERCENT_DIGITS = '1.2-2';

export const FORMAT_FOR_NUMBER_DIGITS = '1.2-2';

export const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-secondary',
    cancelButton: 'btn btn-danger',
    actions: 'd-flex gap-2',
  },
  buttonsStyling: false,
  reverseButtons: true,
});

export const MODAL_CONFIGURATION_TYPE = {
  CODE_INYECTION: 'codeInjection',
  ABOUT_US: 'aboutUs',
  PRIVACY_POLICY: 'privacyPolicy',
  COOKIE_POLICY: 'cookiePolicy',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  RESPONSIBLE_GAMING: 'responsibleGaming',
  FAQS: 'faqs',
  TUTORIALS: 'tutorials',
  CONTACT: 'contact',
  BONUS_INFORMATION: 'bonusInformation',
} as const;

export const AVAILABLE_THEMES: Record<ThemeKeys, Theme> = {
  LIGHT_ONLY: 'light-only',
  DARK_ONLY: 'dark-only',
} as const;

export const ICONS_DASHBOARD_GENERAL_STATS = {
  agentsToday: 'fill-user',
  agentsThisMonth: 'fill-user',
  agentsTotal: 'fill-user',
  playersToday: 'user-visitor',
  playersThisMonth: 'user-visitor',
  playersTotal: 'user-visitor',
  betsToday: 'doller-return',
  betsThisMonth: 'doller-return',
  todayCashDesk: 'doller-return',
  monthCashDesk: 'doller-return',
  allCashDesk: 'doller-return',
};

export const TIME_PERIODS = {
  TODAY: 'today',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const TIME_PERIODS_SELECT = [
  { value: 'today', label: 'dashboard.filters.today' },
  { value: 'week', label: 'dashboard.filters.week' },
  { value: 'month', label: 'dashboard.filters.month' },
  { value: 'year', label: 'dashboard.filters.year' },
];

export const SELECTED_FILTER_TIME_PERIODS: 'today' | 'week' | 'month' | 'year' =
  'today';

export const PRODUCTS_SELECT = [
  { value: 'allProducts', label: 'dashboard.filters.allProducts' },
  { value: 'sportsBets', label: 'dashboard.filters.sportsBets' },
  { value: 'casino', label: 'dashboard.filters.casino' },
  { value: 'liveCasino', label: 'dashboard.filters.liveCasino' },
  { value: 'virtualGames', label: 'dashboard.filters.virtualGames' },
];

export const SELECTED_FILTER_PRODUCTS:
  | 'allProducts'
  | 'sportsBets'
  | 'casino'
  | 'liveCasino'
  | 'virtualGames' = 'allProducts';

export const DEFAULT_TOTAL_BETS = {
  System: 0,
  Simple: 0,
  Multiple: 0,
};
