<div class="container-fluid">
  <div class="page-title">
    <div class="row mt-3 align-items-center">
      <div class="col-6 d-flex align-items-center">
        @if(activeBackButton){
        <button class="btn btn-outline-secondary me-3" (click)="onBack()">
          <i class="fa fa-arrow-left"></i>
        </button>
        }
        <h3 class="mb-0">{{ title | translate }}</h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <svg class="stroke-icon">
                <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">
            {{ item | translate }}
          </li>
          <li class="breadcrumb-item active">{{ active_item | translate }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>
