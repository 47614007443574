import { Pipe, PipeTransform } from "@angular/core";
import { GENDER_OPTIONS } from "src/app/core/helpers/global/global.constant";

@Pipe({
  name: "genderToLabel",
})
export class GenderToLabelPipe implements PipeTransform {
  transform(value: string): string {
    const genderOption = GENDER_OPTIONS.find(
      (option) => option.value === value
    );
    return genderOption ? genderOption.label : "words.notDefined";
  }
}
